import React from "react";
import Drawer from "react-drag-drawer";
import Colors from "../../Assets/Colors";
import { Button } from "../UI/Styled/Button";
import { H5 } from "../UI/Styled/Texts";
import { Div } from "../UI/Styled/Views";
import AddPainElement from "./AddPainElement";

const PainModal = (props) => {
  const { open, onRequestClose, pains, setPains } = props;
  return (
    <Drawer open={open} onRequestClose={onRequestClose}>
      <Div
        style={{
          backgroundColor: Colors.background,
          padding: 20,
          width: "70vw",
          maxHeight: "70vh",
          borderRadius: 8,
          overflow: "scroll",
        }}
      >
        {pains.map((p, i) => (
          <AddPainElement title={p.type} quantity={p.quantity} index={i} key={i} pains={pains} setPains={setPains} />
        ))}
        <Div style={{ alignItems: "flex-end" }}>
          <Button onClick={() => props.submit()} style={{ cursor: "pointer", marginTop: 15, marginLeft: 15 }}>
            <H5 style={{ color: "white" }}>Valider</H5>
          </Button>
        </Div>
      </Div>
    </Drawer>
  );
};

export default PainModal;
