import React, { useState } from "react";
import { ReactComponent as SettingIcon } from "../../Assets/Icons/Settings.svg";
import { H5 } from "../UI/Styled/Texts";
import { Card, Div } from "../UI/Styled/Views";
import ProfileInfoCardElement from "./ProfileInfoCardElement";
import Switch from "react-switch";
import Colors from "../../Assets/Colors";
import { useDispatch, useSelector } from "react-redux";
import { patchEmployee } from "../../store/actions/employees";

const ProfileInfoCard = (props) => {
  const { emp } = props;
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.auth);
  const switchActive = (e) => {
    dispatch(
      patchEmployee({
        token: token,
        id: emp._id,
        body: {
          active: e,
        },
      })
    );
  };

  return (
    <Card style={{ ...props.style }}>
      <Div
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: 5,
        }}
      >
        <H5 style={{ fontWeight: 700 }}>
          {emp.name} {emp.surname}
        </H5>
        <Div style={{ flexDirection: "row", alignItems: "center" }}>
          <Switch
            checked={emp.active}
            onChange={switchActive}
            onColor={Colors.green}
            onHandleColor={Colors.green}
            offColor={Colors.orange}
            offHandleColor={Colors.orange}
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={12}
            width={38}
          />
          <SettingIcon style={{ marginLeft: 10 }} />
        </Div>
      </Div>
      <ProfileInfoCardElement title="Tel" value={emp.phone} />
      <ProfileInfoCardElement title="Email" value={emp.email} />
      <ProfileInfoCardElement
        title="Date de naissance"
        value={`${new Date(emp.birthdate).getDate()}/${(
          new Date(emp.birthdate).getMonth() + 1
        ).toString()}/${new Date(emp.birthdate).getFullYear()}`}
      />
      <ProfileInfoCardElement
        title="Inscrit depuis"
        value={`${new Date(emp.registerDate).getDate()}/${(
          new Date(emp.registerDate).getMonth() + 1
        ).toString()}/${new Date(emp.registerDate).getFullYear()}`}
      />
      <ProfileInfoCardElement title="Mot de passe" value={emp.pin} />
    </Card>
  );
};

export default ProfileInfoCard;
