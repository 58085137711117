import {
  GET_TASKS_SUCCESS,
  ADD_TASKS_SUCCESS,
  UPDATE_TASKS_SUCCESS,
  DELETE_TASKS_SUCCESS,
  ADD_TABLE_SUCCESS,
  UPDATE_TABLE_SUCCESS,
  DELETE_TABLE_SUCCESS,
  FAILURE,
  REQUEST
} from '../types/tasks'

const initialState = {
  tables: [],
  error: false,
  errorStatus: '',
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TASKS_SUCCESS:
      return Object.assign({}, state, {
        tables: action.value,
        loading: false
      })

    case ADD_TASKS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        errorStatus: '',
        tables: state.tables.map((table) => {
          if (table.table._id === action.value.table) {
            table.tasks = [...(table.tasks || []), action.value]
          }
          return table
        })
      })
    case UPDATE_TASKS_SUCCESS:
      let tasksCopy = [...state.tables]
      for (let i = 0; i < tasksCopy.length; i++) {
        for (let j = 0; j < tasksCopy[i]?.tasks?.length; j++) {
          if (tasksCopy[i].tasks[j]._id === action.value._id) {
            tasksCopy[i].tasks[j] = action.value
          }
        }
      }
      return Object.assign({}, state, {
        loading: false,
        error: false,
        errorStatus: '',
        tables: tasksCopy
      })

    case DELETE_TASKS_SUCCESS:
      for (let tasks in state.tables) {
        const idx = state.tables[tasks].tasks.findIndex(
          (el) => el._id === action.value
        )
        state.tables[tasks].tasks.splice(idx, 1)
      }
      return Object.assign({}, state, {
        loading: false,
        error: false,
        errorStatus: ''
      })
    case ADD_TABLE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        errorStatus: '',
        tables: [...state.tables, { table: action.value }]
      })
    case DELETE_TABLE_SUCCESS:
      const tableIndex = state.tables.findIndex(
        (el) => el.table._id === action.value
      )
      state.tables.splice(tableIndex, 1)
      return Object.assign({}, state, {
        loading: false,
        error: false,
        errorStatus: ''
      })

    case UPDATE_TABLE_SUCCESS:
      let tableCopy = [...state.tables]

      for (let todo in tableCopy) {
        if (tableCopy[todo].table._id == action.value._id) {
          console.log('tableCopy[todo].table', tableCopy[todo].table)
          tableCopy[todo].table = action.value
        }
      }
      return Object.assign({}, state, {
        loading: false,
        error: false,
        errorStatus: '',
        tables: tableCopy
      })
    case REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value
      })
    default:
      return state
  }
}
