export const GET_TODO_CATEGORY_SUCCESS = 'GET_TODO_CATEGORY_SUCCESS'
export const GET_TODO_CATEGORY_REQUEST = 'GET_TODO_CATEGORY_REQUEST'
export const GET_TODO_CATEGORY_FAILURE = 'GET_TODO_CATEGORY_FAILURE'

export const ADD_TODO_CATEGORY_SUCCESS = 'ADD_TODO_CATEGORY_SUCCESS'
export const ADD_TODO_CATEGORY_REQUEST = 'ADD_TODO_CATEGORY_REQUEST'
export const ADD_TODO_CATEGORY_FAILURE = 'ADD_TODO_CATEGORY_FAILURE'

export const DELETE_TODO_CATEGORY_SUCCESS = 'DELETE_TODO_CATEGORY_SUCCESS'
export const DELETE_TODO_CATEGORY_REQUEST = 'DELETE_TODO_CATEGORY_REQUEST'
export const DELETE_TODO_CATEGORY_FAILURE = 'DELETE_TODO_CATEGORY_FAILURE'

export const UPDATE_TODO_CATEGORY_SUCCESS = 'UPDATE_TODO_CATEGORY_SUCCESS'
export const REQUEST = 'REQUEST'
export const FAILURE = 'FAILURE'
