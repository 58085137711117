import {
  AUTO_AUTH,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGOUT,
  UPLOAD_PROFILE_PIC,
  UPLOAD_PROFILE_PIC_FAILURE,
  UPLOAD_PROFILE_PIC_REQUEST,
} from "../types/auth";

const initialState = {
  isAuthenticated: false,
  user: null,
  token: "",
  loading: false,
  error: false,
  errorStatus: "",
  lastUpdatedProfilePic: Date.now(),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return Object.assign({}, state, {
        isAuthenticated: true,
        user: action.value.user,
        token: action.value.token,
        loading: false,
        error: false,
        errorCode: "",
      });
    case LOGOUT:
      return Object.assign({}, state, {
        isAuthenticated: false,
        user: null,
        token: "",
      });
    case LOGIN_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value,
      });
    case UPLOAD_PROFILE_PIC:
      return Object.assign({}, state, {
        user: action.value,
        loading: false,
        error: false,
        errorCode: "",
        lastUpdatedProfilePic: Date.now(),
      });
    case UPLOAD_PROFILE_PIC_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value,
      });
    case UPLOAD_PROFILE_PIC_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case AUTO_AUTH:
      return Object.assign({}, state, {
        user: action.value.user,
        token: action.value.token,
        isAuthenticated: true,
      });
    default:
      return state;
  }
};
