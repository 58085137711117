import {
  GET_HISTORIES_SUCCESS,
  ADD_HISTORIES_SUCCESS,
  UPDATE_HISTORIES_SUCCESS,
  REQUEST,
  FAILURE,
} from "../types/histories";

export const getHistories = ({ token, pageNumber }) => {
  return (dispatch) => {
    dispatch({ type: REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/history?pageNumber=${pageNumber}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: GET_HISTORIES_SUCCESS, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const addHistories = ({ token, name, todos }) => {
  return (dispatch) => {
    dispatch({ type: REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/history`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        name: name,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: ADD_HISTORIES_SUCCESS, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const updateHistories = ({ token, _id, checked, employee }) => {
  console.log("checked:", checked);
  console.log("employee:", employee);
  return (dispatch) => {
    dispatch({ type: REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/history?id=${_id}`, {
      method: "PATCH",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        checked,
        employee,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: UPDATE_HISTORIES_SUCCESS, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};
