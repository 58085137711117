import { Link as RouterLink } from "react-router-dom";
import React from "react";

const Link = (props) => {
  return (
    <RouterLink
      to={props.to}
      style={{ textDecoration: "none", ...props.style }}
    >
      {props.children}
    </RouterLink>
  );
};

export default Link;
