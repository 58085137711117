import {
  GET_HISTORY_CLOTURES,
  GET_HISTORY_CLOTURES_FAILURE,
  GET_HISTORY_CLOTURES_REQUEST,
  GET_EMPLOYEE_CLOTURES,
  GET_EMPLOYEE_CLOTURES_FAILURE,
  GET_EMPLOYEE_CLOTURES_REQUEST,
  GET_EMPLOYEE_HISTORY_CLOTURES,
  GET_EMPLOYEE_HISTORY_CLOTURES_FAILURE,
  GET_EMPLOYEE_HISTORY_CLOTURES_REQUEST,
  GET_CLOTURES_REQUEST,
  GET_CLOTURES_FAILURE,
  GET_CLOTURES,
  GET_LAST_YEAR_CLOTURES,
  GET_LAST_YEAR_CLOTURES_FAILURE,
  GET_LAST_YEAR_CLOTURES_REQUEST,
} from "../types/clotures";

export const getHistoryClotures = ({ token, pageNumber }) => {
  return (dispatch) => {
    dispatch({ type: GET_HISTORY_CLOTURES_REQUEST });
    fetch(
      `https://shopngo.herokuapp.com/api/cash-closing/history?pageNumber=${pageNumber}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.errors) {
          dispatch({
            type: GET_HISTORY_CLOTURES_FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: GET_HISTORY_CLOTURES, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const getEmployeeHistoryClotures = ({ token, pageNumber, eid }) => {
  return (dispatch) => {
    dispatch({ type: GET_EMPLOYEE_HISTORY_CLOTURES_REQUEST });
    fetch(
      `https://shopngo.herokuapp.com/api/cash-closing/history?pageNumber=${pageNumber}&eid=${eid}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.errors) {
          dispatch({
            type: GET_EMPLOYEE_HISTORY_CLOTURES_FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: GET_EMPLOYEE_HISTORY_CLOTURES, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const getEmployeeClotures = ({
  token,
  eid,
  dateFrom,
  dateTo,
  hourFrom = null,
  hourTo = null,
}) => {
  const body = hourFrom
    ? {
        dateFrom: dateFrom,
        dateTo: dateTo,
        eid: eid,
        hourFrom: hourFrom,
        hourTo: hourTo,
      }
    : {
        dateFrom: dateFrom,
        dateTo: dateTo,
        eid: eid,
      };
  return (dispatch) => {
    dispatch({ type: GET_EMPLOYEE_CLOTURES_REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/dashboard`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.errors) {
          dispatch({
            type: GET_EMPLOYEE_CLOTURES_FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: GET_EMPLOYEE_CLOTURES, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const getClotures = ({
  token,
  dateFrom,
  dateTo,
  hourFrom = null,
  hourTo = null,
}) => {
  const body = hourFrom
    ? {
        dateFrom: dateFrom,
        dateTo: dateTo,
        hourFrom: hourFrom,
        hourTo: hourTo,
      }
    : {
        dateFrom: dateFrom,
        dateTo: dateTo,
      };
  return (dispatch) => {
    dispatch({ type: GET_CLOTURES_REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/dashboard`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.errors) {
          dispatch({
            type: GET_CLOTURES_FAILURE,
            value: res.errors.status,
          });
        } else {
          console.log(`res`, res);
          dispatch({ type: GET_CLOTURES, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const getLastYearClotures = ({
  token,
  dateFrom,
  dateTo,
  hourFrom = null,
  hourTo = null,
}) => {
  const body = hourFrom
    ? {
        dateFrom: dateFrom,
        dateTo: dateTo,
        hourFrom: hourFrom,
        hourTo: hourTo,
      }
    : {
        dateFrom: dateFrom,
        dateTo: dateTo,
      };
  return (dispatch) => {
    dispatch({ type: GET_LAST_YEAR_CLOTURES_REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/dashboard`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.errors) {
          dispatch({
            type: GET_LAST_YEAR_CLOTURES_FAILURE,
            value: res.errors.status,
          });
        } else {
          console.log("res :>> ", res);
          dispatch({ type: GET_LAST_YEAR_CLOTURES, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};
