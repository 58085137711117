import {
  GET_HISTORY_CLOTURES,
  GET_EMPLOYEE_CLOTURES,
  GET_EMPLOYEE_HISTORY_CLOTURES,
  GET_CLOTURES,
  GET_LAST_YEAR_CLOTURES,
  GET_CLOTURES_REQUEST,
  GET_CLOTURES_FAILURE,
  GET_HISTORY_CLOTURES_REQUEST,
  GET_HISTORY_CLOTURES_FAILURE,
  GET_EMPLOYEE_HISTORY_CLOTURES_REQUEST,
  GET_EMPLOYEE_HISTORY_CLOTURES_FAILURE,
  GET_EMPLOYEE_CLOTURES_REQUEST,
  GET_EMPLOYEE_CLOTURES_FAILURE,
  GET_LAST_YEAR_CLOTURES_REQUEST,
  GET_LAST_YEAR_CLOTURES_FAILURE,
} from "../types/clotures";

const initialState = {
  clotures: null,
  lastYearClotures: null,
  historyClotures: null,
  pageCount: 0,
  error: false,
  errorStatus: "",
  loading: false,
  selectedEmployeeHistoryClotures: null,
  selectedEmployeeClotures: null,
  selectedEmployeePageCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HISTORY_CLOTURES:
      return Object.assign({}, state, {
        historyClotures: action.value.items,
        pageCount: action.value.pageCount,
        loading: false,
      });
    case GET_HISTORY_CLOTURES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_HISTORY_CLOTURES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value,
      });
    case GET_EMPLOYEE_HISTORY_CLOTURES:
      return Object.assign({}, state, {
        selectedEmployeeHistoryClotures: action.value.items,
        selectedEmployeePageCount: action.value.pageCount,
        loading: false,
      });
    case GET_EMPLOYEE_HISTORY_CLOTURES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_EMPLOYEE_HISTORY_CLOTURES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value,
      });
    case GET_EMPLOYEE_CLOTURES:
      return Object.assign({}, state, {
        selectedEmployeeClotures: action.value,
        loading: false,
      });
    case GET_EMPLOYEE_CLOTURES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_EMPLOYEE_CLOTURES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value,
      });
    case GET_CLOTURES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_CLOTURES:
      return Object.assign({}, state, {
        loading: false,
        clotures: action.value,
      });
    case GET_CLOTURES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value,
      });
    case GET_LAST_YEAR_CLOTURES:
      return Object.assign({}, state, {
        lastYearClotures: action.value,
        loading: false,
      });
    case GET_LAST_YEAR_CLOTURES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_LAST_YEAR_CLOTURES_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value,
      });
    default:
      return state;
  }
};
