import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import illutsration from '../../Assets/Icons/responsable.svg'
import pencil from '../../Assets/Icons/pencil.svg'
import crash from '../../Assets/Icons/crash.svg'
import ModalEdit from '../UI/ModalEdit'
import ModalDelete from '../UI/ModalDelete'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteTodosCategories,
  updateTodosCategories
} from '../../store/actions/todos'
import { CategoryCard, CardIcon, Text, Container, StyledLink } from './UI'

//* UI

const Categories = ({ categories, modalToggle, setModalToggle }) => {
  const Item = ({ name, _id }) => {
    const { token } = useSelector((state) => state.auth)

    const dispatch = useDispatch()

    const [toggleModalEdit, setToggleModalEdit] = React.useState(false)
    const [toggleModalDelete, setToggleModalDelete] = React.useState(false)

    const [editedName, setEditedName] = useState('')
    const [categoryID, setcategoryID] = useState(null)

    return (
      <>
        <ModalEdit
          open={toggleModalEdit}
          toggleOpen={setToggleModalEdit}
          buttonText='Enregistrer'
          title='Modifier le nom'
          onButtonClick={() => {
            dispatch(
              updateTodosCategories({
                token,
                name: editedName,
                _id: categoryID
              })
            )
            setToggleModalEdit()
          }}
          inputValue={editedName}
          setInputValue={setEditedName}
        />

        <ModalDelete
          open={toggleModalDelete}
          onCancel={setToggleModalDelete}
          onDelete={() => {
            dispatch(deleteTodosCategories({ token, _id: categoryID }))
            setToggleModalDelete()
          }}
          title='Voulez-vous vraiment supprimer le template {1} ?'
        />
        <CategoryCard>
          <StyledLink
            to={`/responsable/${_id}`}
            style={{ textDecoration: 'none' }}
          >
            <Text style={{ overflowWrap: 'anywhere', paddingRight: 5 }}>
              {name}
            </Text>
            <img src={illutsration} style={{ height: 58, width: 68 }} alt='' />
            <CardIcon
              onClick={(e) => {
                e.preventDefault()
                setcategoryID(_id)
                setToggleModalDelete(true)
              }}
              src={crash}
              top='10px'
              alt=''
            />
            <CardIcon
              onClick={(e) => {
                e.preventDefault()
                setcategoryID(_id)
                setToggleModalEdit(true)
              }}
              src={pencil}
              bottom='10px'
              alt=''
            />
          </StyledLink>
        </CategoryCard>
        <div style={{ width: 25 }} />
      </>
    )
  }

  const { todos } = categories

  return (
    <>
      <div style={{ marginBottom: 100 }}>
        <Container>
          {todos?.map((todo) => {
            return (
              <Item
                key={todo?._id}
                name={todo?.name}
                _id={todo?._id}
                modalToggle={modalToggle}
                setModalToggle={setModalToggle}
              />
            )
          })}
        </Container>
      </div>
    </>
  )
}

export default Categories
