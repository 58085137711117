import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import ModalAddTable from '../UI/ModalAddTable'
import Item from './Item'

import { addTasks } from '../../store/actions/tasks.js'

const Main = styled.section`
  display: flex;
  flex-direction: column;
  background: white;
  margin-top: 32px;
  border: 1px solid #eaeaea;
`

const Todo = ({ tasks }) => {
  const dispatch = useDispatch()

  const [toggleModalAddTable, setToggleModalAddTable] = React.useState(false)
  const [description, setDescription] = React.useState('')
  const [photo, setPhoto] = React.useState(true)
  const [comment, setComment] = React.useState(true)
  const [monday, setMonday] = React.useState(true)
  const [tuesday, setTuesday] = React.useState(true)
  const [wednesday, setWednesday] = React.useState(true)
  const [thursday, setThursday] = React.useState(true)
  const [friday, setFriday] = React.useState(true)
  const [saturday, setSaturday] = React.useState(true)
  const [sunday, setSunday] = React.useState(true)
  const { token } = useSelector((state) => state.auth)

  const [weekend, setWeekend] = useState([
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday
  ])

  return (
    <Main>
      <>
        <ModalAddTable
          open={toggleModalAddTable}
          toggleOpen={setToggleModalAddTable}
          buttonText='Enregistrer'
          title='Modifier le nom'
          onButtonClick={() => {
            setToggleModalAddTable()
            dispatch(
              addTasks({
                token,
                name: description,
                days: [
                  monday,
                  tuesday,
                  wednesday,
                  thursday,
                  friday,
                  saturday,
                  sunday
                ],
                comment,
                picture: photo,
                table: tasks?.table?._id
              })
            )
          }}
          inputValue={description}
          setInputValue={setDescription}
          photoValue={photo}
          commentValue={comment}
          setPhotoValue={setPhoto}
          setCommentValue={setComment}
          setMonday={setMonday}
          setTuesday={setTuesday}
          setWednesday={setWednesday}
          setThursday={setThursday}
          setFriday={setFriday}
          setSaturday={setSaturday}
          setSunday={setSunday}
          monday={monday}
          tuesday={tuesday}
          wednesday={wednesday}
          thursday={thursday}
          friday={friday}
          saturday={saturday}
          sunday={sunday}
        />
        <Item
          weekend={weekend}
          tasks={tasks}
          setToggleModalAddTable={setToggleModalAddTable}
          setWeekend={setWeekend}
        />
      </>
    </Main>
  )
}

export default Todo
