import {
  GET_FOLDERS,
  GET_FOLDERS_FAILURE,
  GET_FOLDERS_REQUEST,
  RESET_SENT_DATA,
  SEND_CONTENT_REQUEST,
  SEND_CONTENT_SUCCESS,
} from "../types/info";

export const getFolders = (token) => {
  return (dispatch) => {
    dispatch({ type: GET_FOLDERS_REQUEST });
    fetch("https://shopngo.herokuapp.com/api/info/folder", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.errors) {
          dispatch({
            type: GET_FOLDERS_FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: GET_FOLDERS, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const setDocumentContent = ({ token, content, date, docId }) => {
  return (dispatch) => {
    dispatch({ type: SEND_CONTENT_REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/info/info/${docId}`, {
      method: "PATCH",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        content: content,
        date: date,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: SEND_CONTENT_REQUEST,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: SEND_CONTENT_SUCCESS });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const resetSentData = () => {
  return (dispatch) => {
    dispatch({ type: RESET_SENT_DATA });
  };
};
