import React from 'react'
import Drawer from 'react-drag-drawer'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

import { Div } from '../UI/Styled/Views'
import deleteIcon from '../../Assets/Icons/deleteIcon.svg'
import Colors from '../../Assets/Colors'
import { Button } from '../UI/Styled/Button'
import styled from 'styled-components'

const Text = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #6c757d;
  text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
`
const CardIcon = styled.img`
  position: absolute;
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  right: ${({ right }) => right ?? '10px'};
  cursor: pointer;
`
const Btn = styled(Button)`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
`

const ModalPicture = ({ open, toggleOpen, img, setImg }) => {
  console.log('img:', img)
  return (
    <Drawer
      open={open}
      onRequestClose={() => {
        setImg('')
        toggleOpen(false)
      }}
    >
      <Div
        style={{
          position: 'relative',
          padding: 20,
          borderRadius: 20,
          paddingTop: 30,
          width: '600px',
          height: '600px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <TransformWrapper
          defaultScale={1}
          defaultPositionX={200}
          defaultPositionY={100}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <>
              <TransformComponent>
                <div
                  style={{
                    maxWidth: 600,
                    maxHeight: 600,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <img
                    loading='lazy'
                    style={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: 10
                    }}
                    src={img}
                    alt=''
                  />
                </div>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      </Div>
    </Drawer>
  )
}
export default ModalPicture
