import {
  CREATE_PAIN_FAILURE,
  CREATE_PAIN_SUCCESS,
  GET_PAIN_HISTORY,
  GET_PAIN_HISTORY_FAILURE,
  GET_PAIN_HISTORY_REQUEST,
  GET_STOCK,
  GET_STOCK_FAILURE,
  GET_STOCK_REQUEST,
} from "../types/pain";

const initialState = {
  pains: null,
  painHistory: null,
  error: false,
  errorStatus: "",
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STOCK:
      return Object.assign({}, state, {
        pains: action.value,
        loading: false,
      });
    case GET_STOCK_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_STOCK_FAILURE:
      return Object.assign({}, state, {
        error: true,
        errorStatus: action.value,
        loading: false,
      });
    case CREATE_PAIN_SUCCESS:
      return state;
    case CREATE_PAIN_FAILURE:
      return state;
    case GET_PAIN_HISTORY:
      return Object.assign({}, state, {
        painHistory: action.value.items,
        pageCount: action.value.pageCount,
        loading: false,
      });
    case GET_PAIN_HISTORY_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_PAIN_HISTORY_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value,
      });
    default:
      return state;
  }
};
