import React, { useEffect } from "react";
import DefaultScreen from "../Components/UI/DefaultScreen";
import { H6 } from "../Components/UI/Styled/Texts";
import { Div } from "../Components/UI/Styled/Views";
import History from "../Components/UI/History";
import { useDispatch, useSelector } from "react-redux";
import { getRepasHistory } from "../store/actions/repas";
import LoadingSpinner from "../Components/UI/LoadingSpinner";

const Repas = () => {
  const { repas, pageCount, loading } = useSelector((state) => state.repas);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();


  return (
    <DefaultScreen title="Repas personnel">
      <History
        title="Repas personnel"
        loadData={getRepasHistory}
        pageCount={pageCount}
      >
        <Div
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <H6 style={{ fontWeight: 600, width: "30%" }}>Date</H6>
          <H6 style={{ fontWeight: 600, width: "30%" }}>Nom</H6>
          <H6 style={{ fontWeight: 600, width: "30%", textAlign: "right" }}>
            Description
          </H6>
        </Div>
        {loading ? (
          <LoadingSpinner isLoading={loading} />
        ) : repas ? (
          repas.map((r, i) => (
            <Div
              style={{
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                marginTop: 10,
              }}
              key={i}
            >
              <H6 style={{ width: "30%" }}>
                {("0" + new Date(r.date).getDate()).slice(-2)}-
                {("0" + (new Date(r.date).getMonth() + 1)).slice(-2)}
                {"  "}
                {("0" + (new Date(r.date).getHours())).slice(-2)}:
                {("0" + (new Date(r.date).getMinutes())).slice(-2)}
              </H6>
              <H6 style={{ width: "30%" }}>
                {r.employe.name} {r.employe.surname}
              </H6>
              <H6 style={{ width: "30%", textAlign: "right" }}>{r.repas}</H6>
            </Div>
          ))
        ) : null}
      </History>
    </DefaultScreen>
  );
};

export default Repas;
