import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Colors from "../Assets/Colors";
import { Rectangle, Triangle } from "../Components/UI/Styled/Shapes";
import { H1, H2, H5 } from "../Components/UI/Styled/Texts";
import { Div, Card, Scroll, Screen } from "../Components/UI/Styled/Views";
import Input from "../Components/UI/Input";
import { login } from "../store/actions/auth";
import { Button } from "../Components/UI/Styled/Button";
import { getLoginError } from "../Services/ErrorHandler";
import LoadingSpinner from "../Components/UI/LoadingSpinner";
import Checkbox from "@material-ui/core/Checkbox";
import { Image } from "../Components/UI/Styled/Images";
import logo from "../Assets/Icons/Logo.png";
import Drawer from "react-drag-drawer";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [email_valid, setEmailValid] = useState(true);
  const [password_valid, setPasswordValid] = useState(true);
  const [remember, setRemember] = useState(false);

  const [forgotModal, setForgotModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetConfirmation, setResetConfirmation] = useState(false);
  const [resetError, setResetError] = useState(null);

  const { errorStatus, error, loading } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const onSubmit = () => {
    if (validate()) {
      dispatch(login(email, password, remember));
    }
  };

  const validate = () => {
    var valid = true;
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,99}$/i.test(email)) {
      setEmailValid(false);
      valid = false;
    }
    if (password === "") {
      setPasswordValid(false);
      valid = false;
    }
    return valid;
  };

  const toggleRemember = () => {
    setRemember((r) => !r);
  };

  const forgotPass = () => {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,99}$/i.test(resetEmail)) {
      fetch("https://shopngo.herokuapp.com/api/user/forgot-my-pass", {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          email: resetEmail,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          console.log(res);
          if (res.errors) {
            console.log(res.errors);
            setResetError(res.errors.status);
          } else {
            console.log(res);
            setForgotModal(false);
            setResetConfirmation(true);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <Screen style={{ justifyContent: "center", position: "relative" }}>
      <Drawer open={forgotModal} onRequestClose={() => setForgotModal(false)}>
        <Card>
          <Div style={{ marginBottom: 20 }}>
            <Input
              type='input'
              title='E-mail'
              value={resetEmail}
              onChangeText={setResetEmail}
            />
            {resetError == 404 ? (
              <H5 style={{ color: Colors.red, marginLeft: 15 }}>
                Aucun compte existant pour cette adresse
              </H5>
            ) : null}
          </Div>
          {resetConfirmation ? (
            <H5>
              Un e-mail vous a été envoyée à l'adresse spécifiée avec les
              instructions à suivre
            </H5>
          ) : (
            <Button onClick={forgotPass}>Changer mon mot de passe</Button>
          )}
        </Card>
      </Drawer>
      <Div style={{ position: "absolute", top: 0 }}>
        <Rectangle />
        <Triangle />
      </Div>
      <Div style={{ position: "absolute", left: "50%", top: "5%" }}>
        <Image
          src={logo}
          style={{
            position: "relative",
            left: "-50%",
            width: 180,
            zIndex: 900,
          }}
        />
      </Div>
      <Div
        style={{
          position: "absolute",
          zIndex: 10,
          justifyContent: "center",
          alignItems: "center",
          left: "50%",
          top: "20%",
        }}
      >
        <Card
          style={{
            position: "relative",
            left: "-50%",
          }}
        >
          <H2 style={{ marginBottom: 20 }}>Connexion</H2>
          <Input
            title='E-mail'
            type='input'
            value={email}
            onChangeText={setEmail}
            error={error || !email_valid}
            submit={onSubmit}
          />
          <Input
            title='Mot de passe'
            type='password'
            value={password}
            onChangeText={setPassword}
            secureTextEntry={true}
            error={error || !password_valid}
            submit={onSubmit}
          />

          <Div
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
              cursor: "pointer",
            }}
            onClick={() => toggleRemember()}
          >
            <Checkbox color={Colors.blue} checked={remember} />
            <H5>Se souvenir de moi</H5>
          </Div>
          {error ? (
            <H5 style={{ color: Colors.red, fontSize: 14 }}>
              {getLoginError(errorStatus)}
            </H5>
          ) : null}
          {loading ? (
            <LoadingSpinner isLoading={loading} />
          ) : (
            <Button style={{ marginTop: 10 }} onClick={onSubmit}>
              Se connecter
            </Button>
          )}
          <Div style={{ width: "100%", alignItems: "center", marginTop: 25 }}>
            <H5
              style={{
                color: Colors.blue,
                cursor: "pointer",
                width: "fit-content",
              }}
              onClick={() => setForgotModal(true)}
            >
              Mot de passe oublié
            </H5>
          </Div>
        </Card>
      </Div>
    </Screen>
  );
};

export default Login;
