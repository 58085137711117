import React, { useState } from 'react'
import Drawer from 'react-drag-drawer'
import Colors from '../../Assets/Colors'
import { Button } from '../UI/Styled/Button'
import { H3, H4 } from '../UI/Styled/Texts'
import { Div } from '../UI/Styled/Views'

const DeleteModal = props => {
  const { open, onRequestClose, deleteCloture } = props
  return (
    <Drawer open={open} onRequestClose={onRequestClose}>
      <Div
        style={{
          backgroundColor: Colors.background,
          padding: 20,
          borderRadius: 20,
          paddingTop: 30
        }}
      >
        <H3>Êtes-vous sûr de vouloir supprimer cette clôture?</H3>
        <Div
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 50,
            marginBottom: 10
          }}
        >
          <Div
            onClick={() => deleteCloture()}
            style={{
              background: Colors.green,
              padding: 10,
              borderRadius: 5,
              cursor: 'pointer'
            }}
          >
            <H4 style={{ color: Colors.white }}>Confirmer</H4>
          </Div>
          <Div
            onClick={() => onRequestClose()}
            style={{
              background: Colors.red,
              padding: 10,
              borderRadius: 5,
              cursor: 'pointer'
            }}
          >
            <H4 style={{ color: Colors.white }}>Annuler</H4>
          </Div>
        </Div>
      </Div>
    </Drawer>
  )
}

export default DeleteModal
