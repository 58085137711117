import React, { useState } from "react";
import DefaultScreen from "../Components/UI/DefaultScreen";
import Input from "../Components/UI/Input";
import LoadingSpinner from "../Components/UI/LoadingSpinner";
import { Button } from "../Components/UI/Styled/Button";
import { H1, H5 } from "../Components/UI/Styled/Texts";
import { Card, Div, Screen } from "../Components/UI/Styled/Views";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router-dom";
import Colors from "../Assets/Colors";
import { autoAuth } from "../store/actions/auth";
import { useDispatch } from "react-redux";

const Verification = () => {
  const { token } = useParams();

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [password, setPassword] = useState("");
  const [name_valid, setNameValid] = useState(true);
  const [surname_valid, setSurnameValid] = useState(true);
  const [password_valid, setPasswordValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState("");

  const validate = () => {
    var valid = true;
    if (password === "") {
      setPasswordValid(false);
      valid = false;
    }
    if (!/^[A-Z ]{3,99}$/i.test(name)) {
      setNameValid(false);
      valid = false;
    }
    if (!/^[A-Z ]{3,99}$/i.test(surname)) {
      setSurnameValid(false);
      valid = false;
    }
    return valid;
  };

  const onSubmit = () => {
    if (validate()) {
      setLoading(true);
      fetch(`https://shopngo.herokuapp.com/api/user/register`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          name: name,
          surname: surname,
          token: token,
          password: password,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (!res.errors) {
            console.log(res);
            fetch("https://shopngo.herokuapp.com/api/currency-stock", {
              method: "POST",
              headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${res.token}`,
              }),
              body: JSON.stringify({
                coins: {
                  "0,01": 0,
                  "0,02": 0,
                  "0,05": 0,
                  "0,10": 0,
                  "0,20": 0,
                  "0,50": 0,
                  "1,00": 0,
                  "2,00": 0,
                },
                date: new Date(),
              }),
            })
              .then((response) => response.json())
              .then((res) => {
                if (res.errors) {
                  console.log(res.errors);
                }
              })
              .catch((error) => console.log(error));
            dispatch(autoAuth(res.token, res.user));
            setLoading(false);
            setDone(true);
          } else {
            setError(res.errors.message);
            setLoading(false);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <Screen>
      {done ? (
        <Redirect to={{ pathname: "/" }} />
      ) : (
        <Div
          style={{
            position: "absolute",
            zIndex: 10,
            justifyContent: "center",
            alignItems: "center",
            left: "50%",
            top: "20%",
          }}
        >
          <Card
            style={{
              position: "relative",
              left: "-50%",
            }}
          >
            <H1>Créez votre compte</H1>
            <Input
              title="Nom"
              type="input"
              value={surname}
              onChangeText={setSurname}
              error={!surname_valid}
            />
            <Input
              title="Prénom"
              type="input"
              value={name}
              onChangeText={setName}
              error={!name_valid}
            />
            <Input
              title="Mot de passe"
              type="password"
              value={password}
              onChangeText={setPassword}
              secureTextEntry={true}
              error={!password_valid}
            />
            {error.length > 0 ? (
              <H5 style={{ color: Colors.red }}>{error}</H5>
            ) : null}
            {loading ? (
              <LoadingSpinner isLoading={loading} />
            ) : (
              <Button style={{ marginTop: 20 }} onClick={onSubmit}>
                Valider
              </Button>
            )}
          </Card>
        </Div>
      )}
    </Screen>
  );
};

export default Verification;
