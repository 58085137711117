import React from "react";
import { InputText } from "./Styled/Texts";
import EnterInput from "./EnterInput";
import { Div } from "./Styled/Views";
import Colors from "../../Assets/Colors";

const Input = (props) => {
  return (
    <Div style={{ ...props.style, marginTop: 20, marginRight:10, marginLeft:10 }}>
      <InputText>{props.title}</InputText>
      <EnterInput
        type={props.type}
        value={props.value}
        onChange={props.onChangeText}
        autoCapitalize="none"
        spellCheck={false}
        style={{ borderColor: props.error ? Colors.red : "#858585" }}
        submit={props.submit}
      />
    </Div>
  );
};

export default Input;
