import { removeData, storeData } from '../../Services/Storage'
import {
  AUTO_AUTH,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGOUT,
  UPLOAD_PROFILE_PIC,
  UPLOAD_PROFILE_PIC_FAILURE,
  UPLOAD_PROFILE_PIC_REQUEST
} from '../types/auth'

export const login = (email, password, remember) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_REQUEST })
    fetch('https://shopngo.herokuapp.com/api/user/login', {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        email: email,
        password: password
      })
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.errors) {
          dispatch({
            type: LOGIN_FAILURE,
            value: res.errors.status
          })
        } else {
          dispatch({ type: LOGIN, value: res })
          if (remember) {
            storeData('token', res.token, { json: true })
            storeData('user', res.user, { json: true })
          }
        }
      })
      .catch((error) => console.log(error))
  }
}

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: LOGOUT })
    removeData('token')
    removeData('user')
  }
}

export const uploadProfilePicture = ({ picture, uid, token }) => {
  const formData = new FormData()

  formData.append('pic', picture)
  formData.append('uid', uid)

  return (dispatch) => {
    dispatch({ type: UPLOAD_PROFILE_PIC_REQUEST })
    fetch('https://shopngo.herokuapp.com/api/user/upload-profile-picture', {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${token}`
      }),
      body: formData
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.errors) {
          dispatch({
            type: UPLOAD_PROFILE_PIC_FAILURE,
            value: res.errors.status
          })
        } else {
          dispatch({ type: UPLOAD_PROFILE_PIC, value: res })
        }
      })
      .catch((error) => console.log(error))
  }
}

export const autoAuth = (token, user) => {
  return (dispatch) => {
    dispatch({ type: AUTO_AUTH, value: { token: token, user: user } })
    removeData('token')
    removeData('user')
  }
}
