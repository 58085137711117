import {} from '../types/clotures'

const initialState = {
  categories: [
    {
      name: 'Responsable',
      _id: 0
    },
    {
      name: 'Employé',
      _id: 1
    },
    {
      name: 'Autre ?',
      _id: 2
    },
    {
      name: 'Responsable',
      _id: 3
    }
  ],

  template: [
    {
      categoryName: 'Sous-table 1',
      tasks: [
        {
          name: 'Première to do',
          days: [true, false, true, false, false, false, true],
          picture: false,
          comment: false,
          _id: '1'
        },
        {
          name: 'Deuxième to do',
          days: [false, false, true, true, false, false, true],
          picture: true,
          comment: false,
          _id: '2'
        }
      ]
    },
    {
      categoryName: 'Sous-table 2',
      tasks: [
        {
          name: 'Première to do autre table',
          days: [true, false, true, false, false, false, true],
          picture: false,
          comment: false,
          _id: '3'
        },
        {
          name:
            'Deuxième to do de la table 2, celle en dessous de la table 1. Ou encore celle qui serait avant la table 3',
          days: [true, false, true, true, false, false, true],
          picture: true,
          comment: true,
          _id: '4'
        }
      ]
    }
  ],

  error: false,
  errorStatus: '',
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
