import React from "react";
import { H6 } from "../UI/Styled/Texts";
import { Div } from "../UI/Styled/Views";

const ProfileInfoCardElement = (props) => {
  const { title, value } = props;
  return (
    <Div
      style={{
        width: "100%",
        margin: 4,
      }}
    >
      <Div
        style={{
          flexDirection: "row",
          width: "50%",
          justifyContent: "flex-start",
        }}
      >
        <H6 style={{ width: "50%", fontWeight: 600 }}>{title} :</H6>
        <H6 style={{ width: "50%" }}>{value}</H6>
      </Div>
    </Div>
  );
};

export default ProfileInfoCardElement;
