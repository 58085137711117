import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardCard from "../Components/Dashboard/DashboardCard";
import GraphOptions from "../Components/Dashboard/GraphOptions";
import ClotureGraph from "../Components/UI/ClotureGraph";
import DefaultScreen from "../Components/UI/DefaultScreen";
import { Card, Div } from "../Components/UI/Styled/Views";
import { getClotures, getLastYearClotures } from "../store/actions/clotures";
import LoadingSpinner from "../Components/UI/LoadingSpinner";

const Dashboard = () => {
  const { clotures, lastYearClotures, loading } = useSelector(
    (state) => state.clotures
  );
  const { token } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [date, setDate] = useState(new Date());
  const [period, setPeriod] = useState({ value: "jour", label: "Jour" });
  const [startTime, setStartTime] = useState({
    value: "null",
    label: "Heure de début",
  });
  const [endTime, setEndTime] = useState({
    value: "null",
    label: "Heure de fin",
  });

  const [cardCA, setCardCA] = useState();
  const [cardCash, setCardCash] = useState();
  const [cardPertes, setCardPertes] = useState();

  const [lastYearCA, setLastYearCA] = useState(null);
  const [lastYearCash, setLastYearCash] = useState(null);
  const [lastYearPertes, setLastYearPertes] = useState(null);
  const [timeError, setTimeError] = useState(false);

  useEffect(() => {
    if (clotures) {
      var dateFrom;
      var clotureDateFrom;
      const d = new Date(date);
      d.setHours(23, 59, 59, 0);
      const d2 = new Date(date);
      switch (period.value) {
        case "jour":
          dateFrom = new Date(d.setDate(d.getDate() - 13));
          clotureDateFrom = new Date(d2.setDate(d2.getDate() - 1));
          break;
        case "semaine":
          dateFrom = new Date(d.setDate(d.getDate() - 13 * 7));
          clotureDateFrom = new Date(d2.setDate(d2.getDate() - 7));
          break;
        case "mois":
          dateFrom = new Date(d.setMonth(d.getMonth() - 13));
          clotureDateFrom = new Date(d2.setMonth(d2.getMonth() - 1));
          break;
        case "annee":
          dateFrom = new Date(d.setFullYear(d.getFullYear() - 12));
          clotureDateFrom = new Date(d2.setFullYear(d2.getFullYear() - 1));
          break;
      }
      console.log(new Date(dateFrom));
      if (
        startTime.value === "null" ||
        endTime.value === "null" ||
        parseInt(startTime.value.split(":")[0]) >=
          parseInt(endTime.value.split(":")[0])
      ) {
        if (
          parseInt(startTime.value.split(":")[0]) >=
          parseInt(endTime.value.split(":")[0])
        ) {
          setTimeError(true);
        } else {
          setTimeError(false);
        }
        dispatch(
          getClotures({
            token: token,
            dateFrom: dateFrom,
            dateTo: date,
          })
        );
        const d = new Date(date);
        const d2 = new Date(clotureDateFrom);
        dispatch(
          getLastYearClotures({
            token: token,
            dateFrom: new Date(d2.setFullYear(d2.getFullYear() - 1)),
            dateTo: new Date(d.setFullYear(d.getFullYear() - 1)),
          })
        );
      } else {
        setTimeError(false);
        dispatch(
          getClotures({
            token: token,
            dateFrom: dateFrom,
            dateTo: date,
            hourFrom: startTime.value,
            hourTo: endTime.value,
          })
        );
        const d = new Date(date);
        const d2 = new Date(clotureDateFrom);
        dispatch(
          getLastYearClotures({
            token: token,
            dateFrom: new Date(d2.setFullYear(d2.getFullYear() - 1)),
            dateTo: new Date(d.setFullYear(d.getFullYear() - 1)),
            hourFrom: startTime.value,
            hourTo: endTime.value,
          })
        );
      }
    }
  }, [period, date, startTime, endTime]);

  useEffect(() => {
    const d = new Date();
    d.setHours(23, 59, 59, 0);
    dispatch(
      getClotures({
        token: token,
        dateFrom: new Date(d.setDate(d.getDate() - 13)),
        dateTo: new Date(),
      })
    );
    const d2 = new Date();
    d2.setHours(23, 59, 59, 0);
    const lastYear = new Date(d2);
    lastYear.setFullYear(lastYear.getFullYear() - 1);
    lastYear.setDate(lastYear.getDate() - 1);
    dispatch(
      getLastYearClotures({
        token: token,
        dateFrom: lastYear,
        dateTo: new Date(d2.setFullYear(d2.getFullYear() - 1)),
      })
    );
  }, []);

  useEffect(() => {
    if (lastYearClotures) {
      if (lastYearClotures.length > 0) {
        var totalCA = 0;
        var totalCash = 0;
        var totalPertes = 0;
        for (let i = 0; i < lastYearClotures.length; i++) {
          totalCA += lastYearClotures[i].CA;
          totalCash += lastYearClotures[i].cash;
          totalPertes +=
            lastYearClotures[i].cash - lastYearClotures[i].cashMachine;
        }
        setLastYearCA(totalCA);
        setLastYearCash(totalCash);
        setLastYearPertes(totalPertes);
      } else {
        setLastYearCA(0);
        setLastYearCash(0);
        setLastYearPertes(0);
      }
    }
  }, [lastYearClotures]);

  return (
    <DefaultScreen title='Dashboard'>
      <Card style={{ marginBottom: 30 }}>
        <GraphOptions
          date={date}
          setDate={setDate}
          period={period}
          setPeriod={setPeriod}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          timeError={timeError}
        />
        <Div style={{ width: "100%" }}>
          {loading ? (
            <LoadingSpinner isLoading={loading} style={{ marginTop: 40 }} />
          ) : (
            <ClotureGraph
              period={period}
              date={date}
              clotures={clotures}
              height={400}
              setCardCash={setCardCash}
              setCardCA={setCardCA}
              setCardPertes={setCardPertes}
              employee={false}
            />
          )}
        </Div>
      </Card>
      <Div style={{ flexDirection: "row" }}>
        <DashboardCard
          title='Cash'
          amount={cardCash}
          lastYearAmount={lastYearCash}
          date={date}
          style={{ marginRight: 30, flex: 1 }}
        />
        <DashboardCard
          title="Chiffre d'affaire"
          amount={cardCA}
          lastYearAmount={lastYearCA}
          date={date}
          style={{ marginRight: 30, flex: 1 }}
        />
        <DashboardCard
          title='Différence'
          amount={cardPertes}
          lastYearAmount={lastYearPertes}
          date={date}
          style={{ flex: 1 }}
        />
      </Div>
    </DefaultScreen>
  );
};

export default Dashboard;
