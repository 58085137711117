import styled from "styled-components";
import Colors from "../../../Assets/Colors";

export const Button = styled.button`
  padding: 8px;
  border-radius: 5px;
  background-color: ${Colors.blue};
  align-items: center;
  justify-content: center;
  color: white;
  font-weight:600;
  text-align: center;
  border: none;
  text-decoration: none;
  font-size: 20px;
  cursor: pointer;
`;
