export const GET_HISTORY_CLOTURES = "GET_HISTORY_CLOTURES";
export const GET_HISTORY_CLOTURES_REQUEST = "GET_HISTORY_CLOTURES_REQUEST";
export const GET_HISTORY_CLOTURES_FAILURE = "GET_HISTORY_CLOTURES_FAILURE";
export const GET_EMPLOYEE_HISTORY_CLOTURES_REQUEST =
  "GET_EMPLOYEE_HISTORY_CLOTURES_REQUEST";
export const GET_EMPLOYEE_HISTORY_CLOTURES = "GET_EMPLOYEE_HISTORY_CLOTURES";
export const GET_EMPLOYEE_HISTORY_CLOTURES_FAILURE =
  "GET_EMPLOYEE_HISTORY_CLOTURES_FAILURE";
export const GET_EMPLOYEE_CLOTURES_REQUEST = "GET_EMPLOYEE_CLOTURES_REQUEST";
export const GET_EMPLOYEE_CLOTURES = "GET_EMPLOYEE_CLOTURES";
export const GET_EMPLOYEE_CLOTURES_FAILURE = "GET_EMPLOYEE_CLOTURES_FAILURE";
export const GET_CLOTURES = "GET_CLOTURES";
export const GET_CLOTURES_REQUEST = "GET_CLOTURES_REQUEST";
export const GET_CLOTURES_FAILURE = "GET_CLOTURES_FAILURE";
export const GET_LAST_YEAR_CLOTURES = "GET_LAST_YEAR_CLOTURES";
export const GET_LAST_YEAR_CLOTURES_FAILURE = "GET_LAST_YEAR_CLOTURES_FAILURE";
export const GET_LAST_YEAR_CLOTURES_REQUEST = "GET_LAST_YEAR_CLOTURES_REQUEST";
