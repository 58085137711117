import {
  GET_CATEGORY_TASKS_SUCCESS,
  ADD_CATEGORY_TASKS_SUCCESS,
  DELETE_CATEGORY_TASKS_SUCCESS,
  UPDATE_CATEGORY_TASKS_SUCCESS,
  FAILURE,
  REQUEST
} from '../types/categories'

const initialState = {
  tables: [],
  error: false,
  errorStatus: '',
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORY_TASKS_SUCCESS:
      return Object.assign({}, state, {
        todos: action.value,
        loading: false
      })
    case REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value
      })
    case ADD_CATEGORY_TASKS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        errorStatus: '',
        todos: [...state.todos, action.value]
      })
    case REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value
      })
    case DELETE_CATEGORY_TASKS_SUCCESS:
      // action.value = id de l'objet à delete
      const newTodos = [...state.todos].filter(
        (todo) => todo._id != action.value
      )
      return Object.assign({}, state, {
        loading: false,
        error: false,
        errorStatus: '',
        todos: newTodos
      })
    case REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value
      })
    case UPDATE_CATEGORY_TASKS_SUCCESS:
      // action.value => nouveau doc
      let todosCopy = [...state.todos]
      for (let todo in todosCopy) {
        if (todosCopy[todo]._id == action.value._id) {
          todosCopy[todo] = action.value
        }
      }
      return Object.assign({}, state, {
        loading: false,
        error: false,
        errorStatus: '',
        todos: todosCopy
      })
    case REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value
      })
    default:
      return state
  }
}
