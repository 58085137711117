import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Colors from "../../Assets/Colors";
import { Div } from "./Styled/Views";

const LoadingSpinner = (props) => {
  const { isLoading } = props;
  return (
    <Div
      style={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        ...props.style,
      }}
    >
      <ClipLoader size={60} color={Colors.blue} loading={isLoading} />
    </Div>
  );
};

export default LoadingSpinner;
