import React from "react";
import { H6 } from "../UI/Styled/Texts";
import { Div } from "../UI/Styled/Views";
import History from "../UI/History";
import { getEmployeeHistoryClotures } from "../../store/actions/clotures";
import Link from "../Link";
import Colors from "../../Assets/Colors";

const ClotureHistoryCard = (props) => {
  const { clotures, pageCount, eid } = props;

  return (
    <History
      title='Historique des clôtures'
      loadData={getEmployeeHistoryClotures}
      pageCount={pageCount}
      eid={eid}
      total={
        clotures &&
        "Différence totale: " +
          clotures
            ?.reduce(
              (acc, c) =>
                acc +
                (parseFloat(c.cash) - parseFloat(c.cashMachine)) /
                  c.employes.length,
              0
            )
            .toFixed(2) +
          "€"
      }
    >
      <Div style={{ flexDirection: "row", width: "100%", marginBottom: 5 }}>
        <H6 style={{ fontWeight: 600, flex: 1 }}>Date</H6>
        <H6 style={{ fontWeight: 600, flex: 1 }}>CA</H6>
        <H6 style={{ fontWeight: 600, flex: 1 }}>Cash</H6>
        <H6 style={{ fontWeight: 600, flex: 1 }}>Différence</H6>
      </Div>
      {clotures
        ? clotures.map((cloture, i) => (
            <Link to={`/cloture/${cloture._id}`} style={{ width: "100%" }}>
              <Div
                style={{
                  width: "100%",
                  flexDirection: "row",
                  marginBottom: 5,
                  marginTop: 5,
                }}
                key={i}
              >
                <H6 style={{ fontWeight: 500, flex: 1 }}>
                  {("0" + new Date(cloture.heureDeCloture).getDate()).slice(-2)}
                  -
                  {(
                    "0" +
                    (new Date(cloture.heureDeCloture).getMonth() + 1)
                  ).slice(-2)}
                </H6>
                <H6 style={{ fontWeight: 500, flex: 1 }}>
                  {parseFloat(cloture.CA).toFixed(2)}€
                </H6>
                <H6 style={{ fontWeight: 500, flex: 1 }}>
                  {parseFloat(cloture.cash).toFixed(2)}€
                </H6>
                {(
                  (parseFloat(cloture.cash) - parseFloat(cloture.cashMachine)) /
                  cloture.employes.length
                ).toFixed(2) >= 0 ? (
                  <H6 style={{ fontWeight: 500, flex: 1, color: Colors.green }}>
                    {(
                      (parseFloat(cloture.cash) -
                        parseFloat(cloture.cashMachine)) /
                      cloture.employes.length
                    ).toFixed(2)}
                    €
                  </H6>
                ) : (
                  <H6 style={{ fontWeight: 500, flex: 1, color: Colors.red }}>
                    {(
                      (parseFloat(cloture.cash) -
                        parseFloat(cloture.cashMachine)) /
                      cloture.employes.length
                    ).toFixed(2)}
                    €
                  </H6>
                )}
              </Div>
            </Link>
          ))
        : null}
    </History>
  );
};

export default ClotureHistoryCard;
