import styled from "styled-components";
import Colors from "../../../Assets/Colors";

export const HeaderText = styled.h1`
  color: ${Colors.white};
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
  margin: 0px;
`;

export const HeaderTabText = styled.h1`
  color: ${Colors.white};
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  margin: 0px;
`;

export const InputText = styled.h1`
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  margin: 2px;
`;

export const H1 = styled.h1`
  font-size: 48px;
  font-family: 'Montserrat', sans-serif;
  margin: 0px;
`;

export const H2 = styled.h2`
  font-size: 36px;
  font-family: 'Montserrat', sans-serif;
  margin: 0px;
`;

export const H3 = styled.h3`
  font-size: 26px;
  font-family: 'Montserrat', sans-serif;
  margin: 0px;
  font-weight: 700;
`;

export const H4 = styled.h4`
  font-size: 20px;
  font-family: 'Montserrat', sans-serif;
  margin: 0px;
  color: ${Colors.grey};
`;

export const H5 = styled.h5`
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  margin: 0px;
  color: ${Colors.grey};
`;

export const H6 = styled.h6`
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  margin: 0px;
  color: ${Colors.grey};
`;
