import React, { useEffect, useState } from "react";
import DefaultScreen from "../Components/UI/DefaultScreen";
import { Div } from "../Components/UI/Styled/Views";
import { H6 } from "../Components/UI/Styled/Texts";
import { useDispatch, useSelector } from "react-redux";
import { getHistoryClotures } from "../store/actions/clotures";
import ClotureElement from "../Components/Historique/ClotureElement";
import History from "../Components/UI/History";
import LoadingSpinner from "../Components/UI/LoadingSpinner";

const Historique = () => {
  const { historyClotures, pageCount, loading } = useSelector(
    (state) => state.clotures
  );
  return (
    <DefaultScreen title='Historique'>
      <History
        title='Historique des clôtures'
        loadData={getHistoryClotures}
        pageCount={pageCount}
      >
        <Div style={{ flexDirection: "row", width: "100%", marginBottom: 5 }}>
          <Div style={{ width: 50 }} />
          <H6 style={{ fontWeight: 600, marginRight: 3, width: "7%" }}>Date</H6>
          <H6 style={{ fontWeight: 600, marginRight: 3, width: "7%" }}>
            Caisse
          </H6>
          <H6 style={{ fontWeight: 600, marginRight: 3, width: "35%" }}>Nom</H6>
          <H6 style={{ fontWeight: 600, marginRight: 3, width: "15%" }}>CA</H6>
          <H6 style={{ fontWeight: 600, marginRight: 3, width: "15%" }}>
            Cash
          </H6>
          <H6 style={{ fontWeight: 600, marginRight: 3, width: "7%" }}>
            Différence
          </H6>
        </Div>
        {loading ? (
          <LoadingSpinner isLoading={loading} />
        ) : historyClotures ? (
          historyClotures.map((c, i) => <ClotureElement key={i} cloture={c} />)
        ) : null}
      </History>
    </DefaultScreen>
  );
};

export default Historique;
