import {
  GET_HISTORY_SUCCESS,
  ADD_HISTORY_SUCCESS,
  UPDATE_HISTORY_SUCCESS,
  REQUEST,
  FAILURE,
} from "../types/history";

export const getHistory = ({ token, id }) => {
  return (dispatch) => {
    dispatch({ type: REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/history/info?id=${id}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: GET_HISTORY_SUCCESS, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const addHistory = ({ token, name, todos }) => {
  return (dispatch) => {
    dispatch({ type: REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/history`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        name: name,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: ADD_HISTORY_SUCCESS, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const updateHistory = ({ token, _id, checked }) => {
  return (dispatch) => {
    dispatch({ type: REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/history?id=${_id}`, {
      method: "PATCH",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        checked,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: UPDATE_HISTORY_SUCCESS, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};
