import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../Components/UI/Styled/Button";
import { TextInput } from "../Components/UI/Styled/TextInputs";
import { H3, H5 } from "../Components/UI/Styled/Texts";
import { Card, Div, Screen } from "../Components/UI/Styled/Views";
import { Redirect } from "react-router-dom";
import Colors from "../Assets/Colors";

const PasswordReset = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);

  const submit = () => {
    if (password.length > 6) {
      fetch(`https://shopngo.herokuapp.com/api/user/password-reset`, {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify({
          token: token,
          newPassword: password,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          console.log(res);
          setDone(true);
        })
        .catch((error) => console.log(error));
    } else {
      setError(true);
    }
  };

  console.log(done);

  return (
    <Screen style={{ justifyContent: "center" }}>
      {done ? (
        <Redirect to={{ pathname: "/" }} />
      ) : (
        <Card>
          <H3 style={{ margin: 10 }}>
            Veuillez entrer votre nouveau mot de passe :
          </H3>
          <TextInput
            style={{ margin: 10 }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error ? (
            <H5 style={{ color: Colors.red }}>
              Veuillez entrer un mot de passe valable (minimum 6 caractères)
            </H5>
          ) : null}
          <Button style={{ margin: 10, padding: 15 }} onClick={submit}>
            <H3 style={{ color: "white" }}>Valider</H3>
          </Button>
        </Card>
      )}
    </Screen>
  );
};

export default PasswordReset;
