import React, { useState } from "react";
import Colors from "../../Assets/Colors";
import { Line } from "../UI/Styled/Shapes";
import { H5 } from "../UI/Styled/Texts";
import { Card, Div } from "../UI/Styled/Views";
import TitleCard from "../UI/TitleCard";
import logoPhoto from "../../Assets/Icons/Photo.png";
import { Image } from "../UI/Styled/Images";
import Drawer from "react-drag-drawer";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
const ResumeInfoCard = (props) => {
  const [photoModal, setPhotoModal] = useState(false);

  return (
    <Card
      style={{ ...props.style, justifyContent: "flex-start", marginBottom: 30 }}
    >
      <Drawer open={photoModal} onRequestClose={() => setPhotoModal(false)}>
        {props.photo ? (
          // <Div>
          //   <Image
          //     src={props.photo}
          //     style={{
          //       maxWidth: '70vw',
          //       maxHeight: '70vh',
          //       minWidth: '10vw',
          //       minHeight: '10vh'
          //     }}
          //   />
          // </Div>
          <TransformWrapper
            defaultScale={1}
            defaultPositionX={200}
            defaultPositionY={100}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <React.Fragment>
                <TransformComponent>
                  <Div style={{ maxWidth: "70vw", maxHeight: "70vh" }}>
                    <img
                      width='600px'
                      height='600px'
                      src={props.photo}
                      alt='test'
                      objectFit='contain'
                    />
                  </Div>
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        ) : null}
      </Drawer>
      <Div
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <H5 style={{ fontWeight: 600 }}>{props.title}</H5>
        {props.photo ? (
          <Image src={logoPhoto} onClick={() => setPhotoModal(true)} />
        ) : null}
      </Div>
      <Line />
      {props.children}
    </Card>
  );
};

export default ResumeInfoCard;
