import React from "react";
import { Line } from "./Styled/Shapes";
import { H5 } from "./Styled/Texts";
import { Card, Div } from "./Styled/Views";

const TitleCard = (props) => {
  return (
    <Card style={{ ...props.style, justifyContent: "flex-start" }}>
      <Div
        style={{
          width: "100%",
          justifyContent: "flex-start",
          flexDirection: "row",
        }}
      >
        <H5 style={{ fontWeight: 600 }}>{props.title}</H5>
        {props.total && <H5 style={{ marginLeft: "auto" }}>{props.total}</H5>}
      </Div>
      <Line />
      {props.children}
    </Card>
  );
};

export default TitleCard;
