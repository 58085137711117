import React, { useEffect, useState } from 'react'
import DefaultScreen from '../Components/UI/DefaultScreen'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Div } from '../Components/UI/Styled/Views'
import TitleCard from '../Components/UI/TitleCard'
import { H6 } from '../Components/UI/Styled/Texts'
import { useDispatch, useSelector } from 'react-redux'
import { getHistoryClotures } from '../store/actions/clotures'
import History from '../Components/UI/History'
import LoadingSpinner from '../Components/UI/LoadingSpinner'
import TodoHistorique from '../Components/Todo/History'
import { getHistories } from '../store/actions/histories'

const Historiques = () => {
  // const { pageCount } = useSelector((state) => state.todos)

  const { histories, metadata } = useSelector((state) => state.histories)
  const test = useSelector((state) => state.histories)
  const { token } = useSelector((state) => state.auth)


  const pageCount = (histories.metadata?.[0].total / 10)



  console.log("meta", Number.isInteger(pageCount))
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getHistories({ token, pageNumber: 0 }))
  }, [])

  // console.log("page count todo", pageCount)


  return (
    <DefaultScreen title='Historique To Do'>
      <History
        title='Historique des to do'
        loadData={getHistories}
        pageCount={Number.isInteger(pageCount) ? (histories.metadata?.[0].total / 10) : ((histories.metadata?.[0].total / 10) + 1).toFixed(0)}
      >
        <Div
          style={{
            flexDirection: 'row',
            width: '100%',
            marginBottom: 5
          }}
        >
          <H6
            style={{
              fontWeight: 600,
              flex: 1,
              marginLeft: 10,
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '17px'
            }}
          >
            Date
          </H6>
        </Div>
        {histories?.data?.map((todo) => {
          console.log('todo:', todo)
          return <TodoHistorique todoHistorique={todo} state={todo} />
        })}
      </History>
    </DefaultScreen>
  )
}

export default Historiques
