import { green } from '@material-ui/core/colors'
import React from 'react'
import styled from 'styled-components'
import Colors from '../../Assets/Colors'

//* UI

const Header = styled.header`
  flex-direction: row;
  justify-content: inherit;
  display: flex;
  padding: 10px 20px;
  background-color: 'white';
  /* padding-bottom: 24px; */
  border-bottom: 1px solid #efefef;
`
const Text = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #4c4c4c;
  text-transform: uppercase;
  min-width: ${({ width }) => `${width}px`};
  max-width: ${({ width }) => `${width}px`};
  width: 100%;
  flex: ${(flex) => flex};
`
const TodoItemContainer = styled.div`
  display: flex;
  flex-wrap: ${({ wrap }) => wrap && 'wrap'};
  min-width: ${({ width }) => `${width}px`};
  max-width: ${({ width }) => `${width}px`};
  width: 100%;
  flex: ${(flex) => flex};
  padding-left: 0;
`

const Main = styled.main`
  background-color: ${Colors.white};
  border-radius: 5px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`
//* UI END

const Item = ({ data }) => {
  const percentage = (data?.doneItems / data?.totalItems) * 100
  const setColor = () => {
    if (percentage === 100) return 'green'
    if (percentage >= 50) return 'blue'
    if (percentage < 50) return 'red'
  }

  return (
    <div>
      <div
        style={{
          position: 'relative',
          marginTop: 1,
          justifyContent: 'inherit',
          alignItems: 'inherit',
          padding: '0 20px'
        }}
      >
        <div
          style={{
            display: 'flex'
          }}
        >
          <TodoItemContainer width={600} wrap style={{ alignItems: 'center' }}>
            <p>{data.task}</p>
          </TodoItemContainer>
          <TodoItemContainer width={300} wrap style={{ alignItems: 'center' }}>
            <p>{data.category}</p>
          </TodoItemContainer>
          <TodoItemContainer width={160} style={{ alignItems: 'center' }}>
            <p style={{ color: setColor() }}>
              {data.doneItems} / {data.totalItems}
            </p>
          </TodoItemContainer>
        </div>
      </div>
    </div>
  )
}

const Statistics = ({ state }) => {
  return (
    <Main>
      <Header>
        <Text width={600}>Tâche</Text>
        <Text width={300}>Sous Table</Text>
        <Text width={160}>Nombre</Text>
      </Header>
      {state?.map((el) => (
        <Item data={el} />
      ))}
    </Main>
  )
}

export default Statistics
