import {
  GET_RETRAITS_HISTORY,
  GET_RETRAITS_HISTORY_FAILURE,
  GET_RETRAITS_HISTORY_REQUEST,
  GET_STOCK_MONNAIE,
  GET_STOCK_MONNAIE_FAILURE,
  GET_STOCK_MONNAIE_REQUEST,
} from "../types/monnaie";

const initialState = {
  stock: null,
  history: null,
  pageCount: 0,
  error: false,
  errorStatus: "",
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STOCK_MONNAIE:
      return Object.assign({}, state, {
        stock: action.value,
        loading: false,
      });
    case GET_STOCK_MONNAIE_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_STOCK_MONNAIE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value,
      });
    case GET_RETRAITS_HISTORY:
      return Object.assign({}, state, {
        history: action.value.items,
        pageCount: action.value.pageCount,
        loading: false,
      });
    case GET_RETRAITS_HISTORY_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_RETRAITS_HISTORY_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value,
      });
    default:
      return state;
  }
};
