import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Div } from "../UI/Styled/Views";
import { TextInput, WhiteTextInput } from "../UI/Styled/TextInputs";
import Select from "react-select";
import Colors from "../../Assets/Colors";

const GraphOptions = (props) => {
  const {
    date,
    setDate,
    period,
    setPeriod,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    timeError,
  } = props;

  const periodOptions = [
    { value: "jour", label: "Jour" },
    { value: "semaine", label: "Semaine" },
    { value: "mois", label: "Mois" },
    { value: "annee", label: "Année" },
  ];
  const startTimeOptions = [
    { value: "null", label: "Heure de début" },
    { value: "0", label: "00:00" },
    { value: "1", label: "01:00" },
    { value: "2", label: "02:00" },
    { value: "3", label: "03:00" },
    { value: "4", label: "04:00" },
    { value: "5", label: "05:00" },
    { value: "6", label: "06:00" },
    { value: "7", label: "07:00" },
    { value: "8", label: "08:00" },
    { value: "9", label: "09:00" },
    { value: "10", label: "10:00" },
    { value: "11", label: "11:00" },
    { value: "12", label: "12:00" },
    { value: "13", label: "13:00" },
    { value: "14", label: "14:00" },
    { value: "15", label: "15:00" },
    { value: "16", label: "16:00" },
    { value: "17", label: "17:00" },
    { value: "18", label: "18:00" },
    { value: "19", label: "19:00" },
    { value: "20", label: "20:00" },
    { value: "21", label: "21:00" },
    { value: "22", label: "22:00" },
    { value: "23", label: "23:00" },
  ];

  const endTimeOptions = [
    { value: "null", label: "Heure de fin" },
    { value: "0", label: "00:00" },
    { value: "1", label: "01:00" },
    { value: "2", label: "02:00" },
    { value: "3", label: "03:00" },
    { value: "4", label: "04:00" },
    { value: "5", label: "05:00" },
    { value: "6", label: "06:00" },
    { value: "7", label: "07:00" },
    { value: "8", label: "08:00" },
    { value: "9", label: "09:00" },
    { value: "10", label: "10:00" },
    { value: "11", label: "11:00" },
    { value: "12", label: "12:00" },
    { value: "13", label: "13:00" },
    { value: "14", label: "14:00" },
    { value: "15", label: "15:00" },
    { value: "16", label: "16:00" },
    { value: "17", label: "17:00" },
    { value: "18", label: "18:00" },
    { value: "19", label: "19:00" },
    { value: "20", label: "20:00" },
    { value: "21", label: "21:00" },
    { value: "22", label: "22:00" },
    { value: "23", label: "23:00" },
  ];
  return (
    <Div style={{ flexDirection: "row", width: "100%", alignItems: "center" }}>
      <Div style={{ flex: 1, margin: 5 }}>
        <Select
          options={startTimeOptions}
          value={startTime}
          onChange={(s) => setStartTime(s)}
          isSearchable={false}
          styles={{
            control: (base, state) => ({
              ...base,
              boxShadow: "none",
              borderColor: timeError ? Colors.red : `${Colors.grey}60`,
            }),
          }}
        />
      </Div>
      <Div style={{ flex: 1, margin: 5 }}>
        <Select
          options={endTimeOptions}
          value={endTime}
          onChange={(s) => setEndTime(s)}
          isSearchable={false}
          styles={{
            control: (base, state) => ({
              ...base,
              boxShadow: "none",
              borderColor: timeError ? Colors.red : `${Colors.grey}60`,
            }),
          }}
        />
      </Div>
      <Div
        style={{
          flex: 1,
          margin: 5,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DatePicker
          selected={date}
          onChange={(d) => {
            d.setHours(23, 59, 59, 0);
            setDate(d);
          }}
          dateFormat="dd/MM/yyyy"
          customInput={
            <WhiteTextInput
              type="text"
              style={{
                height: "100%",
                width:"auto",
                borderColor: `${Colors.grey}40`,
                borderWidth: 1,
                padding: 9,
                borderRadius: 4,
                margin: 0,
                marginLeft:0,
              }}
            />
          }
        />
      </Div>
      <Div style={{ flex: 1, margin: 5 }}>
        <Select
          options={periodOptions}
          value={period}
          onChange={(s) => setPeriod(s)}
          isSearchable={false}
          styles={{
            control: (base, state) => ({
              ...base,
              boxShadow: "none",
            }),
          }}
        />
      </Div>
    </Div>
  );
};

export default GraphOptions;
