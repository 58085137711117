import React, { useState } from 'react'
import styled from 'styled-components'
import DefaultScreen from '../Components/UI/DefaultScreen'
import { useDispatch, useSelector } from 'react-redux'
import Category from '../Components/Template/Categories'
import ModalAddCategory from '../Components/UI/ModalAddCategory'
import { Button } from '../Components/UI/Styled/Button'
import { getTodosCategories, addTodosCategories } from '../store/actions/todos'

const Template = () => {
  const Btn = styled(Button)`
    max-width: 290px;
    min-width: 290px;
    width: 100%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    height: 50px;
    margin-right: 40px;
  `
  const StyledInput = styled.input`
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
  `

  const categories = useSelector((state) => state.todoList)
  const { token } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getTodosCategories({ token }))
  }, [])

  const ModalContent = (props) => {
    const [name, setName] = React.useState('')
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        dispatch(addTodosCategories({ token: token, name }))
        props.toggleOpen(false)
      }
    }
    return (
      <>
        <StyledInput
          onKeyDown={handleKeyDown}
          onChange={(e) => setName(e.target.value)}
        />
        <Button
          onClick={() => {
            dispatch(addTodosCategories({ token: token, name }))
            props.toggleOpen(false)
          }}
          style={{ marginTop: 30, width: '50%', alignSelf: 'center' }}
        >
          Ajouter
        </Button>
      </>
    )
  }

  const AddCategory = () => {
    const [modalToggle, setModalToggle] = useState(false)
    return (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ModalAddCategory
          open={modalToggle}
          toggleOpen={setModalToggle}
          title='Choissez le nom de la nouvelle catégorie'
        >
          <ModalContent toggleOpen={setModalToggle} />
        </ModalAddCategory>
        <Btn onClick={() => setModalToggle(true)}>Ajouter une catégorie</Btn>
      </div>
    )
  }

  return (
    <DefaultScreen title='Template'>
      <Category categories={categories} />
      <AddCategory />
    </DefaultScreen>
  )
}

export default Template
