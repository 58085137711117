import styled from "styled-components";
import Colors from "../../../Assets/Colors";

export const Rectangle = styled.div`
  width: 100vw;
  height: 33vh;
  background-color: ${Colors.blue};
`;

export const Triangle = styled.div`
  width: 0px;
  height: 0px;
  border-right: 100vw solid transparent;
  border-top: 33vh solid ${Colors.blue};
`;

export const Line = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  height: 0px;
  border: 1px solid #f4f4f4;
  background-color: #f4f4f4;
  width: 100%;
`;

export const Circle = styled.div`
  display: inline-block;
  border-radius: 50%;
  background-color: #bbb;
  height: 10px;
  width: 10px;
`;
