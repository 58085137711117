import React, { useEffect, useState } from "react";
import Colors from "../Assets/Colors";
import DefaultScreen from "../Components/UI/DefaultScreen";
import { SearchTextInput, TextInput } from "../Components/UI/Styled/TextInputs";
import { Card, Div } from "../Components/UI/Styled/Views";
import { ReactComponent as SearchIcon } from "../Assets/Icons/Search.svg";
import { Button } from "../Components/UI/Styled/Button";
import ScrollBars from "react-scrollbars-custom";
import { useDispatch, useSelector } from "react-redux";
import { getEmployees } from "../store/actions/employees";
import EmployeeElement from "../Components/Profils/EmployeeElement";
import { H3, H5 } from "../Components/UI/Styled/Texts";
import ProfileInfoCard from "../Components/Profils/ProfileInfoCard";
import CreateProfileCard from "../Components/Profils/CreateProfileCard";
import ClotureHistoryCard from "../Components/Profils/ClotureHistoryCard";
import {
  getEmployeeClotures,
  getEmployeeHistoryClotures,
} from "../store/actions/clotures";
import GraphOptions from "../Components/Dashboard/GraphOptions";
import ClotureGraph from "../Components/UI/ClotureGraph";
import LoadingSpinner from "../Components/UI/LoadingSpinner";

const Profils = () => {
  const [search, setSearch] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [creatingProfile, setCreatingProfile] = useState(false);
  const [date, setDate] = useState(new Date());
  const [period, setPeriod] = useState({ value: "jour", label: "Jour" });
  const [startTime, setStartTime] = useState({ value: "null", label: "-" });
  const [endTime, setEndTime] = useState({ value: "null", label: "-" });

  const { employees, loading } = useSelector((state) => state.employees);
  const {
    selectedEmployeeHistoryClotures,
    selectedEmployeeClotures,
    selectedEmployeePageCount,
  } = useSelector((state) => state.clotures);
  const clotureLoading = useSelector((state) => state.clotures.loading);
  const { token } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const selectEmployee = async (e) => {
    const d = new Date();
    await dispatch(
      getEmployeeHistoryClotures({
        token: token,
        pageNumber: 0,
        eid: e,
      })
    );
    await dispatch(
      getEmployeeClotures({
        token: token,
        eid: e,
        dateFrom: new Date(d.setDate(d.getDate() - 13)),
        dateTo: new Date(),
      })
    );
    setCreatingProfile(false);
    setSelectedEmployee(e);
  };

  const changePeriod = (p) => {
    setPeriod(p);
  };

  const changeDate = (d) => {
    setDate(d);
  };

  useEffect(() => {
    if (selectedEmployeeClotures) {
      var dateFrom;
      const d = new Date(date);
      switch (period.value) {
        case "jour":
          dateFrom = new Date(d.setDate(d.getDate() - 12));
          break;
        case "semaine":
          dateFrom = new Date(d.setDate(d.getDate() - 13 * 7));
          break;
        case "mois":
          dateFrom = new Date(d.setMonth(d.getMonth() - 13));
          break;
        case "annee":
          dateFrom = new Date(d.setFullYear(d.getFullYear() - 13));
          break;
      }
      if (startTime.value === "null" || endTime.value === "null") {
        dispatch(
          getEmployeeClotures({
            token: token,
            eid: selectedEmployee,
            dateFrom: dateFrom,
            dateTo: date,
          })
        );
      } else {
        dispatch(
          getEmployeeClotures({
            token: token,
            eid: selectedEmployee,
            dateFrom: dateFrom,
            dateTo: date,
            hourFrom: startTime.value,
            hourTo: endTime.value,
          })
        );
      }
    }
  }, [period, date, startTime, endTime]);

  useEffect(() => {
    if (!employees) {
      dispatch(getEmployees(token));
    }
  }, []);

  return (
    <DefaultScreen title='Profils' fixed={true}>
      <Div
        style={{
          flexDirection: "row",
          marginTop: 20,
          marginBottom: 20,
          //width: 230,
          flex: 1,
        }}
      >
        <Div style={{ flex: 1 }}>
          <H3 style={{ marginBottom: 10 }}>Profils</H3>
          <Div
            style={{
              backgroundColor: Colors.blue,
              flexDirection: "row",
              alignItems: "center",
              padding: 10,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
          >
            <SearchIcon width={15} />
            <SearchTextInput
              type='input'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{
                borderWidth: 0,
                backgroundColor: "transparent",
                fontSize: "16px",
                height: "auto",
                width: "auto",
                outline: "none",
                color: "white",
              }}
              placeholder='Rechercher'
              spellCheck={false}
            />
          </Div>
          <Card
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              height: "100%",
              overflowX: "hidden",
              width: "auto",
            }}
          >
            <ScrollBars style={{ height: "100%", paddingLeft: 20 }} noScrollX>
              {loading ? (
                <LoadingSpinner isLoading={loading} />
              ) : employees ? (
                employees.map((e, i) => {
                  if (
                    `${e.name} ${e.surname}`
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return (
                      <EmployeeElement
                        style={{ marginRight: 10 }}
                        key={i}
                        emp={e}
                        setSelectedEmployee={(e) => selectEmployee(e)}
                      />
                    );
                  }
                })
              ) : null}
            </ScrollBars>
            <Button
              style={{ width: "100%" }}
              onClick={() => setCreatingProfile(true)}
            >
              <H5 style={{ color: "white" }}>Nouveau profil</H5>
            </Button>
          </Card>
        </Div>
        <Div
          style={{
            marginLeft: 20,
            height: "100%",
            flex: 5,
            marginRight: 30,
          }}
        >
          <ScrollBars style={{ height: "100%", width: "inherit" }}>
            {creatingProfile ? (
              <CreateProfileCard setCreatingProfile={setCreatingProfile} />
            ) : selectedEmployee ? (
              <Div>
                <ProfileInfoCard
                  emp={
                    employees.filter((item) => item._id === selectedEmployee)[0]
                  }
                  style={{ marginBottom: 30 }}
                />
                <Card style={{ marginBottom: 30 }}>
                  {clotureLoading ? (
                    <LoadingSpinner isLoading={clotureLoading} />
                  ) : (
                    <GraphOptions
                      date={date}
                      setDate={changeDate}
                      period={period}
                      setPeriod={changePeriod}
                      startTime={startTime}
                      setStartTime={setStartTime}
                      endTime={endTime}
                      setEndTime={setEndTime}
                    />
                  )}
                  <Div style={{ width: "100%" }}>
                    <ClotureGraph
                      period={period}
                      date={date}
                      height={400}
                      clotures={selectedEmployeeClotures}
                    />
                  </Div>
                </Card>
                <ClotureHistoryCard
                  clotures={selectedEmployeeHistoryClotures}
                  pageCount={selectedEmployeePageCount}
                  eid={selectedEmployee}
                />
              </Div>
            ) : null}
          </ScrollBars>
        </Div>
      </Div>
    </DefaultScreen>
  );
};

export default Profils;
