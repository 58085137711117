import {
  GET_TASKS_SUCCESS,
  ADD_TASKS_SUCCESS,
  UPDATE_TASKS_SUCCESS,
  DELETE_TASKS_SUCCESS,
  ADD_TABLE_SUCCESS,
  UPDATE_TABLE_SUCCESS,
  DELETE_TABLE_SUCCESS,
  REQUEST,
  FAILURE,
} from "../types/tasks";

export const getTasks = ({ token, _id }) => {
  return (dispatch) => {
    dispatch({ type: REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/task?id=${_id}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: GET_TASKS_SUCCESS, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const addTasks = ({
  token,
  name,
  days,
  picture,
  comment,
  table,
  ID,
}) => {
  return (dispatch) => {
    dispatch({ type: REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/task`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        name,
        days,
        picture,
        comment,
        table,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: ADD_TASKS_SUCCESS, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const updateTasks = ({ token, _id, name, comment, picture, days }) => {
  return (dispatch) => {
    dispatch({ type: REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/task?id=${_id}`, {
      method: "PATCH",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        name,
        comment,
        days,
        picture,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: UPDATE_TASKS_SUCCESS, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const deleteTasks = ({ token, _id }) => {
  return (dispatch) => {
    dispatch({ type: REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/task?id=${_id}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Tyrpe": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: DELETE_TASKS_SUCCESS, value: _id });
        }
      })
      .catch((error) => console.log(error));
  };
};

//* TABLE
export const addTable = ({ token, name, category }) => {
  return (dispatch) => {
    dispatch({ type: REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/table`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        name,
        category,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: ADD_TABLE_SUCCESS, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const updateTable = ({ token, _id, name }) => {
  return (dispatch) => {
    dispatch({ type: REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/table?id=${_id}`, {
      method: "PATCH",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        name,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: UPDATE_TABLE_SUCCESS, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const deleteTable = ({ token, _id }) => {
  return (dispatch) => {
    dispatch({ type: REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/table?id=${_id}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Tyrpe": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: DELETE_TABLE_SUCCESS, value: _id });
        }
      })
      .catch((error) => console.log(error));
  };
};
