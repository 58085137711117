import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Colors from "../Assets/Colors";
import ResumeInfoCard from "../Components/Cloture/ResumeInfoCard";
import DashboardCard from "../Components/Dashboard/DashboardCard";
import DefaultScreen from "../Components/UI/DefaultScreen";
import { H5 } from "../Components/UI/Styled/Texts";
import { Div } from "../Components/UI/Styled/Views";
import { Line } from "../Components/UI/Styled/Shapes";
import DeleteModal from "../Components/Cloture/DeleteModal";

const Cloture = () => {
  const history = useHistory();

  const { clotureID } = useParams();
  const [cloture, setCloture] = useState(null);

  const [repartition, setRepartition] = useState([]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    fetch(`https://shopngo.herokuapp.com/api/cash-closing/?id=${clotureID}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          console.log("error");
        } else {
          getRepartition(res);

          setCloture(res);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  let usedFond = [];
  let argentARetirer = [];
  let sommeBilletARetirer = 0;
  let sommeMonnaieARetirer = 0;

  const getTotal = (res) => {
    const fondDeCaisse = res.fondDeCaisse;

    var total = 0.0;
    fondDeCaisse.forEach((item) => {
      total += parseFloat(item.amount) * parseInt(item.quantity);
    });
    return total.toFixed(2);
  };

  const getRepartition = (res) => {
    const fondDeCaisse = res.fondDeCaisse;
    var total = parseFloat(getTotal(res));
    var fond = JSON.parse(JSON.stringify(fondDeCaisse));
    var toGiveBack = new Array(fondDeCaisse.length).fill(0);

    total = total - 400;
    for (var i = fondDeCaisse.length - 1; i >= 0; i--) {
      while (parseFloat(fond[i].amount) <= total && fond[i].quantity > 0) {
        total = parseFloat((total - parseFloat(fond[i].amount)).toFixed(2));
        fond[i].quantity--;
        toGiveBack[i]++;
      }
    }
    setRepartition(toGiveBack);
  };

  const getTotalAmount = (min, max, listOfItem) => {
    return listOfItem
      .filter((f) => f.amount >= min && f.amount <= max)
      .reduce((acc, item) => acc + item.amount * item.quantity, 0);
  };

  if (cloture) {
    console.log("repartition", repartition);
    Object.assign(
      argentARetirer,
      JSON.parse(JSON.stringify(cloture.fondDeCaisse))
    );
    // argentARetirer = [...cloture.fondDeCaisse]
    console.log(cloture.fondDeCaisse);
    for (var u in argentARetirer) {
      argentARetirer[u].quantity = repartition[u];
    }

    usedFond = JSON.parse(JSON.stringify(cloture.fondDeCaisse));
    usedFond = usedFond.filter((item) => item.quantity != 0);

    argentARetirer = argentARetirer.filter((item) => item.quantity != 0);

    for (let i in argentARetirer) {
      const item = argentARetirer[i];
      if (item.amount < 5) {
        sommeMonnaieARetirer += item.amount * item.quantity;
      } else if (item.amount >= 5) {
        sommeBilletARetirer += item.amount * item.quantity;
      }
    }
  }

  console.log("USEFOND", usedFond);

  const toggleChecked = () => {
    fetch(`https://shopngo.herokuapp.com/api/cash-closing/${clotureID}`, {
      method: "PATCH",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({ valide: !cloture.valide }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.errors) {
          console.log("error");
        } else {
          console.log(res);
        }
      })
      .catch((error) => console.log(error));
  };

  const deleteCloture = () => {
    fetch(`https://shopngo.herokuapp.com/api/cash-closing/${clotureID}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((res) => {
        if (res.errors) {
          console.log("error");
        } else {
          console.log("ag");
          console.log(res);
        }
        setIsDeleteModalOpen(false);
        history.push("/history");
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <DefaultScreen
      title={
        cloture
          ? `Clôture - ${(
            "0" + new Date(cloture.heureDeCloture).getDate()
          ).slice(-2)}-${(
            "0" +
            (new Date(cloture.heureDeCloture).getMonth() + 1)
          ).slice(-2)}-${new Date(cloture.heureDeCloture).getFullYear()} - ${(
            "0" + new Date(cloture.heureDeCloture).getHours()
          ).slice(-2)}:${(
            "0" + new Date(cloture.heureDeCloture).getMinutes()
          ).slice(-2)}`
          : "Clôture"
      }
      defaultChecked={cloture ? cloture.valide : null}
      onChange={toggleChecked}
      deleteOption={true}
      isDeleteModalOpen={isDeleteModalOpen}
      setIsDeleteModalOpen={setIsDeleteModalOpen}
    >
      <DeleteModal
        open={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        deleteCloture={deleteCloture}
      />
      {cloture ? (
        <Div style={{ width: "100%" }}>
          <Div
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            <DashboardCard
              title={"Chiffre d'affaire"}
              amount={parseFloat(cloture.CA).toFixed(2)}
              style={{ flex: 1, marginRight: 30 }}
            />
            <DashboardCard
              title={"Cash ticket"}
              amount={parseFloat(cloture.cashMachine).toFixed(2)}
              style={{ flex: 1, marginRight: 30 }}
            />
            <DashboardCard
              title={"Cash réel"}
              amount={parseFloat(cloture.cash).toFixed(2)}
              style={{ flex: 1, marginRight: 30 }}
            />
            <DashboardCard
              title={"Différence"}
              amount={(
                parseFloat(cloture.cash) - parseFloat(cloture.cashMachine)
              ).toFixed(2)}
              style={{ flex: 1 }}
            />
          </Div>
          <Div
            style={{
              flexDirection: "row",
              width: "100%",
              marginTop: 25,
              justifyContent: "center",
            }}
          >
            <Div style={{ flex: 1 }}>
              <ResumeInfoCard title='Fond de caisse'>
                <Div
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {[
                    { min: 0, max: 2 },
                    { min: 5, max: 500 },
                  ].map(({ min, max }) => (
                    <Div style={{ width: "40%", flex: 1 }}>
                      {usedFond
                        .filter(
                          (item) => item.amount >= min && item.amount <= max
                        )
                        .map((item, key) => {
                          return (
                            <Div
                              key={key}
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <H5 style={{ color: Colors.grey }}>
                                {parseFloat(item.amount).toFixed(2)}€
                              </H5>
                              <Div style={{ flexDirection: "row" }}>
                                <H5
                                  style={{
                                    color: Colors.grey,
                                    marginRight: 15,
                                  }}
                                >
                                  x {item.quantity}
                                </H5>
                              </Div>
                            </Div>
                          );
                        })}
                      <Div style={{ marginBottom: 0, marginTop: "auto" }}>
                        <Line />
                        <H5>
                          Total {min ? "billets" : "monnaie"}{" "}
                          {getTotalAmount(min, max, usedFond)}€
                        </H5>
                      </Div>
                    </Div>
                  ))}
                </Div>
              </ResumeInfoCard>

              <ResumeInfoCard title='A retirer'>
                <Div
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {[
                    { min: 0, max: 2 },
                    { min: 5, max: 500 },
                  ].map(({ min, max }) => (
                    <Div style={{ width: "40%", flex: 1 }}>
                      {argentARetirer
                        .filter(
                          (item) => item.amount >= min && item.amount <= max
                        )
                        .map((item, key) => {
                          return (
                            <Div
                              key={key}
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <H5 style={{ color: Colors.grey }}>
                                {parseFloat(item.amount).toFixed(2)}€
                              </H5>
                              <Div style={{ flexDirection: "row", marginRight: 16 }}>
                                <H5
                                  style={{
                                    color: Colors.grey,
                                    marginRight: 4,
                                  }}
                                >
                                  x
                                </H5>
                                <H5 style={{ color: Colors.grey }}>
                                  {item.quantity}
                                </H5>
                              </Div>
                            </Div>
                          );
                        })}
                      <Div style={{ marginBottom: 0, marginTop: "auto" }}>
                        <Line />
                        <H5>
                          Total {min ? "billets" : "monnaie"}{" "}
                          {getTotalAmount(min, max, argentARetirer)}€
                        </H5>
                      </Div>
                    </Div>
                  ))}
                </Div>

                {sommeBilletARetirer != 0 || sommeMonnaieARetirer != 0 ? (
                  <Div style={{ width: "100%", marginTop: 30 }}>
                    <Div
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <H5 style={{ fontWeight: 600 }}>Dans G4S:</H5>
                      <H5 style={{ fontWeight: 600 }}>
                        {sommeBilletARetirer}€
                      </H5>
                    </Div>
                    <Div
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 13,
                      }}
                    >
                      <H5 style={{ fontWeight: 600 }}>Dans coffre bureau:</H5>
                      <H5 style={{ fontWeight: 600 }}>
                        {sommeMonnaieARetirer}€
                      </H5>
                    </Div>
                  </Div>
                ) : (
                  <> </>
                )}
              </ResumeInfoCard>
              <ResumeInfoCard title='Preuve photo' photo={cloture.photoG4S}>
                <Div style={{ width: "100%" }}>
                  <Div
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingBottom: 8,
                    }}
                  >
                    <H5 style={{ color: Colors.grey, fontWeight: 600 }}>
                      Temps de clôture
                    </H5>
                    <H5 style={{ color: Colors.grey, fontWeight: 600 }}>
                      {cloture.time.minutes} minutes {cloture.time.seconds}{" "}
                      secondes
                    </H5>
                  </Div>
                  <Div
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      paddingBottom: 8,
                      marginTop: 5,
                    }}
                  >
                    <H5 style={{ color: Colors.grey, fontWeight: 600 }}>
                      Numéro de caisse
                    </H5>
                    <H5 style={{ color: Colors.grey, fontWeight: 600 }}>
                      {cloture.numCaisse}
                    </H5>
                  </Div>
                </Div>
              </ResumeInfoCard>
            </Div>
            <Div style={{ flex: 1, marginLeft: 30 }}>
              <ResumeInfoCard
                title='Autres moyens de paiement'
                photo={cloture.photoMoyensDePaiement}
              >
                <Div style={{ width: "100%" }}>
                  {cloture.moyensDePaiement.length > 0 ? (
                    <Div
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: 8,
                      }}
                    >
                      <H5 style={{ color: Colors.grey, fontWeight: 600 }}>
                        Type
                      </H5>
                      <H5 style={{ color: Colors.grey, fontWeight: 600 }}>
                        Montant
                      </H5>
                    </Div>
                  ) : (
                    <H5>Aucun autre moyen de paiement</H5>
                  )}

                  {cloture.moyensDePaiement
                    .filter((item) => item.type != "")
                    .map((item, i) => {
                      return (
                        <Div
                          key={i}
                          style={{
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <H5 style={{ color: Colors.grey }}>{item.type}</H5>
                          <H5 style={{ color: Colors.grey }}>
                            {parseFloat(item.montant).toFixed(2)}€
                          </H5>
                        </Div>
                      );
                    })}
                </Div>
              </ResumeInfoCard>
              <ResumeInfoCard title='Retours' photo={cloture.photoRetours}>
                <Div style={{ width: "100%" }}>
                  {cloture.retours.length > 0 ? (
                    <Div
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: 8,
                      }}
                    >
                      <H5 style={{ color: Colors.grey, fontWeight: 600 }}>
                        Montant
                      </H5>
                      <H5 style={{ color: Colors.grey, fontWeight: 600 }}>
                        Commentaire
                      </H5>
                    </Div>
                  ) : (
                    <H5>Aucun retour</H5>
                  )}

                  {cloture.retours
                    .filter((item) => item.montant != "")
                    .map((item, i) => {
                      return (
                        <Div
                          key={i}
                          style={{
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <H5 style={{ color: Colors.grey }}>
                            {parseFloat(item.montant).toFixed(2)}€
                          </H5>
                          <H5 style={{ color: Colors.grey }}>
                            {item.commentaire}
                          </H5>
                        </Div>
                      );
                    })}
                </Div>
              </ResumeInfoCard>
              <ResumeInfoCard title='Retraits' photo={cloture.photoRetraits}>
                {cloture.retraits.length > 0 ? (
                  <></>
                ) : (
                  <H5 style={{ alignSelf: "flex-start" }}>Aucun retrait</H5>
                )}
                {cloture.retraits
                  .filter((item) => item.montant != "")
                  .map((item, i) => {
                    return (
                      <Div key={i} style={{ width: "100%" }}>
                        <H5 style={{ color: Colors.grey }}>
                          -{parseFloat(item.montant).toFixed(2)}€
                        </H5>
                      </Div>
                    );
                  })}
              </ResumeInfoCard>
              <ResumeInfoCard title='Commentaires'>
                <Div style={{ width: "100%" }}>
                  <H5 style={{ color: Colors.grey }}>{cloture.commentaire}</H5>
                </Div>
              </ResumeInfoCard>
              <ResumeInfoCard title='Employés'>
                <Div style={{ width: "100%" }}>
                  {cloture.employes.map((e, i) => {
                    return (
                      <H5
                        key={e}
                        style={{ color: Colors.grey, marginBottom: 5 }}
                      >
                        {cloture.employes[i].name} {cloture.employes[i].surname}
                      </H5>
                    );
                  })}
                </Div>
              </ResumeInfoCard>
            </Div>
          </Div>
        </Div>
      ) : null}
    </DefaultScreen>
  );
};

export default Cloture;
