import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../Assets/Colors";
import { createEmployee } from "../../store/actions/employees";
import { Button } from "../UI/Styled/Button";
import { ProfileTextInput, TextInput } from "../UI/Styled/TextInputs";
import { H5 } from "../UI/Styled/Texts";
import { Card, Div } from "../UI/Styled/Views";
import CreateProfileCardElement from "./CreateProfileCardElement";
import ProfileInfoCardElement from "./ProfileInfoCardElement";

const CreateProfileCard = (props) => {
  const { setCreatingProfile } = props;

  const [name, setName] = useState("");
  const [name_valid, setNameValid] = useState(true);
  const [surname, setSurname] = useState("");
  const [surname_valid, setSurnameValid] = useState(true);
  const [telephone, setTelephone] = useState("");
  const [telephone_valid, setTelephoneValid] = useState(true);
  const [email, setEmail] = useState("");
  const [email_valid, setEmailValid] = useState(true);
  const [dateDeNaissance, setDateDeNaissance] = useState("");
  const [dateDeNaissance_valid, setDateDeNaissanceValid] = useState(true);
  const [motDePasse, setMotDePasse] = useState("");
  const [motDePasse_valid, setMotDePasseValid] = useState(true);

  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const validate = () => {
    var valid = true;
    if (!/^[A-Z]{2,99}$/i.test(name)) {
      setNameValid(false);
      valid = false;
    }
    if (!/^[A-Z]{2,99}$/i.test(surname)) {
      setSurnameValid(false);
      valid = false;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,99}$/i.test(email)) {
      setEmailValid(false);
      valid = false;
    }
    if (!/^[0-9]{4}$/i.test(motDePasse)) {
      setMotDePasseValid(false);
      valid = false;
    }
    if (
      !/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i.test(
        dateDeNaissance
      )
    ) {
      setDateDeNaissanceValid(false);
      valid = false;
    }
    if (!/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/i.test(telephone)) {
      setTelephoneValid(false);
      valid = false;
    }
    return valid;
  };

  const submitProfileCreation = () => {
    if (validate()) {
      setCreatingProfile(false);
      dispatch(
        createEmployee({
          token: token,
          body: {
            name: name,
            surname: surname,
            pin: motDePasse,
            active: true,
            birthdate: new Date(
              parseInt(dateDeNaissance.split("/")[2]),
              parseInt(dateDeNaissance.split("/")[1]) - 1,
              parseInt(dateDeNaissance.split("/")[0])
            ),
            registerDate: new Date(),
            phone: telephone,
            email: email,
          },
        })
      );
    }
  };

  return (
    <Card>
      <Div
        style={{
          width: "100%",
          marginBottom: 8,
        }}
      >
        <Div style={{ flexDirection: "row", width: "100%" }}>
          <ProfileTextInput
            type="input"
            value={name}
            onChange={(e) => setName(e.target.value)}
            spellCheck={false}
            style={{
              borderColor: "transparent",
              fontWeight: 700,
              outline: name_valid ? "none" : "solid",
              outlineColor: name_valid ? "transparent" : Colors.red,
              outlineWidth: name_valid ? 0 : 1,
              marginRight: 10,
              paddingLeft:0,
              width:"40%"
            }}
            placeholder="Prénom"
          />
          <ProfileTextInput
            type="input"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            spellCheck={false}
            style={{
              borderColor: "transparent",
              fontWeight: 700,
              outline: surname_valid ? "none" : "solid",
              outlineColor: surname_valid ? "transparent" : Colors.red,
              outlineWidth: surname_valid ? 0 : 1,
              paddingLeft:0
            }}
            placeholder="Nom"
          />
        </Div>
      </Div>
      <CreateProfileCardElement
        title="Tel"
        value={telephone}
        setValue={setTelephone}
        placeholder="+32 435 52 53 98"
        valid={telephone_valid}
      />
      <CreateProfileCardElement
        title="E-mail"
        value={email}
        setValue={setEmail}
        placeholder="miro@digitalu.be"
        valid={email_valid}
      />
      <CreateProfileCardElement
        title="Date de naissance"
        value={dateDeNaissance}
        setValue={setDateDeNaissance}
        placeholder="20/02/1998"
        maxLength={10}
        valid={dateDeNaissance_valid}
      />
      <CreateProfileCardElement
        title="Mot de passe"
        value={motDePasse}
        setValue={setMotDePasse}
        placeholder="1234"
        maxLength={4}
        valid={motDePasse_valid}
      />
      <Div
        style={{
          width: "100%",
          alignItems: "flex-end",
        }}
      >
        <Button
          onClick={submitProfileCreation}
        >
          <H5 style={{ color: "white" }}>Ajouter le nouveau profil</H5>
        </Button>
      </Div>
    </Card>
  );
};

export default CreateProfileCard;
