import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DefaultScreen from '../Components/UI/DefaultScreen'
import FolderFileCard from '../Components/Informations/FolderFileCard'
import { Button } from '../Components/UI/Styled/Button'
import { H5 } from '../Components/UI/Styled/Texts'
import { Card, Div } from '../Components/UI/Styled/Views'
import { getFolders } from '../store/actions/info'
import Drawer from 'react-drag-drawer'
import Input from '../Components/UI/Input'
import LoadingSpinner from '../Components/UI/LoadingSpinner'

const Informations = () => {
  const { folders, loading } = useSelector((state) => state.info)
  const { token } = useSelector((state) => state.auth)

  const dispatch = useDispatch()

  const [creatingNewFolder, setCreatingNewFolder] = useState(false)
  const [newFolderTitle, setNewFolderTitle] = useState('')

  useEffect(() => {
    if (!folders) {
      dispatch(getFolders(token))
    }
  })

  const createFolder = () => {
    if (newFolderTitle.length > 0) {
      setCreatingNewFolder(false)
      fetch('https://shopngo.herokuapp.com/api/info/folder', {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }),
        body: JSON.stringify({
          name: newFolderTitle,
          date: new Date()
        })
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.errors) {
            console.log(res.errors)
          } else {
            // dispatch(getPainHistory({token:token, pageNumber:0}));
            console.log(res)
            dispatch(getFolders(token))
          }
        })
        .catch((error) => console.log(error))
    }
  }

  return (
    <DefaultScreen title='Informations'>
      <Drawer
        open={creatingNewFolder}
        onRequestClose={() => setCreatingNewFolder(false)}
      >
        <Card>
          <Input
            type='input'
            title='Titre de votre dossier :'
            value={newFolderTitle}
            onChangeText={setNewFolderTitle}
            submit={createFolder}
          />
          <Button style={{ marginTop: 10 }} onClick={createFolder}>
            <H5 style={{ color: 'white' }}>Confirmer</H5>
          </Button>
        </Card>
      </Drawer>
      <Div style={{ flexWrap: 'wrap', flexDirection: 'row', marginTop: 20 }}>
        {loading ? (
          <LoadingSpinner isLoading={loading} />
        ) : folders ? (
          folders.map((f, i) => (
            <FolderFileCard
              key={i}
              type='Folder'
              title={f.name}
              id={f._id}
              date={f.date}
            />
          ))
        ) : null}
      </Div>
      <Div style={{ alignItems: 'flex-start', width: '100%' }}>
        <Button
          style={{
            width: 'fit-content',
            marginTop: 30
          }}
          onClick={() => setCreatingNewFolder(true)}
        >
          <H5 style={{ color: 'white', marginRight: 15, marginLeft: 15 }}>
            Ajouter un dossier
          </H5>
        </Button>
      </Div>
    </DefaultScreen>
  )
}

export default Informations
