import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const ClotureGraph = (props) => {
  const { period, date, clotures, height, employee = true } = props;

  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: "emp-cahrt",
      toolbar: { show: false },
      selection: { show: false },
    },
    xaxis: {
      categories: [],
    },
    yaxis: [
      {
        seriesName: "CA",
        axisTicks: {
          show: true,
        },
      },
      {
        seriesName: "CA",
        show: false,
      },
      {
        opposite: true,
        seriesName: "Différence",
        axisTicks: {
          show: true,
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "80%",
      },
    },
  });

  const [chartSeries, setChartSeries] = useState([
    {
      name: "CA",
      data: [],
    },
    {
      name: "Cash",
      data: [],
    },
    {
      name: "Pertes",
      data: [],
    },
  ]);

  const updateGraph = () => {
    let newCategories = [];
    let newCA = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let newCash = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    let newPertes = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    if (period.value === "annee") {
      for (let i = 11; i >= 0; i--) {
        newCategories.push(date.getFullYear() - i);
      }
      for (let j = 0; j < clotures.length; j++) {
        if (new Date(clotures[j].heureDeCloture).getFullYear() < date.getFullYear() - 11) {
          break;
        }
        newCA[11 - (date.getFullYear() - new Date(clotures[j].heureDeCloture).getFullYear())] += parseFloat(
          clotures[j].CA
        );
        console.log("newCA 0:>> ", newCA);

        newCash[11 - (date.getFullYear() - new Date(clotures[j].heureDeCloture).getFullYear())] += parseFloat(
          clotures[j].cash
        );
        newPertes[11 - (date.getFullYear() - new Date(clotures[j].heureDeCloture).getFullYear())] +=
          parseFloat(clotures[j].cash - parseFloat(clotures[j].cashMachine)) / 3;
        // (employee ? clotures[j].employes.length : 1);
      }
    } else if (period.value === "mois") {
      for (let i = 11; i >= 0; i--) {
        const d = new Date(date);
        d.setDate(1);
        newCategories.push(
          new Date(d.setMonth(d.getMonth() - i)).toLocaleString("default", {
            month: "short",
          })
        );
      }
      for (let j = 0; j < clotures.length; j++) {
        if (
          new Date(clotures[j].heureDeCloture).getFullYear() * 12 +
            (new Date(clotures[j].heureDeCloture).getMonth() + 1) <
          new Date(date).getFullYear() * 12 + (new Date(date).getMonth() + 1) - 11
        ) {
          break;
        }
        newCA[
          11 -
            (new Date(date).getFullYear() * 12 +
              (new Date(date).getMonth() + 1) -
              (new Date(clotures[j].heureDeCloture).getFullYear() * 12 +
                (new Date(clotures[j].heureDeCloture).getMonth() + 1)))
        ] += parseFloat(clotures[j].CA);
        console.log("newCA 1:>> ", newCA);
        newCash[
          11 -
            (new Date(date).getFullYear() * 12 +
              (new Date(date).getMonth() + 1) -
              (new Date(clotures[j].heureDeCloture).getFullYear() * 12 +
                (new Date(clotures[j].heureDeCloture).getMonth() + 1)))
        ] += parseFloat(clotures[j].cash);
        newPertes[
          11 -
            (new Date(date).getFullYear() * 12 +
              (new Date(date).getMonth() + 1) -
              (new Date(clotures[j].heureDeCloture).getFullYear() * 12 +
                (new Date(clotures[j].heureDeCloture).getMonth() + 1)))
        ] +=
          parseFloat(clotures[j].cash - parseFloat(clotures[j].cashMachine)) /
          (employee ? clotures[j].employes.length : 1);
      }
    } else if (period.value === "semaine") {
      var d = new Date(date);
      d.setHours(0, 0, 0, 0);
      if (d.getTimezoneOffset() == -120) d.setHours(1, 0, 0, 0);

      const diff = d.getDate() - d.getDay() + (d.getDay() == 0 ? -6 : 1);
      const lastMonday = new Date(d.setDate(diff));
      for (let i = 11; i >= 0; i--) {
        const dat = new Date(lastMonday.valueOf());
        const da = new Date(dat.setDate(dat.getDate() - 7 * i));
        newCategories.push(
          da
            .getDate()
            .toString()
            .concat("/")
            .concat((da.getMonth() + 1).toString())
            .concat("-")
            .concat(
              new Date(da.setDate(da.getDate() + 6))
                .getDate()
                .toString()
                .concat("/")
                .concat((da.getMonth() + 1).toString())
            )
        );
      }
      for (let j = 0; j < clotures.length; j++) {
        const date = new Date(lastMonday.valueOf());
        if (new Date(clotures[j].heureDeCloture) < new Date(date.setDate(date.getDate() - 7 * 12))) {
          break;
        }
        var d2 = new Date(clotures[j].heureDeCloture);
        d2.setHours(0, 0, 0, 0);
        if (d2.getTimezoneOffset() == -120) d2.setHours(1, 0, 0, 0);
        const diff = d2.getDate() - d2.getDay() + (d2.getDay() == 0 ? -6 : 1);
        const clotureMonday = new Date(d2.setDate(diff));
        if (clotureMonday.getTimezoneOffset() == -120) clotureMonday.setHours(1, 0, 0, 0);
        newCA[11 - (lastMonday - clotureMonday) / (1000 * 60 * 60 * 24 * 7)] += parseFloat(clotures[j].CA);
        console.log("newCA 2:>> ", newCA);

        newCash[11 - (lastMonday - clotureMonday) / (1000 * 60 * 60 * 24 * 7)] += parseFloat(clotures[j].cash);
        newPertes[11 - (lastMonday - clotureMonday) / (1000 * 60 * 60 * 24 * 7)] +=
          parseFloat(clotures[j].cash - parseFloat(clotures[j].cashMachine)) /
          (employee ? clotures[j].employes.length : 1);
      }
    } else if (period.value === "jour") {
      for (let i = 11; i >= 0; i--) {
        const da = new Date(date);
        const da2 = new Date(date);
        newCategories.push(
          new Date(da.setDate(da.getDate() - i))
            .getDate()
            .toString()
            .concat("/")
            .concat((new Date(da2.setDate(da2.getDate() - i)).getMonth() + 1).toString())
        );
      }
      for (let j = 0; j < clotures.length; j++) {
        const d = new Date(date);
        if (new Date(clotures[j].heureDeCloture) < new Date(d.setDate(d.getDate() - 12))) break;

        const empDate = new Date(clotures[j].heureDeCloture.valueOf());
        empDate.setHours(0, 0, 0, 0);
        if (empDate.getTimezoneOffset() == -120) empDate.setHours(1, 0, 0, 0);
        const nowDate = new Date(date);
        nowDate.setHours(0, 0, 0, 0);
        if (nowDate.getTimezoneOffset() == -120) nowDate.setHours(1, 0, 0, 0);
        if (nowDate - empDate < 0) continue;

        newCA[11 - (nowDate - empDate) / (1000 * 60 * 60 * 24)] += parseFloat(clotures[j].CA);
        newCash[11 - (nowDate - empDate) / (1000 * 60 * 60 * 24)] += parseFloat(clotures[j].cash);
        newPertes[11 - (nowDate - empDate) / (1000 * 60 * 60 * 24)] +=
          (parseFloat(clotures[j].cash) - parseFloat(clotures[j].cashMachine)) /
          (employee ? clotures[j].employes.length : 1);
      }
    }

    setChartOptions({
      ...chartOptions,
      xaxis: { ...chartOptions.xaxis, categories: newCategories },
    });

    var x = 0;
    var len = newCA.length;
    while (x < len) {
      newCA[x] = newCA[x].toFixed(2);
      x++;
    }

    var x = 0;
    var len = newCash.length;
    while (x < len) {
      newCash[x] = newCash[x].toFixed(2);
      x++;
    }

    var x = 0;
    var len = newPertes.length;
    while (x < len) {
      newPertes[x] = newPertes[x].toFixed(2);
      x++;
    }

    setChartSeries([
      {
        name: "CA",
        data: newCA,
      },
      {
        name: "Cash",
        data: newCash,
      },
      {
        name: "Différence",
        data: newPertes,
      },
    ]);
    if (props.setCardCash) {
      props.setCardCash(newCash[11]);
      props.setCardCA(newCA[11]);
      props.setCardPertes(newPertes[11]);
    }
  };

  useEffect(() => {
    if (clotures) {
      updateGraph();
    }
  }, [clotures]);

  return (
    <Chart options={chartOptions} series={chartSeries} type="bar" width="100%" height={height ? height : "auto"} />
  );
};

export default ClotureGraph;
