const Colors = {
  blue: "#0E7CFF",
  background: "#F9FAFD",
  grey: "#6C757D",
  red: "#DC0000",
  green: "#2FB500",
  orange: "#FF795B",
  white: "#FFFFFF",
  darkRed: "#E00000",
  darkGrey: "#585858",
  darkGreen: "#068E00",
  lightGrey: "#A7B3B9",
  purple: "#5A74E7",
};

export default Colors;
