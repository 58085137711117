import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultScreen from "../Components/UI/DefaultScreen";
import { H5, H6 } from "../Components/UI/Styled/Texts";
import { Card, Div } from "../Components/UI/Styled/Views";
import TitleCard from "../Components/UI/TitleCard";
import History from "../Components/UI/History";
import { getMonnaieHistory, getMonnaieStock } from "../store/actions/monnaie";
import Colors from "../Assets/Colors";
import { Button } from "../Components/UI/Styled/Button";
import MonnaieEditing from "../Components/Monnaie/MonnaieEditing";
import LoadingSpinner from "../Components/UI/LoadingSpinner";
import Drawer from "react-drag-drawer";

const Monnaie = () => {
  const { stock, history, pageCount, loading } = useSelector(
    (state) => state.monnaie
  );
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [editing, setEditing] = useState(false);
  const [editedStock, setEditedStock] = useState([]);

  const [errorIndex, setErrorIndex] = useState(null);

  const [historyModal, setHistoryModal] = useState(false);
  const [historyModalElement, setHistoryModalElement] = useState(null);

  const historyRef = useRef(null);

  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMonnaieStock(token));
  }, []);

  const getTotal = (coins) => {
    var total = 0;
    var nbCoins = {
      0.01: 50,
      0.02: 50,
      0.05: 50,
      "0.10": 40,
      "0.20": 40,
      "0.50": 40,
      "1.00": 25,
      "2.00": 25,
    };
    for (let c in coins) {
      if (c != "_id") {
        total +=
          parseFloat(c.replace(/,/g, ".")) *
          parseFloat(coins[c]) *
          nbCoins[c.replace(/,/g, ".")];
      }
    }
    return total;
  };

  useEffect(() => {
    if (stock) {
      var bufferStock = [];
      Object.keys(stock.stock).forEach((item) => {
        var bufferStockElement = {};
        bufferStockElement[item] = stock.stock[item];
        bufferStock.push(bufferStockElement);
      });
      setEditedStock(bufferStock);
    }
  }, [stock]);

  const validate = () => {
    var valid = true;
    for (let i = 0; i < editedStock.length; i++) {
      if (!/^[\d]*$/i.test(editedStock[i][Object.keys(editedStock[i])[0]])) {
        valid = false;
        setErrorIndex(i);
      }
    }
    return valid;
  };

  const submitNewStock = () => {
    if (validate()) {
      var body = { coins: {}, date: new Date() };
      var changed = false;
      for (let i = 0; i < editedStock.length; i++) {
        if (
          editedStock[i][Object.keys(editedStock[i])[0]] !=
          stock.stock[Object.keys(editedStock[i])[0]]
        ) {
          changed = true;
          body.coins[Object.keys(editedStock[i])[0]] =
            editedStock[i][Object.keys(editedStock[i])[0]];
        }
      }
      if (changed) {
        fetch("https://shopngo.herokuapp.com/api/currency-stock", {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          }),
          body: JSON.stringify(body),
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.errors) {
              console.log(res.errors);
            } else {
              // dispatch(getPainHistory({token:token, pageNumber:0}));
              console.log(res);
              dispatch(getMonnaieStock(token));
              setEditing(false);
              historyRef.current.fetchHistory();
            }
          })
          .catch((error) => console.log(error));
      } else {
        setEditing(false);
      }
    }
  };

  const showInfo = (p) => {
    console.log(p);
    setHistoryModalElement(p);
    setHistoryModal(true);
  };

  return (
    <DefaultScreen title='Monnaie'>
      {historyModalElement ? (
        <Drawer
          open={historyModal}
          onRequestClose={() => setHistoryModal(false)}
        >
          <TitleCard
            title={
              historyModalElement.employe
                ? `Monnaie - détail - ${historyModalElement.employe.name} ${historyModalElement.employe.surname}`
                : "Monnaie - détail - Admin"
            }
          >
            {historyModalElement.coins ? (
              <Div style={{ width: "30vw" }}>
                {Object.keys(historyModalElement.coins).map((e, i) => (
                  <Div
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      marginBottom: 10,
                    }}
                    key={i}
                  >
                    <H5>{e}€</H5>
                    <H5>x{historyModalElement.coins[e]}</H5>
                  </Div>
                ))}
              </Div>
            ) : (
              <Div style={{ width: "30vw" }}>
                {Object.keys(historyModalElement.change)
                  .filter((x) => x != "_id")
                  .map((e, i) => (
                    <Div
                      style={{
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                      key={i}
                    >
                      <H5>{e}</H5>
                      <H5>x{historyModalElement.change[e]}</H5>
                    </Div>
                  ))}
              </Div>
            )}
          </TitleCard>
        </Drawer>
      ) : null}
      <Div style={{ flexDirection: "row" }}>
        <TitleCard
          style={{ flex: 1, marginRight: 20, height: "100%" }}
          title='Stocks'
        >
          <Div
            style={{
              width: "100%",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <Div style={{ width: "100%" }}>
              <Div
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <H6 style={{ fontWeight: 600 }}>Type</H6>
                <H6 style={{ fontWeight: 600 }}>Nombre</H6>
              </Div>
              {loading ? (
                <LoadingSpinner isLoading={loading} />
              ) : editing ? (
                editedStock.map((s, i) => (
                  <MonnaieEditing
                    key={i}
                    stock={s}
                    index={i}
                    editedStock={editedStock}
                    setEditedStock={setEditedStock}
                    error={i == errorIndex}
                  />
                ))
              ) : stock ? (
                Object.keys(stock.stock).map((s, i) => (
                  <Div
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: 10,
                    }}
                    key={i}
                  >
                    <H6>{s}</H6>
                    <H6>{stock.stock[s]}</H6>
                  </Div>
                ))
              ) : null}
            </Div>
            <Button
              style={{
                width: "100%",
                marginTop: 15,
                backgroundColor: editing ? Colors.red : Colors.blue,
              }}
              onClick={() => (editing ? submitNewStock() : setEditing(true))}
            >
              <H5 style={{ color: "white" }}>
                {editing ? "Enregistrer" : "Editer"}
              </H5>
            </Button>
          </Div>
        </TitleCard>
        <History
          style={{ flex: 1, height: "100%" }}
          title='Historique'
          loadData={getMonnaieHistory}
          pageCount={pageCount}
          ref={historyRef}
        >
          <Div>
            <Div
              style={{
                flexDirection: "row",
                width: "100%",
              }}
            >
              <H6 style={{ fontWeight: 600, flex: 1 }}>Date</H6>
              <H6 style={{ fontWeight: 600, flex: 1 }}>Nom</H6>
              <H6 style={{ fontWeight: 600, flex: 1, textAlign: "right" }}>
                Nombre
              </H6>
            </Div>
            <Div>
              {history
                ? history.map((h, i) => (
                    <Div
                      style={{
                        flexDirection: "row",
                        width: "100%",
                        marginTop: 10,
                      }}
                      key={i}
                      onClick={() => showInfo(h)}
                    >
                      <H6
                        style={{
                          fontWeight: 500,
                          flex: 1,
                          color: h.employe ? Colors.red : Colors.green,
                        }}
                      >
                        {("0" + new Date(h.date).getDate()).slice(-2)}-
                        {("0" + (new Date(h.date).getMonth() + 1)).slice(-2)}
                      </H6>
                      <H6
                        style={{
                          fontWeight: 500,
                          flex: 1,
                          color: h.employe ? Colors.red : Colors.green,
                        }}
                      >
                        {h.employe
                          ? `${h.employe.name} ${h.employe.surname}`
                          : `${user.name} ${user.surname}`}
                      </H6>
                      <H6
                        style={{
                          fontWeight: 500,
                          flex: 1,
                          color: h.employe ? Colors.red : Colors.green,
                          textAlign: "right",
                        }}
                      >
                        {h.employe
                          ? "-"
                          : getTotal(h.coins).toFixed(2) > 0
                          ? "+"
                          : ""}
                        {h.employe
                          ? getTotal(h.change).toFixed(2)
                          : getTotal(h.coins).toFixed(2)}
                        €
                      </H6>
                    </Div>
                  ))
                : null}
            </Div>
          </Div>
        </History>
      </Div>
    </DefaultScreen>
  );
};

export default Monnaie;
