import {
  CREATE_EMPLOYEE,
  CREATE_EMPLOYEE_FAILURE,
  GET_EMPLOYEES,
  GET_EMPLOYEES_FAILURE,
  GET_EMPLOYEES_REQUEST,
  PATCH_EMPLOYEE,
} from "../types/employees";

export const getEmployees = (token) => {
  return (dispatch) => {
    dispatch({ type: GET_EMPLOYEES_REQUEST });
    fetch("https://shopngo.herokuapp.com/api/employee/", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.errors) {
          dispatch({ type: GET_EMPLOYEES_FAILURE, value: res.errors.status });
        } else {
          dispatch({ type: GET_EMPLOYEES, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const patchEmployee = ({ token, id, body }) => {
  return (dispatch) => {
    fetch(`https://shopngo.herokuapp.com/api/employee/${id}`, {
      method: "PATCH",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          console.log(res.errors.status);
        } else {
          dispatch({ type: PATCH_EMPLOYEE, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const createEmployee = ({ token, body }) => {
  return (dispatch) => {
    fetch("https://shopngo.herokuapp.com/api/employee", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: CREATE_EMPLOYEE_FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: CREATE_EMPLOYEE, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};
