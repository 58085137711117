import React from "react";
import euroPhoto from "../../Assets/Icons/euro.png";
import { H6, H3 } from "../UI/Styled/Texts";
import { Card, Div } from "../UI/Styled/Views";
import { Image } from "../UI/Styled/Images";
import Colors from "../../Assets/Colors";

const DashboardCard = (props) => {
  const { title, amount, lastYearAmount, date } = props;

  const isPerteCard = title == "Différence" ? true : false;

  const perte = parseFloat(amount).toFixed(2);

  const lastYear = new Date(date);
  lastYear.setFullYear(lastYear.getFullYear() - 1);
  console.log(`lastYearAmount`, lastYearAmount);
  console.log(`amount`, amount);
  const percentage =
    parseFloat(amount) > parseFloat(lastYearAmount)
      ? `+${(
          (parseFloat(amount) / parseFloat(lastYearAmount) - 1) *
          100
        ).toFixed(2)}%`
      : `-${(
          (1 - parseFloat(amount) / parseFloat(lastYearAmount)) *
          100
        ).toFixed(2)}%`;
  return (
    <Card style={{ ...props.style, marginBottom: 10 }}>
      <Div
        style={{
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Div>
          <H6>{title}</H6>
          {isPerteCard ? (
            <H3 style={{ color: perte < 0 ? Colors.red : Colors.green }}>
              {perte}€
            </H3>
          ) : (
            <H3>{perte}€</H3>
          )}
        </Div>
        <Image src={euroPhoto} />
      </Div>
      <Div style={{ width: "100%", marginTop: 15 }}>
        <H6>
          {lastYearAmount && amount != 0
            ? `${percentage} par rapport à ${lastYear.getFullYear()}`
            : "-"}
        </H6>
      </Div>
    </Card>
  );
};

export default DashboardCard;
