import React from 'react'
import Drawer from 'react-drag-drawer'
import Checkbox from '@material-ui/core/Checkbox'
import { Div } from '../UI/Styled/Views'
import deleteIcon from '../../Assets/Icons/deleteIcon.svg'
import Colors from '../../Assets/Colors'
import { Button } from '../UI/Styled/Button'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'

const CheckBox = withStyles({
  colorSecondary: {
    color: '#3d8af7 !important',
    '&&:hover': {
      backgroundColor: 'rgba(61, 138, 247, 0.05)'
    }
  }
})(Checkbox)

const Text = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #6c757d;
  text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
`

const Btn = styled(Button)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 12px;
  min-width: 60px;
  width: 100%;
  max-width: 60px;
  background: ${({ checked }) => (checked ? Colors.blue : '#E6F3FF')};
  color: ${({ checked }) => (checked ? '#fff' : Colors.blue)};
`

const StyledTextArea = styled.textarea`
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
  resize: none;
`

const CardIcon = styled.img`
  position: absolute;
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  right: ${({ right }) => right ?? '10px'};
  cursor: pointer;
`

const ModalAddTable = ({
  open,
  toggleOpen,
  onButtonClick,
  setInputValue,
  inputValue,
  photoValue,
  commentValue,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
  setMonday,
  setTuesday,
  setWednesday,
  setThursday,
  setFriday,
  setSaturday,
  setSunday,
  setPhotoValue,
  setCommentValue
}) => {
  return (
    <Drawer open={open} onRequestClose={() => toggleOpen(false)}>
      <Div
        style={{
          position: 'relative',
          backgroundColor: Colors.background,
          padding: 20,
          borderRadius: 20,
          paddingTop: 30,
          width: '540.27px',
          height: ' 500px'
        }}
      >
        <Text uppercase>Description</Text>
        <StyledTextArea
          autoFocus
          rows='10'
          cols='33'
          onChange={(e) => setInputValue(e.target.value)}
        >
          {inputValue}
        </StyledTextArea>
        <Text style={{ marginTop: 30 }} uppercase>
          Jours
        </Text>
        <div style={{ display: 'flex' }}>
          <Btn checked={monday} onClick={() => setMonday((pre) => !pre)}>
            Lu
          </Btn>

          <Btn checked={tuesday} onClick={() => setTuesday((pre) => !pre)}>
            Mar
          </Btn>

          <Btn checked={wednesday} onClick={() => setWednesday((pre) => !pre)}>
            Mer
          </Btn>

          <Btn checked={thursday} onClick={() => setThursday((pre) => !pre)}>
            Jeu
          </Btn>

          <Btn checked={friday} onClick={() => setFriday((pre) => !pre)}>
            Ven
          </Btn>

          <Btn checked={saturday} onClick={() => setSaturday((pre) => !pre)}>
            Sam
          </Btn>

          <Btn checked={sunday} onClick={() => setSunday((pre) => !pre)}>
            Dim
          </Btn>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div style={{ display: 'flex', marginTop: 30 }}>
            <CheckBox
              onChange={() => setPhotoValue((pre) => !pre)}
              checked={photoValue}
            />
            <p style={{ marginLeft: 10 }}>Photo Obligatoire</p>
          </div>
          <div style={{ display: 'flex' }}>
            <CheckBox
              onChange={() => setCommentValue((pre) => !pre)}
              checked={commentValue}
            />
            <p style={{ marginLeft: 10 }}>Commentaire Obligatoire</p>
          </div>
        </div>
        <Button
          onClick={() => {
            onButtonClick(false)
            setInputValue('')
          }}
          style={{ marginTop: 30, width: '50%', alignSelf: 'center' }}
        >
          Ajouter une tâche
        </Button>
        <CardIcon
          onClick={(e) => {
            toggleOpen(false)
            setInputValue('')
          }}
          src={deleteIcon}
          top='15px'
          right='15px'
          alt=''
        />
      </Div>
    </Drawer>
  )
}
export default ModalAddTable
