import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Card } from '../UI/Styled/Views'

export const CategoryCard = styled(Card)`
  position: relative;
  flex: 1;
  min-height: 172px;
  max-height: 172px;
  height: 100%;
  justify-content: space-around;
  flex-direction: row;
  max-width: 300px;
  min-width: 300px;
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
  /* border: 1px solid #eaeaea; */
`
export const CardIcon = styled.img`
  position: absolute;
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  right: ${({ right }) => right ?? '10px'};
  cursor: pointer;
`
export const Text = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #6c757d;
  text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
`
export const Container = styled.section`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`

export const StyledLink = styled(Link)`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex: 1;
`
