import {
  GET_RETRAITS_HISTORY,
  GET_RETRAITS_HISTORY_REQUEST,
  GET_STOCK_MONNAIE,
  GET_STOCK_MONNAIE_FAILURE,
  GET_STOCK_MONNAIE_REQUEST,
} from "../types/monnaie";
import { GET_REPAS_HISTORY_FAILURE } from "../types/repas";

export const getMonnaieStock = (token) => {
  return (dispatch) => {
    dispatch({ type: GET_STOCK_MONNAIE_REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/currency-stock`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: GET_STOCK_MONNAIE_FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: GET_STOCK_MONNAIE, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const getMonnaieHistory = ({ token, pageNumber }) => {
  return (dispatch) => {
    dispatch({ type: GET_RETRAITS_HISTORY_REQUEST });
    fetch(
      `https://shopngo.herokuapp.com/api/currency-stock/history?pageNumber=${pageNumber}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: GET_REPAS_HISTORY_FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: GET_RETRAITS_HISTORY, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};
