import React from 'react'
import Drawer from 'react-drag-drawer'
import { Div } from '../UI/Styled/Views'
import deleteIcon from '../../Assets/Icons/deleteIcon.svg'
import Colors from '../../Assets/Colors'
import styled from 'styled-components'

const ModalAddCategory = ({
  open,
  toggleOpen,
  title,
  children
}) => {
  const Text = styled.p`
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #6c757d;
    text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
  `

  const CardIcon = styled.img`
    position: absolute;
    top: ${({ top }) => top};
    bottom: ${({ bottom }) => bottom};
    right: ${({ right }) => right ?? '10px'};
    cursor: pointer;
  `
  return (
    <Drawer open={open} onRequestClose={() => toggleOpen(false)}>
      <Div
        style={{
          position: 'relative',
          backgroundColor: Colors.background,
          padding: 20,
          borderRadius: 20,
          paddingTop: 30,
          width: '540.27px',
          height: ' 195.14px'
        }}
      >
        <Text uppercase>{title}</Text>
        {children}

        <CardIcon
          onClick={(e) => {
            toggleOpen(false)
          }}
          src={deleteIcon}
          top='15px'
          right='15px'
          alt=''
        />
      </Div>
    </Drawer>
  )
}
export default ModalAddCategory
