import React from "react";
import Colors from "../../Assets/Colors";
import { PainTextInput } from "../UI/Styled/TextInputs";
import { H6 } from "../UI/Styled/Texts";
import { Div } from "../UI/Styled/Views";

const MonnaieEditing = (props) => {
  const { stock, index, setEditedStock, editedStock, error } = props;

  const updateMonnaieQuantity = (e) => {
    let newStock = [...editedStock];
    newStock[index][Object.keys(stock)[0]] = e.target.value;
    setEditedStock(newStock);
  };

  return (
    <Div
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        marginTop: 10,
      }}
    >
      <H6>{Object.keys(stock)[0]}</H6>
      <PainTextInput
        style={{
          width: "25%",
          borderColor: error ? Colors.red : Colors.lightGrey,
        }}
        value={editedStock[index][Object.keys(stock)[0]]}
        onChange={(e) => updateMonnaieQuantity(e)}
      />
    </Div>
  );
};

export default MonnaieEditing;
