import React, { useState } from 'react'
import Drawer from 'react-drag-drawer'
import { Div } from '../UI/Styled/Views'
import deleteIcon from '../../Assets/Icons/deleteIcon.svg'
import Colors from '../../Assets/Colors'
import { Button } from '../UI/Styled/Button'
import styled from 'styled-components'

const Text = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #6c757d;
  text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
`
const StyledInput = styled.input`
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
`

const CardIcon = styled.img`
  position: absolute;
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  right: ${({ right }) => right ?? '10px'};
  cursor: pointer;
`

const ModalEdit = ({
  open,
  toggleOpen,
  title,
  buttonText,
  onButtonClick,
  inputValue,
  setInputValue
}) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onButtonClick(false)
      setInputValue('')
    }
    if (event.key === 'Escape') {
      toggleOpen(false)
      setInputValue('')
    }
  }

  return (
    <Drawer open={open} onRequestClose={() => toggleOpen(false)}>
      <Div
        style={{
          position: 'relative',
          backgroundColor: Colors.background,
          padding: 20,
          borderRadius: 20,
          paddingTop: 30,
          width: '540.27px',
          height: ' 195.14px'
        }}
      >
        <Text uppercase>{title}</Text>
        <StyledInput
          autoFocus
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button
          onClick={() => {
            onButtonClick(false)
            setInputValue('')
          }}
          style={{ marginTop: 30, width: '50%', alignSelf: 'center' }}
        >
          {buttonText}
        </Button>
        <CardIcon
          onClick={(e) => {
            toggleOpen(false)
            setInputValue('')
          }}
          src={deleteIcon}
          top='15px'
          right='15px'
          alt=''
        />
      </Div>
    </Drawer>
  )
}
export default ModalEdit
