// services/reportGenerator.js

import jsPDF from 'jspdf'
import 'jspdf-autotable'
// Date Fns is used to format the dates we receive
// from our API call
//TODO: Table in bold,
// define a generatePDF function that accepts a products argument
const generatePDFOfResume = (resume, tableDate, checked) => {
  // initialize jsPDF
  const doc = new jsPDF()

  // define the columns we want and their titles
  const tableColumn = ['TÂCHE', 'HEURE', 'FAIT PAR', 'COMMENTAIRE', '']
  // define an empty array of rows
  const tableRows = []
  console.log('checked:', checked)

  // for each product pass all its data into an array
  resume.forEach((item) => {
    const tableData = [`${item.table}   ${item.doneItems} / ${item.totalItems}`]
    tableRows.push(tableData)

    item.tasks.map((el) => {
      const idx = el?.date?.indexOf('T')
      const hours = el?.date?.slice(idx, -1)?.substr(1)?.slice(0, 5)
      const taskData = []
      taskData.push(`${el.name}`)
      taskData.push(hours)
      taskData.push(el.employee)
      taskData.push(el.comment)
      tableRows.push(taskData)
      taskData.push(`${el.checked ? 'X' : ''}`)
    })
  })

  // startY is basically margin-top
  doc.autoTable(tableColumn, tableRows, { startY: 20 })
  const date = Date().split(' ')

  // we use a date string to generate our filename.
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4]
  // product title. and margin-top + margin-left
  // doc.rect(40, 20, 10, 10, 'F')
  doc.text(`${tableDate}`, 14, 15)
  // we define the name of our PDF file.
  doc.save(`aperçu_${dateStr}.pdf`)
}

export default generatePDFOfResume
