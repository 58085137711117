import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FolderFileCard from "../Components/Informations/FolderFileCard";
import DefaultScreen from "../Components/UI/DefaultScreen";
import { Button } from "../Components/UI/Styled/Button";
import { H3, H5 } from "../Components/UI/Styled/Texts";
import { Card, Div } from "../Components/UI/Styled/Views";
import Drawer from "react-drag-drawer";
import Input from "../Components/UI/Input";
import LoadingSpinner from "../Components/UI/LoadingSpinner";

const Folder = () => {
  const { folderID } = useParams();

  const { token } = useSelector((state) => state.auth);

  const [documents, setDocuments] = useState(null);
  const [name, setName] = useState("");

  const [creatingNewDocument, setCreatingNewDocument] = useState(false);
  const [newDocumentTitle, setNewDocumentTitle] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = () => {
    setLoading(true);
    fetch(
      `https://shopngo.herokuapp.com/api/info/folder/list-folder?id=${folderID}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.errors) {
          console.log(res.errors);
        } else {
          console.log(res);
          setDocuments(res.infos);
          setName(res.name);
        }
      })
      .catch((error) => console.log(error));
    setLoading(false);
  };

  const createDocument = () => {
    setCreatingNewDocument(false);
    fetch("https://shopngo.herokuapp.com/api/info/info", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        content: "",
        name: newDocumentTitle,
        folder: folderID,
        date: new Date(),
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.errors) {
          console.log(res.errors);
        } else {
          getDocuments();
          console.log(res);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <DefaultScreen title={name}>
      <Drawer
        open={creatingNewDocument}
        onRequestClose={() => setCreatingNewDocument(false)}
      >
        <Card>
          <Input
            type="input"
            title="Titre de votre document :"
            value={newDocumentTitle}
            onChangeText={setNewDocumentTitle}
            submit={createDocument}
          />
          <Button style={{marginTop:10}} onClick={createDocument}>
            <H5 style={{ color: "white" }}>Confirmer</H5>
          </Button>
        </Card>
      </Drawer>
      <Div style={{ flexWrap: "wrap", flexDirection: "row", marginTop: 20 }}>
        {loading ? (
          <LoadingSpinner isLoading={loading} />
        ) : documents ? (
          documents.map((d, i) => (
            <FolderFileCard
              key={i}
              type="Document"
              title={d.name}
              id={d._id}
              date={d.date}
            />
          ))
        ) : null}
      </Div>
      <Div style={{ alignItems: "flex-start", width: "100%" }}>
        <Button
          style={{
            width: "fit-content",
            marginTop: 30,
          }}
          onClick={() => setCreatingNewDocument(true)}
        >
          <H5 style={{ color: "white" }}>Ajouter un document</H5>
        </Button>
      </Div>
    </DefaultScreen>
  );
};

export default Folder;
