import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import DefaultScreen from '../Components/UI/DefaultScreen'
import Statistics from '../Components/Statistics/Statistics'
import { Button } from '../Components/UI/Styled/Button'
import Input from "../Components/UI/Input";
import { H1, H2, H5 } from "../Components/UI/Styled/Texts";

import ModalAddTable from '../Components/UI/ModalAddTable'
import DateFnsUtils from '@date-io/date-fns'
import 'date-fns'
import { getStatistics } from '../store/actions/statistics'

import styled from "styled-components";




import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import frLocale from 'date-fns/locale/fr'

function MaterialUIPickers({ value, onChange }) {
  // The first commit of Material-UI
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
      <KeyboardDatePicker
        margin='normal'
        id='date-picker-dialog'
        // format='MM/dd/yyyy'
        value={value}
        onChange={(date) => onChange(date)}
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
      />
    </MuiPickersUtilsProvider>
  )
}



export const NewInput = styled.input`
  margin: ${({ margin }) => margin || "0px"};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  margin-right: ${({ marginRight }) => marginRight};
  margin-left: ${({ marginLeft }) => marginLeft};
  cursor: text;
  width: ${({ width }) => width || "100%"};
  max-width: ${({ maxWidth }) => maxWidth || "100%"};
  resize: none;
  outline: none;
  word-break: break-all;
  overflow: auto;
  outline: none;
  font-family: "Circular", sans-serif;
  font-style: normal;
  cursor: text;
  letter-spacing: 1px;
  font-weight: normal;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: ${({ error }) =>
    error ? "2px solid var(--digitalu-red)" : "2px solid transparent"};
  background: var(--grey-50);
  font-size: 16px;
  color: var(--grey-600);
  transition: 325ms ease;
  padding-left: ${({ paddingLeft }) => paddingLeft || "12px"};
  padding-right: ${({ paddingRight }) => paddingRight || "12px"};
  &::placeholder {
    color: var(--grey-400);
  }
  &:hover,
  &:focus {
    border: ${({ error }) =>
    error ? "2px solid var(--digitalu-red)" : "2px solid var(--blue)"};
    background: var(--grey-25);
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StatisticsScreen = () => {
  const { token } = useSelector((state) => state.auth)
  const { stats } = useSelector((state) => state.stats)



  const DateContainer = styled.div`
    opacity: 0;
    & input {
      display: none;
    }
    & button {
      padding: 10px 45px;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    new Date(Date.now() - 6 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10)
  )
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    new Date().toISOString().slice(0, 10)
  )

  const [statistics, setStatistics] = useState()

  useEffect(() => {
    setStatistics(stats.slice(0).sort(
      (a, b) =>
        (a.doneItems / a.totalItems) * 100 - (b.doneItems / b.totalItems) * 100
    ))
  }, [stats])
  console.log("Statistics", statistics)

  const sortedStats = stats.slice(0)
  sortedStats.sort(
    (a, b) =>
      (a.doneItems / a.totalItems) * 100 - (b.doneItems / b.totalItems) * 100
  )


  const findTask = (task) => {
    setStatistics(statistics.filter((el) => el.task.includes(task)))
    if (task === "") setStatistics(stats.slice(0).sort(
      (a, b) =>
        (a.doneItems / a.totalItems) * 100 - (b.doneItems / b.totalItems) * 100
    ))
  }

  const findSubTable = (subTask) => {
    setStatistics(statistics.filter((el) => el.category.includes(subTask)))
    if (subTask === "") setStatistics(stats.slice(0).sort(
      (a, b) =>
        (a.doneItems / a.totalItems) * 100 - (b.doneItems / b.totalItems) * 100
    ))
  }


  const dispatch = useDispatch()



  useEffect(() => {
    dispatch(
      getStatistics({
        token,
        dateFrom: selectedStartDate,
        dateTo: selectedEndDate
      })
    )
  }, [selectedStartDate, selectedEndDate])



  return (
    <DefaultScreen title='Statistiques'>
      <div
        style={{
          display: 'flex',
          flex: 1,
          marginBottom: 20
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            flex: 1,
            justifyContent: 'flex-end',
          }}
        >

          <div style={{
            display: 'flex',
            flexDirection: "column",
            margin: "0 10px"
          }}>

            <NewInput placeholder="Tâche" onChange={(word) => findTask(word.target.value)} />
          </div>
          <div style={{
            display: 'flex',
            flexDirection: "column",
            margin: "0 10px",
          }}>

            <NewInput placeholder="Sous-table" onChange={(word) => findSubTable(word.target.value)} />
          </div>
          <div
            style={{
              width: '250px',
              display: 'flex',
            }}
          >
            <Button style={{ position: 'relative' }}>
              Date début
              <DateContainer>
                <MaterialUIPickers
                  value={selectedStartDate}
                  onChange={(e) =>
                    setSelectedStartDate(e.toISOString().slice(0, 10))
                  }
                />
              </DateContainer>
            </Button>
            {/* SHOW DATE BEHIND BELOW BUTTON  */}
            <Button style={{ position: 'relative', margin: "0 10px" }}>
              Date Fin
              <DateContainer>
                <MaterialUIPickers
                  value={selectedEndDate}
                  onChange={(e) =>
                    setSelectedEndDate(e.toISOString().slice(0, 10))
                  }
                />
              </DateContainer>
            </Button>
            {/* SHOW DATE BEHIND BELOW BUTTON  */}
          </div>
        </div>

      </div>
      <Statistics state={statistics} />
    </DefaultScreen >
  )
}

export default StatisticsScreen
