import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import DefaultScreen from '../Components/UI/DefaultScreen'
import Todo from '../Components/Todos/Todo'
import { Button } from '../Components/UI/Styled/Button'
import ModalAddTable from '../Components/UI/ModalAddTable'
import ModalEdit from '../Components/UI/ModalEdit'
import { getTasks, addTable } from '../store/actions/tasks'

const Header = styled.header`
  flex-direction: row;
  justify-content: inherit;
  display: flex;
  padding: 0 20px;
`
const Text = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #4c4c4c;
  text-transform: uppercase;
  min-width: ${({ width }) => `${width}px`};
  max-width: ${({ width }) => `${width}px`};
  width: 100%;
  flex: ${({ flex }) => flex};
  ${({ css }) => css};
`

const Todos = () => {
  const [title, setTitle] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [toggleModalAddTable, setToggleModalAddTable] = React.useState(false)
  const [toggleModalEdit, setToggleModalEdit] = React.useState(false)
  const { pathname } = useLocation()
  const { token } = useSelector((state) => state.auth)
  const { tables } = useSelector((state) => state.tables)

  const taskID = pathname.replace('/responsable/', '')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getTasks({ token, _id: taskID }))
  }, [])

  const { template, categories } = useSelector((state) => state.todoTemplate)
  const pathID = pathname.replace(/\D/g, '')

  React.useEffect(() => {
    categories.forEach(({ _id, name }) => {
      if (_id == pathID) setTitle(name)
    })
  }, [])

  return (
    <DefaultScreen
      title={
        <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <p style={{ flex: 1 }}>{title ?? 'todos'}</p>
          <div>
            <Button
              onClick={() => {
                setToggleModalEdit(true)
              }}
              style={{ padding: '10px 20px' }}
            >
              Ajouter une sous table
            </Button>
          </div>
        </div>
      }
    >
      <ModalEdit
        open={toggleModalEdit}
        toggleOpen={setToggleModalEdit}
        buttonText='Enregistrer'
        title='Ajouter une sous table'
        onButtonClick={() => {
          setToggleModalEdit()
          dispatch(addTable({ token, name: description, category: taskID }))
        }}
        inputValue={description}
        setInputValue={setDescription}
      />
      <Header>
        <Text flex={9}>Description</Text>
        <Text flex={8}>Jours</Text>
        <Text flex={4}>Photo</Text>
        <Text
          css={`
            text-align: right;
          `}
          flex={1}
        >
          Commentaire
        </Text>
      </Header>
      {tables?.map((el, index) => {
        return (
          <Todo
            key={index}
            state={template}
            tasks={el}
            setToggleModalAddTable={setToggleModalAddTable}
          />
        )
      })}
    </DefaultScreen>
  )
}

export default Todos
