import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import ReduxThunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import authReducer from '../store/reducers/auth'
import employeesReducer from '../store/reducers/employees'
import painReducer from '../store/reducers/pain'
import infoReducer from '../store/reducers/info'
import cloturesReducer from '../store/reducers/clotures'
import repasReducer from '../store/reducers/repas'
import monnaieReducer from '../store/reducers/monnaie'
import todoReducer from '../store/reducers/todo'
import todosReducer from '../store/reducers/todos'
import todoTemplateReducer from '../store/reducers/todoTemplate'
import taskReducer from '../store/reducers/categories'
import historiesReducer from '../store/reducers/histories'
import historyReducer from '../store/reducers/history'
import tasksReducer from '../store/reducers/tasks'
import tableReducer from '../store/reducers/table'
import statsReducer from '../store/reducers/statistics'
import { getData } from './Storage'

const configureStore = (initialState = {}) => {
  const enhancers = [composeWithDevTools(applyMiddleware(ReduxThunk))]

  const token = getData('token', { json: true })
  const user = getData('user', { json: true })
  //const refreshToken = getData("refreshToken", { json: true });

  const authentication = {
    isAuthenticated: token !== null,
    token: token ? token : null,
    user: user ? user : null
  }

  const rootReducer = combineReducers({
    auth: authReducer,
    employees: employeesReducer,
    pain: painReducer,
    info: infoReducer,
    clotures: cloturesReducer,
    repas: repasReducer,
    monnaie: monnaieReducer,
    todos: todoReducer,
    todoList: todosReducer,
    todoTemplate: todoTemplateReducer,
    categories: taskReducer,
    histories: historiesReducer,
    history: historyReducer,
    tables: tasksReducer,
    table: tableReducer,
    stats: statsReducer
  })

  const store = createStore(
    rootReducer,
    {
      ...initialState,
      auth: { ...authentication }
    },
    compose(...enhancers)
  )
  return store
}

const store = configureStore()

export default store
