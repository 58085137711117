import React from 'react'
import { useSelector } from 'react-redux'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'

import Dashboard from './Views/Dashboard'
import Folder from './Views/Folder'
import Historique from './Views/Historique'
import Informations from './Views/Informations'
import Login from './Views/Login'
import Monnaie from './Views/Monnaie'
import Pain from './Views/Pain'
import Profils from './Views/Profils'
import Repas from './Views/Repas'
import Document from './Views/Document'
import Cloture from './Views/Cloture'
import PasswordReset from './Views/PasswordReset'
import Verification from './Views/Verification'
import Historiques from './Views/Histories'
import HistoryElement from './Views/History'
import Template from './Views/Template'
import Todos from './Views/Todos'
import Statistics from './Views/Statistics'

/**
 * Some routes are private (aka only accesible to users who are logged id)
 */
const PrivateRoute = ({ ...props }) => {
  const { isAuthenticated } = props
  return isAuthenticated || process.env.NODE_ENV === 'development' ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: '/'
      }}
    />
  )
}

/**
 * Some routes are Visitor Routes (aka accessible to anyone)
 */
const VisitorRoute = ({ ...props }) => {
  const { isAuthenticated } = props
  return !isAuthenticated ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: '/dashboard'
      }}
    />
  )
}

function App() {
  const { isAuthenticated } = useSelector((state) => state.auth)
  return (
    <React.Fragment style={{ flex: 1 }}>
      <Router>
        <Switch>
          <VisitorRoute
            exact
            path='/'
            component={Login}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path='/dashboard'
            component={Dashboard}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path='/profiles'
            component={Profils}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path='/history'
            component={Historique}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path='/todo-history'
            component={Historiques}
            isAuthenticated={isAuthenticated}
            exact
          />
          <PrivateRoute
            path='/todo-history/:historyID'
            component={HistoryElement}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path='/template'
            component={Template}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path='/meals'
            component={Repas}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path='/bread'
            component={Pain}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path='/money'
            component={Monnaie}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path='/infos'
            component={Informations}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path='/responsable/:responsableID'
            component={Todos}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path='/statistics'
            component={Statistics}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path='/cloture/:clotureID'
            component={Cloture}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path='/folder/:folderID'
            component={Folder}
            isAuthenticated={isAuthenticated}
          />
          <PrivateRoute
            path='/document/:documentID'
            component={Document}
            isAuthenticated={isAuthenticated}
          />
          <Route path='/password-reset/:token' component={PasswordReset} />
          <Route path='/verify/:token' component={Verification} />
        </Switch>
      </Router>
    </React.Fragment>
  )
}

export default App
