import styled from "styled-components";
import Colors from "../../../Assets/Colors";

export const Scroll = styled.div`
  overflow-y: scroll;
`;

export const Screen = styled.div`
  min-height: 100vh;
  background-color: ${Colors.background};
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Card = styled.div`
  background-color: ${Colors.white};
  border-radius: 5px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
`;
