import React, { useState } from 'react'
import Drawer from 'react-drag-drawer'
import { Div } from '../UI/Styled/Views'
import deleteIcon from '../../Assets/Icons/deleteIcon.svg'
import Colors from '../../Assets/Colors'
import { Button } from '../UI/Styled/Button'
import styled from 'styled-components'

const Text = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #6c757d;
  text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
`

const ModalDelete = ({ open, title, onCancel, onDelete }) => {
  return (
    <Drawer open={open} onRequestClose={() => onCancel(false)}>
      <Div
        style={{
          position: 'relative',
          backgroundColor: Colors.background,
          padding: 20,
          borderRadius: 20,
          paddingTop: 30,
          width: '474px',
          height: ' 150px'
        }}
      >
        <Text uppercase>{title}</Text>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            onClick={() => onCancel(false)}
            style={{ marginTop: 30, alignSelf: 'center', flex: 1 }}
          >
            Annuler
          </Button>
          <div style={{ width: 15 }} />
          <Button
            onClick={() => onDelete(false)}
            style={{
              marginTop: 30,
              alignSelf: 'center',
              flex: 1,
              background: 'red'
            }}
          >
            Supprimer
          </Button>
        </div>
      </Div>
    </Drawer>
  )
}
export default ModalDelete
