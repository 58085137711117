import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import DefaultScreen from '../Components/UI/DefaultScreen'
import ToDoHistoriqueElement from '../Components/History/ToDoHistoriqueElement'
import { Button } from '../Components/UI/Styled/Button'
import ModalAddTable from '../Components/UI/ModalAddTable'
import { getHistory, updateHistory } from '../store/actions/history'
import { Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const CheckBox = withStyles({
  colorSecondary: {
    color: '#3d8af7 !important',
    '&&:hover': {
      backgroundColor: 'rgba(61, 138, 247, 0.05)'
    }
  }
})(Checkbox)

const History = () => {
  const [toggleModalAddTable, setToggleModalAddTable] = React.useState(false)
  const { history } = useSelector((state) => state.history)
  const { token } = useSelector((state) => state.auth)
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const pathTest = pathname.replace('/todo-history/', '')

  console.log('history:', history)

  React.useEffect(() => {
    dispatch(getHistory({ token, id: pathTest }))
  }, [])

  const [historyChecked, setHistoryChecked] = React.useState()
  console.log('yo')
  const idx = history.createdAt?.indexOf('T')
  const todoDate = history.createdAt?.slice(0, idx)
  console.log('todoDate:', todoDate)
  React.useEffect(() => {
    dispatch(
      updateHistory({
        token,
        checked: historyChecked,
        _id: pathTest
      })
    )
  }, [historyChecked])

  return (
    <DefaultScreen
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            fontSize: 32
          }}
        >
          {history?.checked !== undefined && (
            <CheckBox
              checked={history?.checked}
              onChange={() => {
                setHistoryChecked(!history?.checked)
              }}
            />
          )}

          <p style={{ flex: 1 }}>{todoDate}</p>
        </div>
      }
    >
      <ModalAddTable
        open={toggleModalAddTable}
        toggleOpen={setToggleModalAddTable}
        buttonText='Enregistrer'
        title='Modifier le nom'
        onButtonClick={setToggleModalAddTable}
      />
      <ToDoHistoriqueElement
        checked={history?.checked}
        date={todoDate}
        item={history}
      />
    </DefaultScreen>
  )
}

export default History
