import React from "react";
import Colors from "../../Assets/Colors";
import { Circle } from "../UI/Styled/Shapes";
import { H5 } from "../UI/Styled/Texts";
import { Div } from "../UI/Styled/Views";

const EmployeeElement = (props) => {
  const { setSelectedEmployee } = props;
  const { name, surname, active, _id } = props.emp;
  return (
    <Div
      style={{
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        margin: 6,
      }}
    >
      <Circle
        style={{
          backgroundColor: active ? Colors.green : Colors.orange,
          marginRight: 20,
        }}
      />
      <H5
        style={{ cursor: "pointer" }}
        onClick={() => setSelectedEmployee(_id)}
      >
        {name} {surname}
      </H5>
    </Div>
  );
};

export default EmployeeElement;
