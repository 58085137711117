import React, { useEffect, useRef, useState } from "react";
import DefaultScreen from "../Components/UI/DefaultScreen";
import JoditEditor from "jodit-react";
import { useParams } from "react-router-dom";
import { H5 } from "../Components/UI/Styled/Texts";
import { Button } from "../Components/UI/Styled/Button";
import { Div } from "../Components/UI/Styled/Views";
import { useDispatch, useSelector } from "react-redux";
import { resetSentData, setDocumentContent } from "../store/actions/info";
import LoadingSpinner from "../Components/UI/LoadingSpinner";

const Document = () => {
  const { documentID } = useParams();
  const editor = useRef(null);
  const [content, setContent] = useState();
  const [modified, setModified] = useState(false);
  const [name, setName] = useState("");

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { token } = useSelector((state) => state.auth);

  const submitContent = async () => {
    await dispatch(
      setDocumentContent({
        token: token,
        content: content,
        date: new Date(),
        docId: documentID,
      })
    );
  };

  useEffect(() => {
    return dispatch(resetSentData());
  }, []);

  useEffect(() => {
    setLoading(true);
    fetch(`https://shopngo.herokuapp.com/api/info/info/${documentID}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          console.log(res.errors);
        } else {
          console.log(res);
          setContent(res.content);
          setName(res.name);
        }
      })
      .catch((error) => console.log(error));
    setLoading(false);
  }, []);

  return (
    <DefaultScreen title={name}>
      {loading ? (
        <LoadingSpinner isLoading={loading} />
      ) : (
        <>
          <Div>
            <JoditEditor
              ref={editor}
              value={content}
              tabIndex={1}
              onChange={(newContent) => {
                setContent(newContent);
              }}
            />
          </Div>
          <Div style={{ alignItems: "flex-end", width: "100%" }}>
            <Button
              style={{ width: "fit-content", marginTop: 20 }}
              onClick={submitContent}
            >
              <H5 style={{ color: "white" }}>Sauvegarder les modifications</H5>
            </Button>
          </Div>
        </>
      )}
    </DefaultScreen>
  );
};

export default Document;
