import React from "react";
import Colors from "../../Assets/Colors";
import PlusMinus from "../UI/PlusMinus";
import { H5 } from "../UI/Styled/Texts";
import { Div } from "../UI/Styled/Views";

const AddPainElement = (props) => {
  const { title, quantity, pains, index } = props;

  const removeQuantity = () => {
    if (parseInt(quantity) > 0) {
      let newPains = [...pains];
      newPains[index].quantity--;
      props.setPains(newPains);
    }
  };

  const addQuantity = () => {
    let newPains = [...pains];
    newPains[index].quantity++;
    props.setPains(newPains);
  };

  return (
    <Div
      style={{
        flexDirection: "row",
        margin: 5,
        marginBottom: 15,
        marginLeft: 45,
        alignItems: "center",
      }}
    >
      <Div
        style={{
          backgroundColor: Colors.white,
          width: "75%",
          borderRadius: 2,
          padding: 5,
        }}
      >
        <H5 style={{ marginLeft: 10, color: "black" }}>{title}</H5>
      </Div>
      <PlusMinus
        style={{ width: "20%" }}
        quantity={quantity}
        removeQuantity={removeQuantity}
        addQuantity={addQuantity}
      />
    </Div>
  );
};

export default AddPainElement;
