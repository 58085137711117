import React, { useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../Assets/Colors";
import { Button } from "./Styled/Button";
import { H5, H3, H4 } from "./Styled/Texts";
import { Div } from "./Styled/Views";
import TitleCard from "./TitleCard";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/solid";
const History = React.forwardRef((props, ref) => {
  const { title, loadData, pageCount, total, totalTitle } = props;

  const [page, setPage] = useState(0);

  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.auth);
  const previousPage = () => {
    if (page > 0) {
      setPage((p) => p - 1);
    }
  };
  const previousTenPage = () => {
    if (page > 9) {
      setPage((p) => p - 10);
    }
  };

  const nextTenPage = () => {
    if (page < pageCount - 10) {
      setPage((p) => p + 10);
    }
  };

  const nextPage = () => {
    if (page < pageCount - 1) {
      setPage((p) => p + 1);
    }
  };

  console.log("in h", pageCount)

  useImperativeHandle(ref, () => ({
    fetchHistory() {
      if (props.eid) {
        dispatch(loadData({ token: token, eid: props.eid, pageNumber: page }));
      } else {
        dispatch(loadData({ token: token, pageNumber: page }));
      }
    },
  }));

  useEffect(() => {
    if (props.eid) {
      dispatch(loadData({ token: token, eid: props.eid, pageNumber: page }));
    } else {
      dispatch(loadData({ token: token, pageNumber: page }));
    }
  }, [page]);

  return (
    <TitleCard
      title={title}
      style={{
        ...props.style,
        justifyContent: "space-between",
        border: "1px solid #eaeaea",
      }}
      total={total}
    >
      <Div
        style={{
          width: "100%",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {props.children}
        <Div
          style={{
            flexDirection: "row",
            width: "100%",
            // justifyContent: "space-between",
            alignItems: "center",
            marginTop: 15,
          }}
        >
          <div style={{ height: 32 }}>
            <ChevronDoubleLeftIcon
              style={{
                color: Colors.blue,
                cursor: "pointer",
                height: 28,
                marginTop: 2,
              }}
              onClick={previousTenPage}
            />
          </div>
          <div style={{ height: 32 }}>
            <ChevronLeftIcon
              style={{
                color: Colors.blue,
                cursor: "pointer",
                height: 32,
              }}
              onClick={previousPage}
            />
          </div>
          <H5 style={{ marginLeft: "auto", marginRight: "auto" }}>
            {page + 1}/{pageCount == "0" ? "1" : pageCount}
          </H5>
          <div style={{ height: 32, width: 32 }}>
            <ChevronRightIcon
              style={{
                color: Colors.blue,
                cursor: "pointer",
                height: 32,
              }}
              onClick={nextPage}
            />
          </div>
          <div style={{ height: 32 }}>
            <ChevronDoubleRightIcon
              style={{
                color: Colors.blue,
                cursor: "pointer",
                height: 28,
                marginTop: 2,
              }}
              onClick={nextTenPage}
            />
          </div>
        </Div>
      </Div>
    </TitleCard>
  );
});

export default History;
