import React from "react";
import Colors from "../../Assets/Colors";
import { HeaderText } from "../UI/Styled/Texts";
import { Div } from "../UI/Styled/Views";
import { Image } from "../UI/Styled/Images";
import Menu from "../../Assets/Icons/HambMenu.svg";

import { useMediaQuery } from "@material-ui/core";

const Header = (props) => {
  const matches = useMediaQuery("(min-width:800px)");
  return (
    <>
      <Div
        style={{
          backgroundColor: Colors.blue,
          width: "100%",
          ...props.style,
          height: 45,
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        {!matches && (
          <Div
            style={{
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              height: 45,
              marginTop: 5,
              cursor: "pointer",
            }}
            onClick={() => props.setOpen((o) => !o)}
          >
            <Image src={Menu} height={20} />
          </Div>
        )}
        <Div style={{ flex: 1 }}></Div>
        <Div
          style={{
            backgroundColor: `${Colors.white}30`,
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: 40,
            paddingRight: 40,
            height: "100%",
            marginRight: 30,
            cursor: "pointer",
          }}
          onClick={props.logout}
        >
          <HeaderText>Log out</HeaderText>
        </Div>
      </Div>
    </>
  );
};

export default Header;
