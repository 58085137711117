import styled from "styled-components";
import Colors from "../../../Assets/Colors";

export const TextInput = styled.input`
  border: 1px solid #858585;
  width: 350px;
  height: 45px;
  margin: 2px;
  font-size: 22px;
  padding-left: 10px;
`;

export const WhiteTextInput = styled.input`
  width: 250px;
  height: 45px;
  margin-left: 15px;
  background-color: white;
  font-size: 16px;
  padding-left: 3px;
`;

export const ProfileTextInput = styled.input`
  border: transparent;
  height: 16px;
  font-size: 14px;
  color: ${Colors.grey};
  padding-left: 10px;
  outline: none;
  &::-webkit-input-placeholder {
    color: #b9b9b9;
    font-weight: 500;
    font-style: italic;
  }
`;

export const SearchTextInput = styled.input`
  border: transparent;
  width: 100%;
  height: 16px;
  font-size: 14px;
  color: white;
  padding-left: 10px;
  outline: none;
  &::-webkit-input-placeholder {
    color: white;
    font-weight: 500;
  }
`;

export const PainTextInput = styled.input`
  border: 1px solid ${Colors.lightGrey};
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  font-family: Montserrat;
  color: ${Colors.grey};
  padding: 4px;
  outline: none;
  border-radius: 4px;
  background-color: #f9fafd;
`;
