import React from "react";
import Colors from "../../Assets/Colors";
import { ProfileTextInput } from "../UI/Styled/TextInputs";
import { H6 } from "../UI/Styled/Texts";
import { Div } from "../UI/Styled/Views";

const CreateProfileCardElement = (props) => {
  const { title, value, setValue, placeholder, valid } = props;
  return (
    <Div
      style={{
        width: "100%",
        margin: 4,
      }}
    >
      <Div
        style={{
          flexDirection: "row",
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        <H6 style={{ width: "40%", fontWeight: 600 }}>{title} :</H6>
        <ProfileTextInput
          type="input"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          spellCheck={false}
          style={{
            borderColor: valid ? "transparent" : Colors.red,
            borderWidth: 1,
            outline: valid ? "none" : "solid",
            outlineColor: valid ? "transparent" : Colors.red,
            outlineWidth: valid ? 0 : 1,
            fontWeight: 700,
          }}
          placeholder={placeholder}
          maxLength={props.maxLength ? props.maxLength : 40}
        />
      </Div>
    </Div>
  );
};

export default CreateProfileCardElement;
