import {
  GET_FOLDERS,
  GET_FOLDERS_FAILURE,
  GET_FOLDERS_REQUEST,
  RESET_SENT_DATA,
  SEND_CONTENT_REQUEST,
  SEND_CONTENT_SUCCESS,
} from "../types/info";

const initialState = {
  folders: null,
  error: false,
  errorStatus: "",
  loading: false,
  dataSent: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FOLDERS:
      return Object.assign({}, state, {
        folders: action.value,
        loading: false,
      });
    case GET_FOLDERS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_FOLDERS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value,
      });
    case SEND_CONTENT_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case SEND_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        dataSent: true,
      });
    case RESET_SENT_DATA:
      return Object.assign({}, state, {
        dataSent: false,
      });
    default:
      return state;
  }
};
