import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { logout } from '../../store/actions/auth'
import DashboardSideBar from '../Dashboard/DashboardSideBar'
import Header from './Header'
import { H3, H4 } from './Styled/Texts'
import { Div, Screen } from './Styled/Views'
import ScrollBars from 'react-scrollbars-custom'
import Checkbox from '@material-ui/core/Checkbox'
import Colors from '../../Assets/Colors'

const DefaultScreen = (props) => {
  const dispatch = useDispatch()
  const { title, setIsDeleteModalOpen, deleteOption } = props
  const [open, setOpen] = useState(false)
  return (
    <Screen style={{ minWidth: 1000 }}>
      <Header logout={() => dispatch(logout())} setOpen={setOpen} />
      <Div
        style={{
          flexDirection: 'row',
          width: '100%',
          flex: 1
        }}
      >
        <DashboardSideBar active={title} open={open} setOpen={setOpen} />

        {!props.fixed ? (
          <ScrollBars
            scrollbarWidth={10}
            noScrollX
            style={{
              height: 'inherit',
              marginTop: 20,
              minWidth: 700
            }}
          >
            <Div style={{ flexDirection: 'row', width: '100%' }}>
              <Div
                style={{
                  width: '100%'
                }}
              >
                {props.fixed ? (
                  <H3 style={{ marginBottom: 10, position: 'fixed', flex: 1 }}>
                    {title}
                  </H3>
                ) : (
                  <Div
                    style={{
                      flexDirection: 'row',
                      marginBottom: 10
                    }}
                  >
                    <Div
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        flex: 1
                      }}
                    >
                      {props.defaultChecked != null ? (
                        <Checkbox
                          defaultChecked={props.defaultChecked}
                          onChange={props.onChange}
                        />
                      ) : null}
                      <H3 style={{ flex: 1 }}>{title}</H3>
                    </Div>
                    {deleteOption ? (
                      <Div
                        onClick={() => setIsDeleteModalOpen(true)}
                        style={{ justifyContent: 'center', cursor: 'pointer' }}
                      >
                        <H4 style={{ color: Colors.red }}>
                          Supprimer la clôture
                        </H4>
                      </Div>
                    ) : (
                      <></>
                    )}
                  </Div>
                )}
                {props.children}
              </Div>
              <Div style={{ minWidth: 30 }} />
            </Div>
            <Div style={{ height: 20 }} />
          </ScrollBars>
        ) : (
          <Div style={{ height: 'inherit', width: '100%' }}>
            {props.children}
          </Div>
        )}
      </Div>
    </Screen>
  )
}

export default DefaultScreen
