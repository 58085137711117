import React from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import ModalEdit from '../UI/ModalEdit'
import { Button } from '../UI/Styled/Button'
import ModalDelete from '../UI/ModalDelete'
import crash from '../../Assets/Icons/crash.svg'
import pencil from '../../Assets/Icons/pencil.svg'
import plus from '../../Assets/Icons/plus.svg'
import {
  deleteTasks,
  updateTasks,
  updateTable
} from '../../store/actions/tasks.js'
import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import Colors from '../../Assets/Colors'

import { deleteTable } from '../../store/actions/tasks'

const Btn = styled(Button)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 12px;
  min-width: 60px;
  width: 100%;
  max-width: 60px;
  background: ${({ checked }) => (checked ? Colors.blue : '#E6F3FF')};
  color: ${({ checked }) => (checked ? '#fff' : Colors.blue)};
`

const TodoItemContainer = styled.div`
  display: flex;
  flex-wrap: ${({ wrap }) => wrap && 'wrap'};
  min-width: ${({ width }) => `${width}px`};
  max-width: ${({ width }) => `${width}px`};
  width: 100%;
  flex: ${({ flex }) => flex};
  padding-left: 0;
`
const Img = styled.img`
  cursor: pointer;
`

export const TextEllipsisContainer = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: ${({ lines }) => lines || 1};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CheckBox = withStyles({
  colorSecondary: {
    color: '#3d8af7 !important',
    '&&:hover': {
      backgroundColor: 'rgba(61, 138, 247, 0.05)'
    }
  }
})(Checkbox)

const SousItem = ({ el, setWeekend, weekend }) => {
  const [toggleModalEdit, setToggleModalEdit] = React.useState(false)
  const [toggleModalDeleteTask, setToggleModalDeleteTask] = React.useState(
    false
  )
  const { token } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [name, setName] = React.useState('')
  return (
    <>
      <ModalDelete
        open={toggleModalDeleteTask}
        onCancel={setToggleModalDeleteTask}
        onDelete={() => {
          setToggleModalDeleteTask()
          dispatch(deleteTasks({ token, _id: el?._id }))
        }}
        title='Voulez-vous vraiment supprimer la tâche?'
      />
      <ModalEdit
        open={toggleModalEdit}
        toggleOpen={setToggleModalEdit}
        buttonText='Enregistrer'
        title='Modifier le nom'
        onButtonClick={() => {
          setToggleModalEdit()
          dispatch(
            updateTasks({
              token,
              _id: el?._id,
              name,
              comment: el?.comment
            })
          )
        }}
        inputValue={name}
        setInputValue={setName}
      />
      <div
        style={{
          paddingBottom: 24,
          borderBottom: '1px solid #EFEFEF',
          paddingTop: 20,
          display: 'flex'
        }}
      >
        <TodoItemContainer
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
          flex={9}
        >
          <TextEllipsisContainer lines={3}>{el?.name}</TextEllipsisContainer>
          {/* ahaha */}
        </TodoItemContainer>
        <TodoItemContainer flex={8} wrap>
          <Btn
            onClick={() => {
              const copyOfWeek = el?.days
              copyOfWeek.splice(0, 1, !el?.days[0])
              dispatch(
                updateTasks({
                  token,
                  _id: el?._id,
                  days: copyOfWeek
                })
              )
            }}
            checked={el?.days?.[0]}
          >
            Lu
          </Btn>
          <Btn
            onClick={() => {
              const copyOfWeek = el?.days
              copyOfWeek.splice(1, 1, !el?.days[1])
              dispatch(
                updateTasks({
                  token,
                  _id: el?._id,
                  days: copyOfWeek
                })
              )
            }}
            checked={el?.days?.[1]}
          >
            Mar
          </Btn>
          <Btn
            onClick={() => {
              const copyOfWeek = el?.days
              copyOfWeek.splice(2, 1, !el?.days[2])
              dispatch(
                updateTasks({
                  token,
                  _id: el?._id,
                  days: copyOfWeek
                })
              )
            }}
            checked={el?.days?.[2]}
          >
            Mer
          </Btn>
          <Btn
            onClick={() => {
              const copyOfWeek = el?.days
              copyOfWeek.splice(3, 1, !el?.days[3])
              dispatch(
                updateTasks({
                  token,
                  _id: el?._id,
                  days: copyOfWeek
                })
              )
            }}
            checked={el?.days?.[3]}
          >
            Jeu
          </Btn>
          <Btn
            onClick={() => {
              const copyOfWeek = el?.days
              copyOfWeek.splice(4, 1, !el?.days[4])
              dispatch(
                updateTasks({
                  token,
                  _id: el?._id,
                  days: copyOfWeek
                })
              )
            }}
            checked={el?.days?.[4]}
          >
            Ven
          </Btn>
          <Btn
            onClick={() => {
              const copyOfWeek = el?.days
              copyOfWeek.splice(5, 1, !el?.days[5])
              dispatch(
                updateTasks({
                  token,
                  _id: el?._id,
                  days: copyOfWeek
                })
              )
            }}
            checked={el?.days?.[5]}
          >
            Sam
          </Btn>
          <Btn
            onClick={() => {
              const copyOfWeek = el?.days
              copyOfWeek.splice(6, 1, !el?.days[6])
              dispatch(
                updateTasks({
                  token,
                  _id: el?._id,
                  days: copyOfWeek
                })
              )
            }}
            checked={el?.days?.[6]}
          >
            Dim
          </Btn>
        </TodoItemContainer>
        <TodoItemContainer flex={4} style={{ alignItems: 'center' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginLeft: 12
            }}
          >
            <CheckBox
              onChange={() =>
                dispatch(
                  updateTasks({
                    token,
                    _id: el?._id,
                    picture: !el.picture
                  })
                )
              }
              checked={el?.picture}
            />
          </div>
        </TodoItemContainer>
        <TodoItemContainer
          flex={1}
          style={{
            alignItems: 'center'
            // transform: 'translateX(40px)'
          }}
        >
          <div>
            <CheckBox
              checked={el?.comment}
              onChange={() =>
                dispatch(
                  updateTasks({
                    token,
                    _id: el?._id,
                    comment: !el.comment
                  })
                )
              }
            />
          </div>
          <div style={{ margin: '0 20px' }}>
            <Img src={pencil} onClick={setToggleModalEdit} />
          </div>
          <div>
            <Img src={crash} onClick={setToggleModalDeleteTask} />
          </div>
        </TodoItemContainer>
      </div>
    </>
  )
}

const Item = ({ tasks, setToggleModalAddTable, weekend, setWeekend }) => {
  const [toggleModalDeleteTable, setToggleModalDeleteTable] = React.useState(
    false
  )
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.auth)
  const [toggleModalEdit, setToggleModalEdit] = React.useState(false)
  const [name, setName] = React.useState('')

  return (
    <>
      <ModalDelete
        open={toggleModalDeleteTable}
        onCancel={setToggleModalDeleteTable}
        onDelete={() => {
          setToggleModalDeleteTable()
          dispatch(deleteTable({ token, _id: tasks?.table?._id }))
        }}
        title='Voulez-vous vraiment supprimer la table?'
      />
      <ModalEdit
        open={toggleModalEdit}
        toggleOpen={setToggleModalEdit}
        buttonText='Enregistrer'
        title='Modifier le nom'
        inputValue={name}
        setInputValue={setName}
        onButtonClick={() => {
          setToggleModalEdit()
          dispatch(
            updateTable({
              token,
              _id: tasks?.table?._id,
              name
            })
          )
        }}
      />
      <div>
        <div
          style={{
            position: 'relative',
            marginTop: 1,
            justifyContent: 'inherit',
            alignItems: 'inherit',
            padding: '20px 20px 80px 20px'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <h2
              style={{
                borderLeft: '3px solid #3C95FF',
                paddingLeft: 10,
                fontFamily: 'Montserrat',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '20px',
                lineHeight: '24px'
              }}
            >
              {tasks?.table?.name}
            </h2>
            <div style={{ width: 20 }} />
            <Img src={pencil} onClick={setToggleModalEdit} />
            <div style={{ width: 20 }} />

            <Img
              onClick={setToggleModalDeleteTable}
              style={{ marginBottom: 2 }}
              src={crash}
            />
          </div>
          {tasks?.tasks?.map((el, index) => {
            return (
              <SousItem
                weekend={weekend}
                setWeekend={setWeekend}
                key={index}
                el={el}
                weekend={weekend}
              />
            )
          })}
        </div>

        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            transform: 'translateY(-40px)'
          }}
        >
          <Img onClick={setToggleModalAddTable} src={plus} />
        </div>
      </div>
    </>
  )
}

export default Item
