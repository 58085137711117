export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS'
export const ADD_TASKS_SUCCESS = 'ADD_TASKS_SUCCESS'
export const UPDATE_TASKS_SUCCESS = 'UPDATE_TASKS_SUCCESS'
export const DELETE_TASKS_SUCCESS = 'DELETE_TASKS_SUCCESS'

export const ADD_TABLE_SUCCESS = 'ADD_TABLE_SUCCESS'
export const UPDATE_TABLE_SUCCESS = 'UPDATE_TABLE_SUCCESS'
export const DELETE_TABLE_SUCCESS = 'DELETE_TABLE_SUCCESS'

export const REQUEST = 'REQUEST'
export const FAILURE = 'FAILURE'
