import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Colors from "../Assets/Colors";
import DefaultScreen from "../Components/UI/DefaultScreen";
import { Button } from "../Components/UI/Styled/Button";
import { PainTextInput } from "../Components/UI/Styled/TextInputs";
import { H5, H6 } from "../Components/UI/Styled/Texts";
import { Div } from "../Components/UI/Styled/Views";
import TitleCard from "../Components/UI/TitleCard";
import { createNewPain, getPainHistory, getPainStock } from "../store/actions/pain";
import PainModal from "../Components/Pain/PainModal";
import History from "../Components/UI/History";
import PainEditing from "../Components/Pain/PainEditing";
import LoadingSpinner from "../Components/UI/LoadingSpinner";
import Drawer from "react-drag-drawer";

const Pain = () => {
  const { pains, painHistory, pageCount, loading } = useSelector((state) => state.pain);

  const { token, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  console.log(pains);
  console.log(painHistory);

  const [editing, setEditing] = useState(false);
  const [adding, setAdding] = useState(false);
  const [newPainName, setNewPainName] = useState("");
  const [newPainPrice, setNewPainPrice] = useState("");
  const [painQuantities, setPainQuantities] = useState([]);
  const [editedPains, setEditedPains] = useState([]);

  const [errorIndex, setErrorIndex] = useState(null);

  const [historyModal, setHistoryModal] = useState(false);
  const [historyModalPain, setHistoryModalPain] = useState(null);

  const historyRef = useRef(null);

  console.log(painHistory);

  const validateSubmission = () => {
    var valid = true;
    console.log(editedPains);
    for (let i = 0; i < editedPains.length; i++) {
      if (!/[A-Z]{2,99}$/i.test(editedPains[i].type)) {
        valid = false;
        setErrorIndex(i);
      }
      if (!/^(\d*\.)?\d+$/i.test(editedPains[i].price)) {
        valid = false;
        setErrorIndex(i);
      }
    }
    return valid;
  };

  const submitNewPains = async () => {
    setErrorIndex(null);
    if (validateSubmission()) {
      if (newPainName != "" && newPainPrice != "") {
        await dispatch(createNewPain(token, newPainName, newPainPrice));
        setNewPainName("");
        setNewPainPrice("");
      }
      for (let i = 0; i < editedPains.length; i++) {
        if (editedPains[i].type != pains[i].type || editedPains[i].price != pains[i].price) {
          await patchPain(editedPains[i]);
        }
      }
      setEditing(false);
      await dispatch(getPainStock(token));
    }
  };

  const patchPain = async (p) => {
    fetch(`https://shopngo.herokuapp.com/api/bread-stock/${p._id}`, {
      method: "PATCH",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        type: p.type,
        price: p.price,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.errors) {
          console.log(res.errors);
        } else {
          // dispatch(getPainHistory({token:token, pageNumber:0}));
          console.log(res);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (pains) {
      var bufferPains = [];
      pains.forEach((item) => {
        var bufferPainElement = {};
        bufferPainElement.type = item.type;
        bufferPainElement._id = item._id;
        bufferPainElement.price = item.price;
        bufferPainElement.quantity = 0;
        bufferPains.push(bufferPainElement);
      });
      setPainQuantities(bufferPains);
      setEditedPains(bufferPains);
    }
  }, [pains]);

  useEffect(() => {
    if (!pains) {
      dispatch(getPainStock(token));
    }
  }, []);

  const updateStock = () => {
    setAdding(false);
    var breads = {};
    for (let p in painQuantities) {
      breads[painQuantities[p]._id] = painQuantities[p].quantity;
    }
    var body = {
      breads: breads,
      date: new Date(),
    };
    fetch("https://shopngo.herokuapp.com/api/bread-stock/group-update", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.errors) {
          console.log(res.errors);
        } else {
          // dispatch(getPainHistory({token:token, pageNumber:0}));
          console.log(res);
          historyRef.current.fetchHistory();
        }
      })
      .catch((error) => console.log(error));
  };

  const getTotal = (pains) => {
    var total = 0;
    for (let p in pains) {
      total += parseInt(pains[p].quantity);
    }
    return total;
  };

  const showInfo = (p) => {
    setHistoryModalPain(p);
    setHistoryModal(true);
  };

  return (
    <DefaultScreen title="Pain">
      <PainModal
        open={adding}
        onRequestClose={() => setAdding(false)}
        pains={painQuantities}
        setPains={setPainQuantities}
        submit={updateStock}
      />
      {historyModalPain ? (
        <Drawer open={historyModal} onRequestClose={() => setHistoryModal(false)}>
          <Div
            style={{
              backgroundColor: Colors.background,
              maxHeight: "70vh",
              borderRadius: 8,
              overflow: "scroll",
            }}
          >
            <TitleCard
              title={
                historyModalPain.employe
                  ? `Pain - détail - ${historyModalPain.employe.name} ${historyModalPain.employe.surname}`
                  : "Pain - détail - Admin"
              }
            >
              {historyModalPain.breads ? (
                <Div style={{ width: "30vw" }}>
                  {Object.keys(historyModalPain.breads).map((p, i) => (
                    <Div
                      style={{
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-between",
                        marginBottom: 10,
                      }}
                      key={i}
                    >
                      <H5>{historyModalPain.breads[p].type.type}</H5>
                      <H5>x{historyModalPain.breads[p].quantity}</H5>
                    </Div>
                  ))}
                </Div>
              ) : (
                <Div></Div>
              )}
            </TitleCard>
          </Div>
        </Drawer>
      ) : null}
      <Div style={{ flexDirection: "row", alignItems: "start" }}>
        <TitleCard style={{ flex: 1, marginRight: 20 }} title="Types de pain">
          <Div
            style={{
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <Div style={{ width: "100%" }}>
              <Div
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "100%",
                }}
              >
                <H6 style={{ fontWeight: 600 }}>Produit</H6>
                <H6 style={{ fontWeight: 600 }}>Prix d'achat</H6>
              </Div>
              {loading ? (
                <LoadingSpinner isLoading={loading} />
              ) : editing ? (
                <Div style={{ width: "100%", height: "100%" }}>
                  {editedPains.map((p, i) => {
                    return (
                      <PainEditing
                        key={i}
                        pain={p}
                        index={i}
                        editedPains={editedPains}
                        setEditedPains={setEditedPains}
                        error={i == errorIndex}
                      />
                    );
                  })}
                  <Div
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: 10,
                    }}
                  >
                    <PainTextInput
                      style={{ width: "70%" }}
                      value={newPainName}
                      onChange={(e) => setNewPainName(e.target.value)}
                    />
                    <PainTextInput
                      style={{ width: "20%" }}
                      value={newPainPrice}
                      onChange={(e) => setNewPainPrice(e.target.value.replace(/,/g, "."))}
                    />
                  </Div>
                </Div>
              ) : pains ? (
                pains.map((p, i) => (
                  <Div
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      marginTop: 10,
                    }}
                    key={i}
                  >
                    <H6>{p.type}</H6>
                    <H6>{parseFloat(p.price).toFixed(2)}€</H6>
                  </Div>
                ))
              ) : null}
            </Div>
            <Div style={{ flexDirection: "row", width: "100%", marginTop: 15 }}>
              <Button
                style={{
                  flex: 1,
                  padding: 9,
                  marginRight: 5,
                  cursor: "pointer",
                  backgroundColor: editing ? Colors.red : Colors.blue,
                }}
                onClick={() => (editing ? setEditing(false) : setEditing(true))}
              >
                <H5 style={{ color: "white" }}>{editing ? "Annuler" : "Editer pain"}</H5>
              </Button>
              <Button
                style={{
                  flex: 1,
                  padding: 9,
                  marginLeft: 5,
                  cursor: "pointer",
                }}
                onClick={() => (editing ? submitNewPains() : setAdding(true))}
              >
                <H5 style={{ color: "white" }}>{editing ? "Enregistrer" : "Ajouter pain existant"}</H5>
              </Button>
            </Div>
          </Div>
        </TitleCard>
        <History
          style={{ flex: 1 }}
          title="Historique des pains"
          loadData={getPainHistory}
          pageCount={pageCount}
          ref={historyRef}
        >
          <Div
            style={{
              flexDirection: "row",
              width: "100%",
            }}
          >
            <H6 style={{ fontWeight: 600, flex: 1 }}>Date</H6>
            <H6 style={{ fontWeight: 600, flex: 1 }}>Nom</H6>
            <H6 style={{ fontWeight: 600, flex: 1, textAlign: "right" }}>Nombre total</H6>
          </Div>
          {painHistory
            ? painHistory.map((p, i) => (
                <Div
                  key={i}
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    marginTop: 10,
                  }}
                  onClick={() => showInfo(p)}
                >
                  <H6
                    style={{
                      fontWeight: 500,
                      flex: 1,
                      color: p.employe ? Colors.red : Colors.green,
                    }}
                  >
                    {("0" + new Date(p.date).getDate()).slice(-2)}-{("0" + (new Date(p.date).getMonth() + 1)).slice(-2)}
                    {"   "}
                    {("0" + new Date(p.date).getHours()).slice(-2)}:{("0" + new Date(p.date).getMinutes()).slice(-2)}
                  </H6>
                  <H6
                    style={{
                      fontWeight: 500,
                      flex: 1,
                      color: p.employe ? Colors.red : Colors.green,
                    }}
                  >
                    {p.employe ? `${p.employe.name} ${p.employe.surname}` : `${user.name} ${user.surname}`}
                  </H6>
                  <H6
                    style={{
                      fontWeight: 500,
                      flex: 1,
                      color: p.employe ? Colors.red : Colors.green,
                      textAlign: "right",
                    }}
                  >
                    {p.employe ? "-" : "+"}
                    {getTotal(p.breads)}
                  </H6>
                </Div>
              ))
            : null}
        </History>
      </Div>
    </DefaultScreen>
  );
};

export default Pain;
