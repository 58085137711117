import React, { useState } from "react";
import Colors from "../../Assets/Colors";
import { H3, H5 } from "./Styled/Texts";
import { Div } from "./Styled/Views";

const PlusMinus = (props) => {
  const { removeQuantity, addQuantity, quantity } = props;

  const [timer, setTimer] = useState(null);

  const minusPressed = () => {
    removeQuantity();
    var t = setTimeout(minusPressed, 200);
    setTimer(t);
  };

  const plusPressed = () => {
    addQuantity();
    var t = setTimeout(plusPressed, 200);
    setTimer(t);
  };

  const clearTimer = () => {
    clearTimeout(timer);
  };

  return (
    <Div
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginLeft: 10,
        justifyContent: "space-between",
        ...props.style,
      }}
    >
      <Div
        style={{
          backgroundColor: Colors.white,
          width: 26,
          height: 26,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 4,
          cursor: "pointer",
        }}
        onMouseDown={minusPressed}
        onMouseUp={clearTimer}
        onTouchStart={minusPressed}
        onTouchEnd={clearTimer}
      >
        <H5 style={{ color: "black" }}>-</H5>
      </Div>
      <H5 style={{ marginLeft: 15, marginRight: 15, color: "black" }}>
        {quantity}
      </H5>
      <Div
        style={{
          backgroundColor: Colors.white,
          width: 26,
          height: 26,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 4,
          cursor: "pointer",
        }}
        onMouseDown={plusPressed}
        onMouseUp={clearTimer}
        onTouchStart={plusPressed}
        onTouchEnd={clearTimer}
      >
        <H5 style={{ color: "black" }}>+</H5>
      </Div>
    </Div>
  );
};

export default PlusMinus;
