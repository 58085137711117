import {
  CREATE_EMPLOYEE,
  GET_EMPLOYEES,
  GET_EMPLOYEES_FAILURE,
  GET_EMPLOYEES_REQUEST,
  PATCH_EMPLOYEE,
} from "../types/employees";

const initialState = {
  employees: null,
  loading: false,
  error: false,
  errorStatus: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_EMPLOYEES:
      return Object.assign({}, state, {
        employees: action.value,
        loading: false,
      });
    case GET_EMPLOYEES_FAILURE:
      return Object.assign({}, state, {
        error: true,
        errorStatus: action.value,
        loading: false,
      });
    case PATCH_EMPLOYEE:
      const index = state.employees.findIndex(
        (emp) => emp._id === action.value._id
      );
      return Object.assign({}, state, {
        employees: [
          ...state.employees.slice(0, index), // everything before current post
          action.value,
          ...state.employees.slice(index + 1), // everything after current post
        ],
      });

    case CREATE_EMPLOYEE:
      return Object.assign({}, state, {
        employees: [...state.employees, action.value.employee],
      });
    default:
      return state;
  }
};
