import { GET_STATS_SUCCESS, FAILURE, REQUEST } from '../types/statistics'

const initialState = {
  stats: [],
  error: false,
  errorStatus: '',
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STATS_SUCCESS:
      return Object.assign({}, state, {
        stats: action.value,
        loading: false
      })
    case REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value
      })
    default:
      return state
  }
}
