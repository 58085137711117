import React, { useState } from 'react'
import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox'
import camera from '../../Assets/Icons/camera-green.svg'
import register from '../../Assets/Icons/register.svg'
import { withStyles } from '@material-ui/core/styles'
import ModalPicture from '../UI/ModalPicture'
import pdfGenerator from '../../Services/pdfGenerator'
import Colors from '../../Assets/Colors'

import close from '../../Assets/Icons/close.svg'
import check from '../../Assets/Icons/check.svg'

//* UI

const CheckBox = withStyles({
  colorSecondary: {
    color: '#3d8af7 !important',
    '&&:hover': {
      backgroundColor: 'rgba(61, 138, 247, 0.05)'
    }
  }
})(Checkbox)

const Test = styled.div`
  background-color: ${Colors.white};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`

const Header = styled.header`
  flex-direction: row;
  justify-content: inherit;
  display: flex;
  padding: 10px 20px;
  background-color: 'white';
  /* padding-bottom: 24px; */
  border-bottom: 1px solid #efefef;
`
const Text = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  color: #4c4c4c;
  text-transform: uppercase;
  min-width: ${({ width }) => `${width}px`};
  max-width: ${({ width }) => `${width}px`};
  width: 100%;
  flex: ${(flex) => flex};
`
const TodoItemContainer = styled.div`
  display: flex;
  flex-wrap: ${({ wrap }) => wrap && 'wrap'};
  min-width: ${({ width }) => `${width}px`};
  max-width: ${({ width }) => `${width}px`};
  width: 100%;
  flex: ${(flex) => flex};
  padding-left: 0;
`
const Img = styled.img`
  cursor: pointer;
`
const Main = styled.main`
  display: flex;
  flex-direction: column;
`
//* UI END

const Item = ({ table, modal, img, setImg }) => {
  console.log('table:', table)
  const openModal = (_id) => {
    table.tasks.forEach((el) => {
      if (el._id === _id) {
        console.log('sa passe dans la condition:', el)
        setImg(el.picture)
      }
      modal.setOpen(true)
    })
  }

  return (
    <Test>
      <div
        style={{
          position: 'relative',
          marginTop: 1,
          justifyContent: 'inherit',
          alignItems: 'inherit',
          padding: '20px'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2
            style={{
              borderLeft: '3px solid #3C95FF',
              paddingLeft: 10,
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '20px',
              lineHeight: '24px'
            }}
          >
            {table.table}
          </h2>
          <div style={{ width: 20 }} />
          <p style={{ color: '#3C95FF' }}>
            {table.doneItems}/{table.totalItems}
          </p>
        </div>
        {table.tasks.map((el) => {
          const idx = el?.date?.indexOf('T')
          const hours = el?.date?.slice(idx, -1)?.substr(1)?.slice(0, 5)

          return (
            <>
              <div
                style={{
                  display: 'flex'
                }}
              >
                <TodoItemContainer
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  width={470}
                >
                  {el.checked ? (
                    <img
                      src={check}
                      style={{ width: 18 }}
                    />
                  ) : (
                    <img
                      src={close}
                      style={{ width: 12 }}
                    />
                  )}
                  {/* <CheckBox checked={el.checked} /> */}
                  <div style={{ width: 40 }} />
                  <p style={{ marginRight: 40 }}>{el.name}</p>
                </TodoItemContainer>
                <TodoItemContainer
                  width={130}
                  wrap
                  style={{ alignItems: 'center', marginLeft: 5 }}
                >
                  <p>{hours}</p>
                </TodoItemContainer>
                <TodoItemContainer width={210} style={{ alignItems: 'center' }}>
                  <p>{el?.employee}</p>
                </TodoItemContainer>
                <TodoItemContainer
                  flex={1}
                  style={{
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <p style={{ marginRight: 20 }}>{el?.comment}</p>
                  {el.picture ? (
                    <Img src={camera} onClick={() => openModal(el._id)} />
                  ) : (
                    <></>
                  )}
                </TodoItemContainer>
              </div>
            </>
          )
        })}
      </div>
    </Test>
  )
}

const ResponsableTodo = ({ date, item, checked }) => {
  const [toggleOpenModalPicture, setToggleOpenModalPicture] = React.useState(
    false
  )
  const [img, setImg] = useState()
  const { tasks } = item

  return (
    <>
      <ModalPicture
        setImg={setImg}
        open={toggleOpenModalPicture}
        toggleOpen={setToggleOpenModalPicture}
        img={img}
      />
      <Main>
        <Header>
          <Text width={470}>Tâche</Text>
          <Text width={130}>Heure</Text>
          <Text width={210}>Fait par</Text>
          <Text flex={1}>Commentaire</Text>
          <Img
            onClick={() => pdfGenerator(tasks, date, checked)}
            src={register}
          />
        </Header>
        {tasks?.map((table) => {
          return (
            <Item
              modal={{
                isOpen: toggleOpenModalPicture,
                setOpen: setToggleOpenModalPicture
              }}
              img={img}
              setImg={setImg}
              table={table}
            />
          )
        })}
      </Main>
    </>
  )
}

export default ResponsableTodo
