import React from "react";
import Colors from "../../Assets/Colors";
import { TextInput } from "./Styled/TextInputs";

const EnterInput = (props) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      props.submit();
    }
  };

  return (
    <TextInput
      type={props.type}
      onKeyDown={handleKeyDown}
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
      autoCapitalize="none"
      spellCheck={false}
      style={{...props.style}}
    />
  );
};

export default EnterInput;
