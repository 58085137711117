import React from "react";
import Colors from "../../Assets/Colors";
import Link from "../Link";
import { H6 } from "../UI/Styled/Texts";
import { Div } from "../UI/Styled/Views";
import close from "../../Assets/Icons/close.svg";
import check from "../../Assets/Icons/check.svg";

const ClotureElement = (props) => {
  const { cloture } = props;

  const perte = (
    parseFloat(cloture.cash) - parseFloat(cloture.cashMachine)
  ).toFixed(2);

  return (
    <Link to={`/cloture/${cloture._id}`} style={{ width: "100%" }}>
      <Div
        style={{
          width: "100%",
          flexDirection: "row",
          marginBottom: 5,
          marginTop: 5,
          cursor: "pointer",
        }}
      >
        <Div
          style={{
            width: 50,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {cloture.valide ? (
            <img src={check} style={{ width: 18 }} />
          ) : (
            <img src={close} style={{ width: 12 }} />
          )}
        </Div>
        <H6 style={{ fontWeight: 500, width: "7%", marginRight: 3 }}>
          {("0" + new Date(cloture.heureDeCloture).getDate()).slice(-2)}/
          {("0" + (new Date(cloture.heureDeCloture).getMonth() + 1)).slice(-2)}
        </H6>
        <H6 style={{ fontWeight: 500, width: "7%", marginRight: 3 }}>
          {cloture.numCaisse}
        </H6>

        <Div
          style={{
            fontWeight: 500,
            width: "35%",
            marginRight: 3,
            flexDirection: "row",
            wordBreak: "break-word",
          }}
        >
          <H6 style={{ fontWeight: 500 }}>
            {cloture.employes.map((e) => `${e.name} ${e.surname}`).join(", ")}
          </H6>
        </Div>
        <H6 style={{ fontWeight: 500, width: "15%", marginRight: 3 }}>
          {parseFloat(cloture.CA).toFixed(2)}€
        </H6>
        <H6 style={{ fontWeight: 500, width: "15%", marginRight: 3 }}>
          {parseFloat(cloture.cash).toFixed(2)}€
        </H6>
        {perte >= 0 ? (
          <H6
            style={{
              fontWeight: 500,
              width: "7%",
              marginRight: 3,
              color: Colors.green,
            }}
          >
            {perte}€
          </H6>
        ) : (
          <H6
            style={{
              fontWeight: 500,
              width: "7%",
              marginRight: 3,
              color: Colors.red,
            }}
          >
            {perte}€
          </H6>
        )}
      </Div>
    </Link>
  );
};

export default ClotureElement;
