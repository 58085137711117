import {
  GET_HISTORY_SUCCESS,
  ADD_HISTORY_SUCCESS,
  UPDATE_HISTORY_SUCCESS,
  FAILURE,
  REQUEST
} from '../types/history'

const initialState = {
  history: [],
  error: false,
  errorStatus: '',
  loading: false,
  checked: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        history: action.value,
        loading: false
      })
    case REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value
      })
    case ADD_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        errorStatus: '',
        history: [...state.histories, action.value]
      })
    case REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value
      })
    case UPDATE_HISTORY_SUCCESS:
      state.history.checked = action.value.checked
      return Object.assign({}, state, {
        loading: false,
        error: false,
        errorStatus: '',
        history: state.history
      })
    case REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value
      })
    default:
      return state
  }
}
