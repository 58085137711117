import {
  GET_REPAS_HISTORY,
  GET_REPAS_HISTORY_FAILURE,
  GET_REPAS_HISTORY_REQUEST,
} from "../types/repas";

const initialState = {
  repas: null,
  pageCount: 0,
  error: false,
  errorStatus: "",
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REPAS_HISTORY:
      return Object.assign({}, state, {
        repas: action.value.items,
        pageCount: action.value.pageCount,
        loading: false,
      });
    case GET_REPAS_HISTORY_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });
    case GET_REPAS_HISTORY_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value,
      });
    default:
      return state;
  }
};
