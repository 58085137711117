import {} from '../types/clotures'

const liste = [
  {
    name: 'Le tour',
    doneItems: 5,
    totalItems: 20,
    checked: true
  },
  {
    name: 'Magasin',
    doneItems: 7,
    totalItems: 10,
    checked: false
  },
  {
    name: 'Responsable',
    doneItems: 1,
    totalItems: 5,
    checked: false
  }
]

const initialState = {
  //todos: null,
  history: [
    {
      // date: 'new Date(07/10/2020)',
      date: 'date',
      data: {
        doneItems: 13,
        totalItems: 13,
        checked: false,
        history: [
          {
            name: 'Le tour',
            doneItems: 5,
            totalItems: 20,
            checked: true
          },
          {
            name: 'Magasin',
            doneItems: 7,
            totalItems: 10,
            checked: false
          },
          {
            name: 'Responsable',
            doneItems: 1,
            totalItems: 5,
            checked: false
          }
        ]
      }
    },
    {
      // date: 'new Date(08/10/2020)',
      date: 'date',
      data: {
        doneItems: 23,
        totalItems: 36,
        checked: false,
        history: [
          {
            name: 'Le hihihi',
            doneItems: 4,
            totalItems: 35,
            checked: false
          }
        ]
      }
    },
    {
      // date: 'new Date(09/10/2020)',
      date: 'date',
      data: {
        doneItems: 11,
        totalItems: 40,
        checked: false,
        history: [
          {
            name: 'Le ahahha',
            doneItems: 15,
            totalItems: 20,
            checked: false
          }
        ]
      }
    }
  ],

  // todo:null,
  todos: {
    // date: 'new Date(26/02/2020)',
    date: 'date',
    checked: false,
    data: [
      {
        categoryName: 'Sous-table 1',
        doneItems: 2,
        totalItems: 3,
        tasks: [
          {
            name: 'Lorem Ipsum dolor sit',
            checked: true,
            hour: 'new Date(26/02/2020)',
            employeeName: 'Jean Lavoine',
            comment: 'Ceci est le commentaire',
            picture:
              'https://scontent-lhr8-2.xx.fbcdn.net/v/t1.0-9/154874625_2821515941394483_5561130536324371339_n.jpg?_nc_cat=101&ccb=3&_nc_sid=8bfeb9&_nc_ohc=vx4BPHPrrD0AX_RGYKL&_nc_oc=AQmiYbZFgPP8cFbM1-TGLqeh2y-BRJiqMlLdWC-u1r1Zk9nwzkjvcJm37bfkHnDC2do&_nc_ht=scontent-lhr8-2.xx&oh=5ab1bb6af40e9993ff296e36f244be4e&oe=6067A219'
          },
          {
            name: 'Nom 2',
            checked: true,
            hour: 'new Date(26/02/2020)',
            employeeName: 'Jaquues A Dit',
            comment: 'Ceci est le commentaire',
            picture: ''
          },
          {
            name: 'OOOOh',
            checked: false,
            hour: '',
            employeeName: '',
            comment: '',
            picture: ''
          }
        ]
      },
      {
        categoryName: 'Sous-table 1',
        name: 'Sous-table 2',
        doneItems: 3,
        totalItems: 3,
        tasks: [
          {
            name: 'Lorem Ipsum dolor sit',
            checked: true,
            hour: 'new Date(26/02/2020)',
            employeeName: 'Jean Lavoine',
            comment: 'Ceci est le commentaire',
            picture:
              'https://scontent-lhr8-2.xx.fbcdn.net/v/t1.0-9/154874625_2821515941394483_5561130536324371339_n.jpg?_nc_cat=101&ccb=3&_nc_sid=8bfeb9&_nc_ohc=vx4BPHPrrD0AX_RGYKL&_nc_oc=AQmiYbZFgPP8cFbM1-TGLqeh2y-BRJiqMlLdWC-u1r1Zk9nwzkjvcJm37bfkHnDC2do&_nc_ht=scontent-lhr8-2.xx&oh=5ab1bb6af40e9993ff296e36f244be4e&oe=6067A219'
          },
          {
            name: 'Nom 2',
            checked: true,
            hour: 'new Date(26/02/2020)',
            employeeName: 'Jaquues A Dit',
            comment: 'Ceci est le commentaire',
            picture:
              'https://scontent-lhr8-2.xx.fbcdn.net/v/t1.0-9/154874625_2821515941394483_5561130536324371339_n.jpg?_nc_cat=101&ccb=3&_nc_sid=8bfeb9&_nc_ohc=vx4BPHPrrD0AX_RGYKL&_nc_oc=AQmiYbZFgPP8cFbM1-TGLqeh2y-BRJiqMlLdWC-u1r1Zk9nwzkjvcJm37bfkHnDC2do&_nc_ht=scontent-lhr8-2.xx&oh=5ab1bb6af40e9993ff296e36f244be4e&oe=6067A219'
          },
          {
            name: 'OOOOh',
            checked: true,
            hour: 'new Date(26/02/2020)',
            employeeName: 'Jean Vaisselle',
            comment: 'Ceci est le commentaire 3',
            picture: ''
          }
        ]
      }
    ]
  },

  statistics: [
    {
      name: 'Tache 1',
      table: 'Matin',
      doneItems: 1,
      totalItams: 13
    },
    {
      name: 'Tache 2',
      table: 'Soir',
      doneItems: 3,
      totalItams: 13
    },
    {
      name: 'Tache 3',
      table: 'Matin',
      doneItems: 6,
      totalItams: 13
    },
    {
      name: 'Tache 4',
      table: 'Soir',
      doneItems: 10,
      totalItams: 13
    },
    {
      name: 'Tache 5',
      table: 'Matin',
      doneItems: 1,
      totalItams: 13
    },
    {
      name: 'Tache 6',
      table: 'Midi',
      doneItems: 1,
      totalItams: 13
    }
  ],

  pageCount: 0,
  error: false,
  errorStatus: '',
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
