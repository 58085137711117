export const GET_CATEGORY_TASKS_SUCCESS = 'GET_CATEGORY_TASKS_SUCCESS'

export const ADD_CATEGORY_TASKS_SUCCESS = 'ADD_CATEGORY_TASKS_SUCCESS'

export const DELETE_CATEGORY_TASKS_SUCCESS = 'DELETE_CATEGORY_TASKS_SUCCESS'

export const UPDATE_CATEGORY_TASKS_SUCCESS = 'UPDATE_CATEGORY_TASKS_SUCCESS'

export const REQUEST = 'REQUEST'
export const FAILURE = 'FAILURE'
