export const getLoginError = (status) => {
  let errorMessage = '';
  switch (status) {
    case 401:
      return 'Le mot de passe entré est incorrect.';
    case 404:
      return 'Aucun utilisateur ne correspond à cette adresse e-mail.';
  }
  return errorMessage;
};

// export const getEmployeeLoginError = (status) => {
//   let errorMessage = '';
//   switch (status) {
//     case 401:
//       return 'Le mot de passe entré est incorrect.';
//     case 404:
//       return 'Aucun utilisateur ne correspond à cette adresse e-mail.';
//   }
//   return errorMessage;
// };
