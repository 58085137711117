import React from "react";
import { H5, H6 } from "../UI/Styled/Texts";
import { Card, Div } from "../UI/Styled/Views";
import { Image } from "../UI/Styled/Images";
import FolderImage from "../../Assets/Icons/folder.png";
import DocumentImage from "../../Assets/Icons/document.png";
import Colors from "../../Assets/Colors";
import { Link } from "react-router-dom";

const FolderFileCard = (props) => {
  const { title, type, id, date } = props;
  const linkTo = type == "Folder" ? `/folder/${id}` : `/document/${id}`;
  return (
    <Link to={linkTo} style={{ textDecoration: "none" }}>
      <Card
        style={{
          width: 180,
          height: 100,
          justifyContent: "space-between",
          alignItems: "flex-start",
          marginRight: 20,
          marginBottom:20,
          cursor: "pointer",
        }}
      >
        <Div
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <H5>{title}</H5>
          {type == "Folder" ? (
            <Image src={FolderImage} style={{ width: 30 }} />
          ) : (
            <Image src={DocumentImage} style={{ width: 30 }} />
          )}
        </Div>
        <H6 style={{ color: Colors.lightGrey }}>
          Créé le {new Date(date).getDate()}/{new Date(date).getMonth() + 1}/
          {new Date(date).getFullYear()}
        </H6>
      </Card>
    </Link>
  );
};

export default FolderFileCard;
