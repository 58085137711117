import React from "react";
import Colors from "../../Assets/Colors";
import { PainTextInput } from "../UI/Styled/TextInputs";
import { Div } from "../UI/Styled/Views";

const PainEditing = (props) => {
  const { pain, index, setEditedPains, editedPains, error } = props;

  const updatePainType = (e) => {
    let newPains = [...editedPains];
    newPains[index].type = e.target.value;
    setEditedPains(newPains);
  };


  const updatePainPrice = (e) => {
    let newPains = [...editedPains];
    newPains[index].price = e.target.value.replace(/,/g , ".");
    setEditedPains(newPains);
  };


  return (
    <Div
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        marginTop: 10,
      }}
    >
      <PainTextInput
        style={{
          width: "70%",
          borderColor: error ? Colors.red : Colors.lightGrey,
        }}
        value={pain.type}
        onChange={(e) => updatePainType(e)}
      />
      <PainTextInput
        style={{
          width: "20%",
          borderColor: error ? Colors.red : Colors.lightGrey,
        }}
        value={pain.price}
        onChange={(e) => updatePainPrice(e)}
      />
    </Div>
  );
};

export default PainEditing;
