import React from 'react'

import { Div } from '../UI/Styled/Views'
import LinearProgress from '@material-ui/core/LinearProgress'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import arrow from '../../Assets/Icons/arrow.svg'
import close from '../../Assets/Icons/close.svg'
import check from '../../Assets/Icons/check.svg'



const CheckBox = withStyles({
  colorSecondary: {
    color: '#3d8af7 !important',
    '&&:hover': {
      backgroundColor: 'rgba(61, 138, 247, 0.05)'
    }
  }
})(Checkbox)

const ContainerTodo = (props) => {
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 12,
      borderRadius: '30px',
      background: '#F0F7FF'
    },
    colorPrimary: {
      backgroundColor: 'green'
    },
    bar: {
      borderRadius: '30px',
      backgroundColor: props.color,
      transition: '2s'
    }
  }))(LinearProgress)
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: 40 }}>

      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
        onClick={props.onClick}
      >
        <p
          style={{
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '13px',
            lineHeight: '16px',
            width: 180,
            transform: 'translateX(50px)',
            marginRight: 20
          }}
        >
          {props.name}
        </p>
        <p
          style={{
            color: props.color,
            width: 100,
            fontFamily: 'Montserrat',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '17px',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          {props.done}/{props.total}
        </p>

        <div style={{ width: '50vw' }}>
          <BorderLinearProgress
            value={props.value}
            disableRipple
            variant='determinate'
            {...props}
          />
        </div>
      </div>
    </div>
  )
}

const ChildrenTodo = ({ toggleChildren, state }) => {
  return (
    toggleChildren &&
    state?.history?.map((todo) => {
      const percentage = (todo?.doneItems / todo?.totalItems) * 100
      return (
        <Link
          to={`/todo-history/${todo._id}`}
          style={{ textDecoration: 'none', color: '#454545' }}
        >
          <div style={{ marginLeft: 40 }}>
            <ContainerTodo
              done={todo?.doneItems}
              total={todo?.totalItems}
              color={
                percentage == 100 ? 'green' : percentage >= 50 ? 'blue' : 'red'
              }
              value={percentage}
              name={
                <div style={{ display: 'flex' }}>
                  {todo?.checked ? (
                    <img
                      src={check}
                      style={{ width: 18, marginRight: 12 }}
                    />
                  ) : (
                    <img
                      src={close}
                      style={{ width: 12, marginRight: 12 }}
                    />
                  )}
                  {/* <CheckBox checked={todo?.checked} /> */}
                  <p
                    style={{
                      fontFamily: 'Montserrat',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: '13px',
                      lineHeight: '16px'
                    }}
                  >
                    {todo.category.name}
                  </p>
                </div>
              }
              checked={todo.checked}
            />
          </div>
        </Link>
      )
    })
  )
}

const TodoHistorique = ({ state }) => {
  const [toggleChildren, setToggleChildren] = React.useState(false)
  const [rotateArrow, setRotateArrow] = React.useState(0)

  const headerPercentage = (state.doneItems / state.totalItems) * 100
  console.log("state", state)
  return (
    <Div
      style={{
        width: '100%',
        flexDirection: 'row',
        marginBottom: 5,
        cursor: 'pointer'
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ContainerTodo
          done={state.doneItems}
          total={state.totalItems}
          color={
            headerPercentage == 100
              ? 'green'
              : headerPercentage >= 50
                ? 'blue'
                : 'red'
          }
          value={headerPercentage}
          checked={state.doneItems === state.totalItems}
          name={
            <div style={{ display: "flex" }}>
              <div style={{ width: 40 }}>
                {state.checked ? (
                  <img
                    src={check}
                    style={{ width: 18 }}
                  />
                ) : (
                  <img
                    src={close}
                    style={{ width: 12 }}
                  />
                )}
              </div>
              <div style={{ display: 'flex' }}>
                <img
                  src={arrow}
                  style={{
                    marginRight: 10,
                    transform: `rotate(${rotateArrow}deg)`,
                    transition: '.225s ease'
                  }}
                />
                {state.day}
              </div>
            </div>
          }
          widthCheckbox
          onClick={() => {
            setToggleChildren(!toggleChildren)
            if (toggleChildren) setRotateArrow(0)
            if (!toggleChildren) setRotateArrow(90)
          }}
        />
        <ChildrenTodo
          toggleChildren={toggleChildren}
          state={state}
        />
      </div>
    </Div>
  )
}

export default TodoHistorique
