import {
  GET_HISTORIES_SUCCESS,
  ADD_HISTORIES_SUCCESS,
  UPDATE_HISTORIES_SUCCESS,
  FAILURE,
  REQUEST
} from '../types/histories'

const initialState = {
  histories: [],
  pageCount: 0,
  error: false,
  errorStatus: '',
  loading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HISTORIES_SUCCESS:
      return Object.assign({}, state, {
        histories: action.value,
        pageCount: action.value.pageCount,
        loading: false
      })
    case REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value
      })
    case ADD_HISTORIES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: false,
        errorStatus: '',
        histories: [...state.histories, action.value]
      })
    case REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value
      })
    case UPDATE_HISTORIES_SUCCESS:
      // action.value => nouveau doc
      let historiesCopy = [...state.histories]
      for (let todo in historiesCopy) {
        if (historiesCopy[todo]._id == action.value._id) {
          historiesCopy[todo] = action.value
        }
      }
      return Object.assign({}, state, {
        loading: false,
        error: false,
        errorStatus: '',
        histories: historiesCopy
      })
    case REQUEST:
      return Object.assign({}, state, {
        loading: true
      })
    case FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: true,
        errorStatus: action.value
      })
    default:
      return state
  }
}
