import React, { useState } from "react";
import Colors from "../../Assets/Colors";
import { ReactComponent as DashboardIcon } from "../../Assets/Icons/Dashboard.svg";
import { ReactComponent as ProfilsIcon } from "../../Assets/Icons/Profils.svg";
import { ReactComponent as HistoriqueIcon } from "../../Assets/Icons/Historique.svg";
import { ReactComponent as RepasIcon } from "../../Assets/Icons/Repas.svg";
import { ReactComponent as PainIcon } from "../../Assets/Icons/Pain.svg";
import { ReactComponent as MonnaieIcon } from "../../Assets/Icons/Monnaie.svg";
import { ReactComponent as InfosIcon } from "../../Assets/Icons/Infos.svg";
import { ReactComponent as LogoutIcon } from "../../Assets/Icons/Logout.svg";
import { ReactComponent as Bar } from "../../Assets/Icons/bar-chart.svg";
import { ReactComponent as Template } from "../../Assets/Icons/template.svg";
import { H3, H5 } from "../UI/Styled/Texts";
import { Card, Div } from "../UI/Styled/Views";
import { logout, uploadProfilePicture } from "../../store/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import Link from "../Link";
import { Circle } from "../UI/Styled/Shapes";
import { Image } from "../UI/Styled/Images";
import { FileInput } from "../UI/Styled/FileInputs";
import { useMediaQuery } from "@material-ui/core";
import { Drawer } from "@material-ui/core";

const SideBarItem = ({ to, Icon, active, title }) => {
  return (
    <Link to={to}>
      <Div
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <Div style={{ flex: 1 }}>
          <Icon
            fill={active ? Colors.purple : Colors.darkGrey}
            width='24'
            height='24'
          />
        </Div>
        <H5
          style={{
            color: active ? Colors.purple : Colors.darkGrey,
            marginLeft: 10,
            flex: 9,
          }}
        >
          {title}
        </H5>
      </Div>
    </Link>
  );
};

const Routes = ({ active }) => {
  return (
    <Div style={{ width: "100%", marginTop: 10 }}>
      <SideBarItem
        to='/dashboard'
        Icon={DashboardIcon}
        active={active == "Dashboard"}
        title={"Dashboard"}
      />
      <SideBarItem
        to='/profiles'
        Icon={ProfilsIcon}
        active={active == "Profils"} // todo -> ask shady how link color work
        title={"Profils"}
      />
      <SideBarItem
        to='/history'
        Icon={HistoriqueIcon}
        active={active == "Historique"}
        title={"Historique"}
      />
      <SideBarItem
        to='/meals'
        Icon={RepasIcon}
        active={active == "Repas personnel"}
        title={"Repas personnel"}
      />
      <SideBarItem
        to='/bread'
        Icon={PainIcon}
        active={active == "Pain"}
        title={"Pain"}
      />
      <SideBarItem
        to='/money'
        Icon={MonnaieIcon}
        active={active == "Monnaie"}
        title={"Monnaie"}
      />
      <SideBarItem
        to='/infos'
        Icon={InfosIcon}
        active={active == "Informations"}
        title={"Informations"}
      />
      <SideBarItem
        to='/todo-history'
        Icon={HistoriqueIcon}
        active={active == "Historique To Do"}
        title={"Historique To Do"}
      />
      <SideBarItem
        to='/template'
        Icon={Template}
        active={active == "Template"}
        title={"Template"}
      />
      <SideBarItem
        to='/statistics'
        Icon={Bar}
        active={active == "Statistiques"}
        title={"Statistiques"}
      />
    </Div>
  );
};
const DashboardSideBar = (props) => {
  const { active, open, setOpen } = props;
  const matches = useMediaQuery("(min-width:800px)");
  return matches ? (
    <Menu active={active} setOpen={setOpen} />
  ) : (
    <Drawer anchor='left' open={open} onClose={() => setOpen(false)}>
      <Menu active={active} setOpen={setOpen} />
    </Drawer>
  );
};

const Menu = (props) => {
  const { active } = props;
  const { user, lastUpdatedProfilePic } = useSelector((state) => state.auth);
  const { token } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const IMG_SIZE = 60;

  const selectPicture = () => {
    document.getElementById("hiddenFileInput").click();
  };

  const fileSelectedHandler = (event) => {
    dispatch(
      uploadProfilePicture({
        picture: event.target.files[0],
        uid: user._id,
        token: token,
      })
    );
  };

  return (
    <Card
      style={{
        width: 200,
        justifyContent: "space-between",
        marginRight: 30,
        marginLeft: 30,
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Div
        style={{
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {user && user.profilePicture ? (
          <Div
            style={{
              width: IMG_SIZE,
              height: IMG_SIZE,
              borderRadius: "50%",
              backgroundImage: `url(${
                user.profilePicture.indexOf("http") === 0
                  ? `${user.profilePicture}?${lastUpdatedProfilePic}`
                  : `https://${user.profilePicture}?${lastUpdatedProfilePic}`
              })`,
              backgroundSize: "100% 100%",
            }}
            onClick={selectPicture}
          />
        ) : (
          <Circle
            style={{
              width: IMG_SIZE,
              height: IMG_SIZE,
              borderRadius: "50%",
            }}
            onClick={selectPicture}
          />
        )}
        <H5
          style={{
            marginTop: 10,
            marginBottom: 10,
            fontWeight: 700,
            color: "black",
            textAlign: "center",
          }}
        >
          {`${user?.name} ${user?.surname}`}
        </H5>
        <Routes active={active} />
      </Div>
      <Div
        style={{
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => dispatch(logout())}
      >
        <LogoutIcon fill={Colors.red} />
        <H5 style={{ color: Colors.red, marginLeft: 20 }}>Déconnexion</H5>
      </Div>
      <FileInput
        type='file'
        id='hiddenFileInput'
        onChange={fileSelectedHandler}
      />
    </Card>
  );
};

export default DashboardSideBar;
