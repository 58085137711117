import {
  GET_TODO_CATEGORY_SUCCESS,
  GET_TODO_CATEGORY_FAILURE,
  GET_TODO_CATEGORY_REQUEST,
  ADD_TODO_CATEGORY_REQUEST,
  ADD_TODO_CATEGORY_FAILURE,
  ADD_TODO_CATEGORY_SUCCESS,
  DELETE_TODO_CATEGORY_REQUEST,
  DELETE_TODO_CATEGORY_FAILURE,
  DELETE_TODO_CATEGORY_SUCCESS,
  UPDATE_TODO_CATEGORY_SUCCESS,
  REQUEST,
  FAILURE,
} from "../types/todo";

export const getTodosCategories = ({ token }) => {
  return (dispatch) => {
    dispatch({ type: GET_TODO_CATEGORY_REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/category`, {
      method: "GET",
      headers: new Headers({
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.errors) {
          dispatch({
            type: GET_TODO_CATEGORY_FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: GET_TODO_CATEGORY_SUCCESS, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const addTodosCategories = ({ token, name, todos }) => {
  return (dispatch) => {
    dispatch({ type: ADD_TODO_CATEGORY_REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/category`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        name: name,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: ADD_TODO_CATEGORY_FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: ADD_TODO_CATEGORY_SUCCESS, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};

export const deleteTodosCategories = ({ token, _id, name }) => {
  return (dispatch) => {
    dispatch({ type: DELETE_TODO_CATEGORY_REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/category?id=${_id}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Tyrpe": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: DELETE_TODO_CATEGORY_FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: DELETE_TODO_CATEGORY_SUCCESS, value: _id });
        }
      })
      .catch((error) => console.log(error));
  };
};
export const updateTodosCategories = ({ token, _id, name }) => {
  console.log("name:", name);
  return (dispatch) => {
    dispatch({ type: REQUEST });
    fetch(`https://shopngo.herokuapp.com/api/tasks/category?id=${_id}`, {
      method: "PATCH",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify({
        name: name,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        if (res.errors) {
          dispatch({
            type: FAILURE,
            value: res.errors.status,
          });
        } else {
          dispatch({ type: UPDATE_TODO_CATEGORY_SUCCESS, value: res });
        }
      })
      .catch((error) => console.log(error));
  };
};
